import { groupByUnStr } from '../../api/customer/analytics.api';
import { roundDecimals } from '../../fromKotlin/nkutils';
import { fixColumnsWidth } from './ag-table-utils';
import { ITableControls } from './interfaces';

export function extractBeforeNumber(input: string): string {
    const regex = /^([a-zA-Z0-9]+(?:_[a-zA-Z]+)*?)_\d/;
    const match = input.match(regex);
    return (match ? match[1] : null) ?? input;
}

export const saveColumnState = (params, tableControls, gridReady, force_vis?) => {
    if (!gridReady) return;
    if (force_vis || tableControls?.groupBy?.allowSave || tableControls?.defaultGroupBy) {
    }

    const columnState = params?.columnApi?.getColumnState();
    const stateToSave = columnState?.map((column, i) => {
        const forceV = force_vis?.find((col) => col.colId === column?.colId);
        return {
            ...column,
            colId: extractBeforeNumber(column?.colId) ?? column?.colId,
            hide: forceV ? true : column?.hide
        };
    });
    localStorage.setItem(tableControls.tableName + '-columnsState', JSON.stringify(stateToSave));
    const groupState = params?.columnApi?.getColumnGroupState();
    localStorage.setItem(tableControls.tableName + '-columnsStategrp', JSON.stringify(groupState));
};

export const onCellKeyDown = (params: any, tableRef: any, menuiItems) => {
    const isMultipleSelection = menuiItems?.[params?.colDef?.id];
    if (isMultipleSelection) {
        return;
    }

    const editable = params?.colDef?.editable;
    if (!editable) {
        return;
    }

    const key = params?.event?.key;
    const rowIndex = params?.rowIndex;
    const column = params?.column;
    let nextRowIndex;
    let nextColumn;
    switch (key) {
        case 'ArrowUp':
            nextRowIndex = rowIndex - 1;
            nextColumn = column;
            break;
        case 'ArrowDown':
            nextRowIndex = rowIndex + 1;
            nextColumn = column;
            break;
        case 'ArrowRight':
            nextRowIndex = rowIndex;
            nextColumn = tableRef?.current?.columnApi?.getDisplayedColBefore(column);
            break;
        case 'ArrowLeft':
            nextRowIndex = rowIndex;
            nextColumn = tableRef?.current?.columnApi?.getDisplayedColAfter(column);
            break;
        case 'Enter':
            nextRowIndex = rowIndex;
            nextColumn = tableRef?.current?.columnApi?.getDisplayedColAfter(column);
            break;
        default:
            return;
    }
    tableRef?.current?.api?.stopEditing();
    tableRef?.current?.api?.setFocusedCell(nextRowIndex, nextColumn);
    // tableRef?.current?.api?.startEditingCell({
    //   rowIndex: nextRowIndex,
    //   colKey: nextColumn?.colId,
    // });
};

export const onColumnMoved = (params: any, tableControls: ITableControls, gridReady) => {
    if (params.finished && params?.source !== 'api' && params?.source !== 'gridOptionsChanged') {
        saveColumnState(params, tableControls, gridReady);
    }
};
export const onColumnGroupOpened = (params: any, tableControls: ITableControls, gridReady) => {
    saveColumnState(params, tableControls, gridReady);
};

export const onColumnResized = (params: any, tableControls: ITableControls, gridReady) => {
    if (
        params.finished &&
        (params?.source === 'uiColumnDragged' || params?.source === 'autosizeColumns' || params?.source === 'uiColumnResized')
    ) {
        saveColumnState(params, tableControls, gridReady);
    }
};

export const onColumnPinned = (params: any, tableControls: ITableControls, gridReady) => {
    saveColumnState(params, tableControls, gridReady);
};

export const addDragToTable = (params) => {
    const dragColumn = {
        headerName: '',
        field: 'drag',
        width: 50,
        pinned: 'right',
        rowDrag: true
    };
    const columnDefs = params.api.getColumnDefs();
    if (columnDefs.find((column) => column.field === 'drag')) {
        return;
    }
        params.api.setColumnDefs([dragColumn, ...columnDefs]);
};

export const removeDragFromTable = (params) => {
    const columnDefs = params.api.getColumnDefs();
    const newColumnDefs = columnDefs.filter((column) => column.field !== 'drag');
    params.api.setColumnDefs(newColumnDefs);
};

export const onFilterChanged = (params: any) => {
    const filterModel = params.api.getFilterModel();
    const allColumns = params.columnApi.getAllColumns();
    allColumns.forEach((column: any) => {
        const filterPresent = filterModel[column.colId] !== undefined;
        column.setFilterActive(filterPresent);
        if (filterPresent) {
            column.setColDef({
                ...column.getColDef(),
                headerClass: 'active-header-filter'
            });
        } else {
            column.setColDef({
                ...column.getColDef(),
                headerClass: ''
            });
        }
    });
};

export const onColumnVisible = (params, tableControls: ITableControls, gridReady) => {
    const changedColumn = params?.column?.colDef;
    changedColumn?.hideCallback ? changedColumn?.hideCallback(params) : null;
    saveColumnState(params, tableControls, gridReady);
};

export const createPinnedRowDataNew = (columns, rowData, config, withoutFormatter) => {
    const obj: {
        [key: string]: any;
    } = {};
    const format = (type, value) => (type === 'currency' ? '₪ ' + value : type === 'percent' ? value + ' %' : `${value}`);
    columns.forEach((column) => {
        const key = column?.id;
        const defaultAction = config?.func?.[column.id] ?? column?.pinnedRowProps?.action;
        let defaultValueGetter = (d) => d?.[key];
        if (column?.extractFunc) {
            defaultValueGetter = (d) => column?.extractFunc(d);
        }
        if (column?.valueGetter) {
            defaultValueGetter = (d) => column?.valueGetter(d);
        }

        if (config?.valueGetter?.[column.id]) {
            defaultValueGetter = config?.valueGetter?.[column.id];
        }
        let formatter = config?.format?.[column.id] ?? column?.pinnedRowProps?.format;

        if (typeof defaultAction === 'function') {
            let v = defaultAction(rowData.map((d) => defaultValueGetter(d)));
            obj[key] = !withoutFormatter && formatter ? format(formatter, v) : v;
            return;
        }
        switch (defaultAction) {
            case 'count':
                obj[key] = rowData?.length;
                break;
            case 'dev':
                let x = 0;
                let y = 0;
                rowData?.forEach((item: any) => {
                    if (column?.extractFunc) {
                        const d = column?.extractFunc(item);
                        x += d.x;
                        y += d.y;
                    } else {
                    }
                });
                const sum = x && y ? Math.min(100, (100 * y) / x) : 0;
                const formatted = `${roundDecimals(sum)}`;
                obj[key] = !withoutFormatter && formatter && sum ? format(formatter, formatted) : formatted;
                break;
            case 'uniqueCount': {
                const unique = new Set();
                rowData?.forEach((item: any) => {
                    const v = defaultValueGetter(item);
                    if (v && v != '') unique.add(v);
                });
                obj[key] = unique.size;
                break;
            }
            case 'booleanCount': {
                let count = 0;
                rowData?.forEach((item: any) => {
                    const v = defaultValueGetter(item);
                    if (v) count++;
                });
                obj[key] = rowData?.length + ' / ' + count;
                break;
            }
            case 'avg': {
                let sum = 0;
                let count = 0;
                rowData?.forEach((item: any) => {
                    const v = defaultValueGetter(item);
                    if (typeof v === 'number') {
                        sum += v;
                        count++;
                    } else {
                        const toNumber = Number(v);
                        sum += isNaN(toNumber) ? 0 : toNumber;
                        count++;
                    }
                });
                const formatted = (sum / count)?.toFixed(2)?.replace(/\d(?=(\d{3})+\.)/g, '$&,');
                if (formatted === 'NaN') {
                    obj[key] = 0;
                } else {
                    obj[key] = !withoutFormatter && formatter && sum ? format(formatter, formatted) : formatted;
                }
                break;
            }
            case 'sum': {
                let sum = 0;
                rowData?.forEach((item: any) => {
                    if (column?.[key] === 'string') {
                        return;
                    }
                    const v = defaultValueGetter(item);
                    if (typeof v === 'number') {
                        sum += v;
                    } else {
                        const toNumber = Number(v);
                        sum += isNaN(toNumber) ? 0 : toNumber;
                    }
                });
                // const formatted = sum?.toFixed(2)?.replace(/\d(?=(\d{3})+\.)/g, '$&,');
                const formatted = withoutFormatter || isNaN(sum) ? sum : `${roundDecimals(sum)}`;
                obj[key] = !withoutFormatter && formatter && sum ? format(formatter, formatted) : formatted;
                break;
            }
            case 'min': {
                let min = Infinity;
                rowData?.forEach((item: any) => {
                    const v = defaultValueGetter(item);
                    if (typeof v === 'number') {
                        min = Math.min(min, v);
                    } else {
                        const toNumber = Number(v);
                        min = Math.min(min, isNaN(toNumber) ? 0 : toNumber);
                    }
                });
                const formatted = withoutFormatter ? min : `${roundDecimals(min)}`;
                obj[key] = !withoutFormatter && formatter && min ? format(formatter, formatted) : formatted;
                break;
            }
            case 'max': {
                let max = -Infinity;
                rowData?.forEach((item: any) => {
                    const v = defaultValueGetter(item);
                    if (typeof v === 'number') {
                        max = Math.max(max, v);
                    } else {
                        const toNumber = Number(v);
                        max = Math.max(max, isNaN(toNumber) ? 0 : toNumber);
                    }
                });
                const formatted = withoutFormatter ? max : `${roundDecimals(max)}`;
                obj[key] = !withoutFormatter && formatter && max ? format(formatter, formatted) : formatted;
                break;
            }
            case 'first': {
                obj[key] = rowData?.[0]?.[key];
                break;
            }
            case 'last': {
                obj[key] = rowData?.[rowData?.length - 1]?.[key];
                break;
            }
            case 'none': {
                obj[key] = '';
                break;
            }

            case 'custome': {
                obj[key] = column?.customePinnedRowAction?.custome(rowData);
                break;
            }
            default:
                obj[key] = '';
                break;
        }
    });
    return obj;
};

export const onManyCellEditingStopped = (
    paramsis: any[],
    setEditMode,
    setStatusBarData,
    setValueAlert,
    setEditList,
    editList,
    editCallback,
    editCallbackMany,
    allowSame
) => {
    const arr: any = [];
    paramsis.forEach((params) => {
        if (params.oldValue != params.newValue || allowSame) {
            const editedColumn = [params?.column?.colDef?.id, params?.column?.colDef?.headerName];
            if (typeof params?.oldValue === 'boolean' && (params?.newValue === 'true' || params?.newValue === 'false')) {
                if ((params?.oldValue && params.newValue === 'true') || (!params?.oldValue && params.newValue === 'false')) return;
                params.node.setDataValue(params.column, params.newValue === 'true');
            }
            const columnDefs = params?.colDef;
            const columnValueLimit = columnDefs?.valueLimit;
            if (params?.newValue > columnValueLimit?.max || params?.newValue < columnValueLimit?.min) {
                setValueAlert(true);
                params.node.setDataValue(params.column.colId, params.oldValue);
                return;
            }
            params.data.hasBeenEdited = true;
            arr.push(params);
        }
    });
    if (arr.length === 0) {
        return;
    }
    arr[0]?.api?.refreshCells({ force: true, rowNodes: arr.map((params) => params.node) });
    if (editCallback) {
        arr.forEach((params) => editCallback(params));
    }
    if (editCallbackMany) {
        editCallbackMany(arr);
    }
    setEditMode(true);
    const grp = groupByUnStr(arr, (p) => p?.node?.id);
    const editGrp = groupByUnStr(editList, (p) => p?.id);
    const newEditted = {};

    Object.keys(grp).forEach((g) => {
        const arr = grp[g];
        const arr0 = arr[0];
        const editted = editGrp[arr0?.node?.id]?.[0];
        const newNode = {
            id: arr0?.node?.id,
            ...editted
        };
        arr.forEach((params) => {
            newNode.data = {
                ...newNode.data,
                [params?.colDef?.id]: params.newValue
            };
            if (newNode.data[`${params?.colDef?.id}_old`] == undefined) {
                newNode.data[`${params?.colDef?.id}_old`] = params.oldValue;
            }
        });
        newEditted[arr0?.node?.id] = newNode;
    });
    Object.keys(editGrp).forEach((g) => {
        if (!newEditted[g]) {
            newEditted[g] = editGrp[g][0];
        }
    });
    const newLst = Object.values(newEditted);
    setEditList(newLst);
    setStatusBarData((prev) => {
        return {
            ...prev,
            editedRows: newLst.length
        };
    });
};

export const onCellEditingStopped = (
    params: any,
    setEditMode,
    statusBarData,
    setStatusBarData,
    setValueAlert,
    tableControls,
    setEditList,
    editList,
    editCallback
) => {
    if (params.oldValue == params.newValue) return;
    const editedColumn = [params?.column?.colDef?.id, params?.column?.colDef?.headerName];
    let skipCheck = false;
    if (typeof params?.oldValue === 'boolean' && (params?.newValue === 'true' || params?.newValue === 'false')) {
        if ((params?.oldValue && params.newValue === 'true') || (!params?.oldValue && params.newValue === 'false')) return;
        params.node.setDataValue(params.column, params.newValue === 'true');
        skipCheck = true;
    }
    const columnDefs = params?.colDef;
    const columnValueLimit = columnDefs?.valueLimit;
    if (params?.newValue > columnValueLimit?.max || params?.newValue < columnValueLimit?.min) {
        setValueAlert(true);
        params.node.setDataValue(params.column.colId, params.oldValue);
        return;
    }
    params.data.hasBeenEdited = true;
    params.api.refreshCells({ force: true, rowNodes: [params.node] });
    if (editCallback) {
        editCallback(params);
    }
    const isInEditList = editList.find((node: any) => node?.id === params?.node?.id);
    setEditMode(true);
    if (isInEditList) {
        return;
    } else {
        setStatusBarData({
            ...statusBarData,
            editedRows: statusBarData.editedRows + 1
        });
        const newNode = {
            ...params.node,
            data: {
                ...params.node.data,
                [params?.colDef?.id]: params.oldValue
            }
        };
        setEditList([...editList, newNode]);
    }
};

export const onValueDrag = (params, setEditMode, setStatusBarData, statusBarData, setValueAlert, tableControls, editList, setEditList) => {
    const value = params.initialValues[0];
    const numberOfEditedRows = changeRangeValue(params, setValueAlert, value, tableControls, editList, setEditList);
    setStatusBarData({
        ...statusBarData,
        editedRows: statusBarData.editedRows + numberOfEditedRows
    });
    setEditMode(true);
};

export const resetTable = (tableRef, tableControls, columnDefs, setCustomeUserState, gridReady) => {
        tableRef.current.api.setColumnDefs(columnDefs);
        tableRef?.current?.api?.deselectAll();
        tableRef?.current?.columnApi?.resetColumnState();
    setCustomeUserState(0);
    fixColumnsWidth(tableRef);
    saveColumnState(tableRef?.current, tableControls, gridReady);
    // tableRef?.current?.api?.refreshCells({ force: true });
};

export const changeRangeValue = (params, setValueAlert, value, tableControls, editList, setEditList) => {
    value = value === 'true' ? true : value === 'false' ? false : value;
    const selectedRange = params.api.getCellRanges()[0];
    const start =
        selectedRange.startRow.rowIndex > selectedRange.endRow.rowIndex ? selectedRange.endRow.rowIndex : selectedRange.startRow.rowIndex;
    const end =
        selectedRange.endRow.rowIndex > selectedRange.startRow.rowIndex ? selectedRange.endRow.rowIndex : selectedRange.startRow.rowIndex;
    const column = selectedRange.columns[0];
    if (!column?.colDef?.editable) return;
    let newEditList: any[] = [];
    let numberOfEditedRows = 0;
    for (let i = start; i <= end; i++) {
        const rowNode = params.api.getDisplayedRowAtIndex(i);
        value = typeof rowNode.data[column.colDef.id] === 'number' && value.toString().match(/^[0-9]+$/) ? Number(value) : value;
        const columnValueLimit = column?.colDef?.valueLimit;
        if (
            valueCheck(setValueAlert, rowNode.data[column.colDef.id], value, columnValueLimit, [column.colDef.id, column.colDef.headerName])
        ) {
            params?.node?.setDataValue(params.column.colId, rowNode.data[column.colDef.id]);
            return;
        }
        if (rowNode.data?.[column.colDef.id] === value) continue;
        const isInEditList = editList.find((node: any) => node?.id === rowNode?.id);
        if (!isInEditList) {
            !rowNode.data?.hasBeenEdited && numberOfEditedRows++;
            rowNode.data.hasBeenEdited = true;
            newEditList.push({
                ...rowNode,
                data: {
                    ...rowNode.data,
                    [column.colDef.id]: rowNode.data[column.colDef.id]
                }
            });
        }
        rowNode.data[column.colDef.id] = value;
        params.api.refreshCells({ force: true });
    }
    setEditList([...editList, ...newEditList]);
    return numberOfEditedRows;
};

export const openColumnMenuOnRightClick = (event, tableRef) => {
    if (!event?.target?.className || typeof event?.target?.className !== 'string') return;
    if (event?.target?.className?.includes('header') || event?.target?.parentElement?.className?.includes('header')) {
        event?.preventDefault();
        const colId = event?.target?.innerText?.toLowerCase();
        const column = tableRef?.current?.columnApi?.getColumns()?.find((column) => column?.colDef?.headerName === colId);
        tableRef?.current?.api?.showColumnMenuAfterMouseClick(column, event);
    }
};

export const valueCheck = (setValueAlert, originValue, newValue, columnValueLimit?, columnDet?) => {
    if (typeof originValue === 'boolean' && typeof newValue !== 'boolean') {
        setValueAlert(true);
        return true;
    }
    if (typeof originValue === 'number' && !newValue.toString().match(/^[0-9]+$/)) {
        setValueAlert(true);
        return true;
    }
    if (typeof originValue === 'string' && typeof newValue !== 'string') {
        setValueAlert(true);
        return true;
    }
    if (columnValueLimit && (newValue > columnValueLimit.max || newValue < columnValueLimit.min)) {
        setValueAlert(true);
        return true;
    }
    return false;
};
