import {useDispatch} from 'react-redux';
import {useQuery, useMutation, useQueryClient} from 'react-query';
import {SNACKBAR_OPEN} from 'store/actions';
import {
    getCustomerDocuments,
    getDocuments,
    getAllCustomerDocuments,
    createRealCustomerDocuments,
    cancelCustomerDocuments,
    getSpecificDocument,
    getClientsDaily,
    getBaseOrder,
    close_all_dv,
    newClientDebt,
    getAllSupplierDocuments,
    createSupplierNote,
    changeAllOrderNoteDate,
    update_note_amounts,
    updateClientPay,
    close_all_tn,
    updateRealCustomerDocument,
    refreshDocsCall, newAllOrderNote, dvFromOrder, close_all_docs
} from 'api/customer/documents.api';
import {DocumentType, ExtendedDocument, ProductNote} from 'types/DocumentsTypes/Document';
import {CustomerDocumentsQueryOptions} from 'api/customer/documents.api';
import {PayNote} from "../../types/DocumentsTypes/PayNote";
import React from "react";
import {OrderData} from "../../types/OrderData";
import {getSpecialPrice} from "../../api/customer/products_prices.api";
import {dateObjectToString} from "../../fromKotlin/nkutils";
import JSDB from "../../fromKotlin/nk";

const DOCUMENTS = 'documents';
export const CUSTOMER_DOCUMENTS = 'customerDocuments';
export const SUPPLIER_DOCUMENTS = 'supplierDocuments';
export const CLIENT_DAILY = 'customerDaily';

export const useGetDocuments = (opts?: CustomerDocumentsQueryOptions) =>
    useQuery([DOCUMENTS, ...Object.values(opts ?? [])], () => getDocuments(opts).catch(reason => {
            const dispatch = useDispatch();
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'שגיאה בגישה לנתונים',
                variant: 'alert',
                alertSeverity: 'error'
            });
        }
    ));

export const useGetCustomerDocuments = (cid: string, opts?: CustomerDocumentsQueryOptions) =>
    useQuery([CUSTOMER_DOCUMENTS, ...Object.values(opts ?? [])], () => getCustomerDocuments(cid, opts).catch(reason => {
            const dispatch = useDispatch();
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'שגיאה בגישה לנתונים',
                variant: 'alert',
                alertSeverity: 'error'
            });
        }
    ));


export const useGetSpecificCustomerDocumentsComplex = (types: DocumentType[], cid?: number, fromDate?: Date, toDate?: Date, open?: boolean, specific?: number, full?: boolean, day?: number, onlyProducts?: boolean, branched?: boolean, api?: boolean, check_date?: Date, inventory?: boolean, options?: any) => {
    const queryClient = useQueryClient();
    const intervalRef = React.useRef<number | null>(null);
    const [lastFetched, setLastFetched] = React.useState<number | null>(null);
    const key = [CUSTOMER_DOCUMENTS];  // Define this appropriately based on your existing setup

    // ... rest of your existing hook code ...

    React.useEffect(() => {
        function fetchAndUpdateTimestamp() {
            queryClient.invalidateQueries(key);
            setLastFetched(Date.now());
        }

        function checkAndFetch() {
            if (!lastFetched || Date.now() - lastFetched >= (options?.refetchInterval || 0)) {
                fetchAndUpdateTimestamp();
            } else {
                startInterval();
            }
        }

        function startInterval() {
            const remainingTime = (options?.refetchInterval || 0) - (Date.now() - (lastFetched || Date.now()));
            intervalRef.current = window.setTimeout(() => {

                fetchAndUpdateTimestamp();
                startInterval();
            }, remainingTime);
        }

        function stopInterval() {
            if (intervalRef.current !== null) {
                clearTimeout(intervalRef.current);
                intervalRef.current = null;
            }
        }

        if (document.hasFocus()) {
            checkAndFetch();
        }

        window.addEventListener('focus', checkAndFetch);
        window.addEventListener('blur', stopInterval);

        return () => {
            stopInterval();
            window.removeEventListener('focus', checkAndFetch);
            window.removeEventListener('blur', stopInterval);
        };
    }, [key, options?.refetchInterval, queryClient, lastFetched]);

    // The actual useQuery part from your existing code...
    return useQuery([CUSTOMER_DOCUMENTS], async () => {
        const r: any[] = [];
        const documents = []
        for (const type of types) {

            let result: any;
            if (day && day !== -1) {
                if (type == 'order') {
                    result = await getBaseOrder(day, specific, full);
                } else {
                    // eslint-disable-next-line no-await-in-loop
                    result = [];
                }
            } else {
                // eslint-disable-next-line no-await-in-loop
                documents.push(type)
                result = [];

            }
            r.push(...result);
        }
        const result = await getAllCustomerDocuments(fromDate, toDate, full, undefined, api, undefined, true, undefined, undefined, undefined, undefined, documents);
        r.push(...result);
        return r;
    }, {

        refetchOnWindowFocus: false,  // Important to set this to false
    });

}
export const useGetSpecificCustomerDocuments = (types: DocumentType[], cid?: number, fromDate?: Date, toDate?: Date, open?: boolean, specific?: number, full?: boolean, day?: number, onlyProducts?: boolean, branched?: boolean, api?: boolean, check_date?: Date, inventory?: boolean, options?: any, poolName?: string, cost?: boolean) =>

    useQuery([poolName ? poolName : CUSTOMER_DOCUMENTS], async () => {
            const r: any[] = [];

            // Start the cost-related asynchronous operation in parallel (if needed)
            let costResPromise: Promise<any> | undefined;
            if (cost) {
                const d = new Date();
                costResPromise = getSpecialPrice("-1", dateObjectToString(fromDate ?? d), dateObjectToString(toDate ?? d));
            }

            // Prepare an array of promises for the types-related asynchronous operations
            const typePromises = types.map(async (type) => {
                let result: any;
                if (day && day !== -1) {
                    if (type === 'order') {
                        result = [];
                    } else {
                        result = await getBaseOrder(day, specific, full);
                    }
                } else {
                    result = await getSpecificDocument(type, cid, fromDate, toDate, open, specific, full, onlyProducts, undefined, branched, api, check_date, inventory);
                }
                return result;
            });
            const shouldSerialize = true || JSDB().getUser().first?.company_type != 4
            // Await all the type-related promises in parallel
            if (shouldSerialize) {
                // Execute promises sequentially
                for (const promise of typePromises) {
                    const result = await promise;
                    r.push(...result);
                }
            } else {
                // Execute promises in parallel
                const typeResults = await Promise.all(typePromises);
                for (const result of typeResults) {
                    r.push(...result);
                }
            }

            // Await the cost-related operation if it was initiated
            if (costResPromise) {
                const costRes = await costResPromise;
                // Handle the costRes as needed
            }

            return r;
        }
        , options);

export const useGetCustomerAllDocuments = (dateFrom: Date, dateTo: Date, full: boolean, cid?: string, apis?: boolean, tax_only?: boolean, productOnly?: boolean, visit?: boolean, poolName?: string, cost?: boolean, documents?: string[]) =>
    useQuery([poolName ? poolName : CUSTOMER_DOCUMENTS], () => getAllCustomerDocuments(dateFrom, dateTo, full, cid, apis, tax_only, productOnly, undefined, visit, undefined, cost, documents).catch(reason => {
            const dispatch = useDispatch();
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'שגיאה בגישה לנתונים',
                variant: 'alert',
                alertSeverity: 'error'
            });
        }
    ));
export const useGetSupplierAllDocuments = (dateFrom: Date, dateTo: Date, full: boolean, cid?: string, tax_only?: boolean, poolName?: string) =>
    useQuery([poolName ?? SUPPLIER_DOCUMENTS], () => getAllSupplierDocuments(dateFrom, dateTo, full, cid, tax_only).catch(reason => {
            const dispatch = useDispatch();
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'שגיאה בגישה לנתונים',
                variant: 'alert',
                alertSeverity: 'error'
            });
        }
    ));


export const useGetCustomerAllDocumentsNoRefetch = (dateFrom: Date, dateTo: Date, full: boolean, cid?: string, apis?: boolean, tax_only?: boolean, cost?: boolean, visit?: boolean, documents?: string[]) =>
    useQuery([CUSTOMER_DOCUMENTS], () => getAllCustomerDocuments(dateFrom, dateTo, full, cid, apis, tax_only, undefined, undefined, visit, undefined, cost, documents).catch(reason => {
            console.log(reason)
            const dispatch = useDispatch();
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'שגיאה בגישה לנתונים',
                variant: 'alert',
                alertSeverity: 'error'
            });
        }
    ), {
        refetchOnWindowFocus: false,
        enabled: false // turned off by default, manual refetch is needed
    });
export const useGetClientDaily = (dateFrom: Date, dateTo: Date, day: number, cid?: number) =>
    useQuery([CLIENT_DAILY], () => getClientsDaily(dateFrom, dateTo, cid, day).catch(reason => {
            const dispatch = useDispatch();
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'שגיאה בגישה לנתונים',
                variant: 'alert',
                alertSeverity: 'error'
            });
        }
    ), {
        refetchOnWindowFocus: false,
        enabled: false // turned off by default, manual refetch is needed
    });

export const useCreateCustomerDocument = (cid: string, sup?: boolean) => {
    const cache = useQueryClient();
    const dispatch = useDispatch();

    return useMutation(sup ? SUPPLIER_DOCUMENTS : CUSTOMER_DOCUMENTS, (doc: ExtendedDocument) => createRealCustomerDocuments(cid, doc), {
        onSuccess: () => {
            cache.invalidateQueries(sup ? SUPPLIER_DOCUMENTS : CUSTOMER_DOCUMENTS);
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'המסמך נוצר בהצלחה!',
                variant: 'alert',
                alertSeverity: 'success'
            });
        },
        onError: () => {
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'אירעה שגיאה ביצירת המסמך',
                variant: 'alert',
                alertSeverity: 'error'
            });
        }
    });
};
export const useCreateSupplierDocument = (cid: string) => {
    const cache = useQueryClient();
    const dispatch = useDispatch();

    return useMutation(SUPPLIER_DOCUMENTS, (doc: ExtendedDocument) => createSupplierNote(cid, doc), {
        onSuccess: () => {
            cache.invalidateQueries(SUPPLIER_DOCUMENTS);
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'המסמך נוצר בהצלחה!',
                variant: 'alert',
                alertSeverity: 'success'
            });
        },
        onError: () => {
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'אירעה שגיאה ביצירת המסמך',
                variant: 'alert',
                alertSeverity: 'error'
            });
        }
    });
};

export const useCreateCustomerDebt = (pool, sup) => {
    const cache = useQueryClient();
    const dispatch = useDispatch();

    return useMutation(pool ?? CUSTOMER_DOCUMENTS, ({
                                                        cid,
                                                        date,
                                                        positive,
                                                        negative,
                                                        notes
                                                    }: { cid: number, date: Date, positive: number, negative: number, notes: string }) => newClientDebt(cid, date, positive, negative, notes, sup), {
        onSuccess: () => {
            cache.invalidateQueries(pool ?? CUSTOMER_DOCUMENTS);
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'החוב נוצר בהצלחה!',
                variant: 'alert',
                alertSeverity: 'success'
            });
        },
        onError: () => {
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'אירעה שגיאה ביצירת החוב',
                variant: 'alert',
                alertSeverity: 'error'
            });
        }
    });
};


export const useUpdateCustomerDocument = (type: string, state: number, pool?: string) => {
    const cache = useQueryClient();
    const dispatch = useDispatch();
    return useMutation(pool ?? (type == 'client' ? CUSTOMER_DOCUMENTS : SUPPLIER_DOCUMENTS), (doc: ExtendedDocument) => state == 1 ? updateClientPay(doc.id, doc as PayNote) : state == 2 ? updateRealCustomerDocument(doc) : updateRealCustomerDocument(doc), {
        onSuccess: (data, variables) => {
            cache.setQueryData(pool ?? (type == 'client' ? CUSTOMER_DOCUMENTS : SUPPLIER_DOCUMENTS), (oldData: ExtendedDocument[]) => {
                let exist = oldData?.find((d) => d.id == data.id && d.type == data.type)

                const ret = oldData?.map((d) => {
                    if (exist && data?.id == d.id && data.type == d.type) {
                        return data;
                    } else if (!exist && variables?.id == d.id && variables.type == d.type) {
                        return {...variables, status: "canceled"};
                    } else {
                        return d;
                    }
                }) ?? [];
                if (!exist) {
                    ret.push(data)
                }

                return ret;

            });


            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'המסמך עודכן בהצלחה!',
                variant: 'alert',
                alertSeverity: 'success'
            });
        },
        onError: (error, variables, context) => {
            console.log(error)
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'אירעה שגיאה בעדכון המסמך',
                variant: 'alert',
                alertSeverity: 'error'
            });
        }
    });
};
export const refreshDocs = (pool) => {
    const cache = useQueryClient();
    return useMutation(pool ?? CUSTOMER_DOCUMENTS, ({
                                                        ids,
                                                        types,
                                                        full,
                                                        offline
                                                    }: { ids: number[], types: number[], full?: boolean, offline?: boolean }) => refreshDocsCall(ids, types, offline, full), {
        onSuccess: (data) => {
            const ids = data.reduce((map, obj) => {
                map[`${obj.id},${obj.type}`] = obj;
                return map;
            }, {});
            cache.setQueryData(pool ?? CUSTOMER_DOCUMENTS, (oldData: ExtendedDocument[]) => oldData?.map((d) => {
                const key = `${d.id},${d.type}`;
                return (key in ids) ?
                    ids[key] : d;
            }));

        },
        onError: () => {

        }
    });
};

export const useActivateCustomerDocument = (pool?: string) => {
    const cache = useQueryClient();
    const dispatch = useDispatch();

    return useMutation(pool ?? CUSTOMER_DOCUMENTS, ({
                                                        doc,
                                                        changeDate
                                                    }: { doc: ExtendedDocument, changeDate?: Date }) => cancelCustomerDocuments(doc, changeDate), {
        onSuccess: (data, variables) => {
            cache.setQueryData(pool ?? CUSTOMER_DOCUMENTS, (oldData: ExtendedDocument[]) => {
                const ret = oldData?.map((d) => {
                    if (variables.doc.id == d.id && variables.doc.type == d.type) {
                        return variables.doc;
                    } else {
                        return d;
                    }

                }) ?? [];
                if (variables.doc.id != data.id)
                    ret.push(data);
                return ret;

            });


            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'המסמך עודכן בהצלחה!',
                variant: 'alert',
                alertSeverity: 'success'
            });
        },
        onError: () => {
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'אירעה שגיאה בעדכון המסמך',
                variant: 'alert',
                alertSeverity: 'error'
            });
        }
    });
};

export const useEditComment = (pool) => {
    const cache = useQueryClient();
    const dispatch = useDispatch();

    return useMutation(pool ?? CUSTOMER_DOCUMENTS, ({
                                                        doc
                                                    }: { doc: ExtendedDocument }) => {
        return updateRealCustomerDocument(doc)
    }, {
        onSuccess: (data, variables) => {
            cache.setQueryData(pool ?? CUSTOMER_DOCUMENTS, (oldData: ExtendedDocument[]) => {

                const ret = oldData?.map((d) => {
                    if (variables.doc.id == d.id && variables.doc.type == d.type) {
                        return data;
                    } else {
                        return d;
                    }

                });
                if (variables.doc.id != data.id)
                    ret.push(data);
                return ret;

            });


            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'המסמך עודכן בהצלחה!',
                variant: 'alert',
                alertSeverity: 'success'
            });
        },
        onError: () => {
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'אירעה שגיאה בעדכון המסמך',
                variant: 'alert',
                alertSeverity: 'error'
            });
        }
    });
};
export const usePaidTN = (pool, sup) => {
    const cache = useQueryClient();
    const dispatch = useDispatch();

    return useMutation(pool ?? sup ? SUPPLIER_DOCUMENTS : CUSTOMER_DOCUMENTS, (docs: ExtendedDocument[]) => close_all_tn(docs.filter((d) => d.type == 'invoice' || d.type == 'refund' || d.type == 'sup_invoice' || d.type == 'sup_refund')), {
        onSuccess: (data) => {
            const ids = data.reduce((map, obj) => {
                map[obj.id] = obj;
                return map;
            }, {});
            cache.setQueryData(pool ?? sup ? SUPPLIER_DOCUMENTS : CUSTOMER_DOCUMENTS, (oldData: ExtendedDocument[]) => oldData?.map((d) => {
                return (d.id in ids && ('invoice' == d.type || d.type == 'refund' || d.type == 'sup_invoice' || d.type == 'sup_refund')) ?
                    ids[d.id] : d;
            }));


            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'המסמך עודכן בהצלחה!',
                variant: 'alert',
                alertSeverity: 'success'
            });
        },
        onError: () => {
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'אירעה שגיאה בעדכון המסמך',
                variant: 'alert',
                alertSeverity: 'error'
            });
        }
    });
};

export const usePaidDvs = (pool) => {
    const cache = useQueryClient();
    const dispatch = useDispatch();

    return useMutation(pool ?? CUSTOMER_DOCUMENTS, (docs: ExtendedDocument[]) => close_all_docs(docs), {
        onSuccess: (data) => {
            const ids = data.reduce((map, obj) => {
                map[`${obj.id}-${obj.type}`] = obj;
                return map;
            }, {});
            console.log(ids)
            cache.setQueryData(pool ?? CUSTOMER_DOCUMENTS, (oldData: ExtendedDocument[]) => oldData?.map((d) => {
                return (`${d.id}-${d.type}` in ids) ?
                    ids[`${d.id}-${d.type}`] : d;
            }));


            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'המסמך עודכן בהצלחה!',
                variant: 'alert',
                alertSeverity: 'success'
            });
        },
        onError: () => {
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'אירעה שגיאה בעדכון המסמך',
                variant: 'alert',
                alertSeverity: 'error'
            });
        }
    });
};


export const useOpenOrders = (pool) => {
    const cache = useQueryClient();
    const dispatch = useDispatch();

    return useMutation(pool ?? CUSTOMER_DOCUMENTS, ({
                                                        docs,
                                                        date
                                                    }: { docs: ExtendedDocument[], date: Date }) => changeAllOrderNoteDate(docs.filter((d) => d.type == 'order' && d.status == 'open').map((o) => o.id), date), {
        onSuccess: (data) => {
            const ids = data.reduce((map, obj) => {
                map[obj.id] = obj;
                return map;
            }, {});
            cache.setQueryData(pool ?? CUSTOMER_DOCUMENTS, (oldData: ExtendedDocument[]) => oldData?.map((d) => {
                return (d.id in ids && ('order' == d.type)) ?
                    ids[d.id] : d;
            }));


            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'ההזמנות עודכנו בהצלחה!',
                variant: 'alert',
                alertSeverity: 'success'
            });
        },
        onError: () => {
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'אירעה שגיאה בעדכון ההזמנות',
                variant: 'alert',
                alertSeverity: 'error'
            });
        }
    });
};

export const useOpenOrders2 = (pool) => {
    const cache = useQueryClient();
    const dispatch = useDispatch();

    return useMutation(pool ?? CUSTOMER_DOCUMENTS, ({
                                                        docs,
                                                        date
                                                    }: { docs: OrderData[], date: Date }) => changeAllOrderNoteDate(docs.filter((d) => d.orderId != '').map((o) => Number(o.orderId)), date), {
        onSuccess: (data) => {
            const ids = data.reduce((map, obj) => {
                map[obj.id] = obj;
                return map;
            }, {});
            cache.setQueryData(pool ?? CUSTOMER_DOCUMENTS, (oldData: ExtendedDocument[]) => oldData?.map((d) => {
                return (d.id in ids && ('order' == d.type)) ?
                    ids[d.id] : d;
            }));


            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'ההזמנות עודכנו בהצלחה!',
                variant: 'alert',
                alertSeverity: 'success'
            });
        },
        onError: () => {
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'אירעה שגיאה בעדכון ההזמנות',
                variant: 'alert',
                alertSeverity: 'error'
            });
        }
    });
};

export const createDVFromOrder = (pool) => {
    const cache = useQueryClient();
    const dispatch = useDispatch();

    return useMutation(pool ?? CUSTOMER_DOCUMENTS, ({
                                                        docs,
                                                        date,
                                                        refresh
                                                    }: { docs: ExtendedDocument[], date: Date, refresh?: boolean }) => dvFromOrder(date, docs.filter((d) => d.type == 'order' && d.status == 'open' && d.products.length > 0), refresh), {
        onSuccess: (data, variables) => {

            const ids = data!.reduce((map, obj) => {
                map[obj.id] = obj;
                return map;
            }, {});
            cache.setQueryData(pool ?? CUSTOMER_DOCUMENTS, (oldData: ExtendedDocument[]) => {


                const cur = oldData.map((d) => {
                    return (d.id in ids && ('order' == d.type)) ?
                        ids[d.id] : d;
                })
                const newDocs = data!.filter((d) => d.type == 'delivery_certificate' || d.type == 'tax_invoice')
                return [...cur, ...newDocs]

            });


            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'תעודות נוצרו בהצלחה!',
                variant: 'alert',
                alertSeverity: 'success'
            });
        },
        onError: () => {
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'אירעה שגיאה ביצירת תעודות',
                variant: 'alert',
                alertSeverity: 'error'
            });
        }
    });
};
