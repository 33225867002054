import { FormattedMessage } from 'react-intl';
import { IconUserCheck, IconTruck, IconBuildingStore, IconFile } from '@tabler/icons';

const application = {
    id: 'application',
    type: 'group',
    children: [
        {
            id: 'customers',
            title: <FormattedMessage id="customers" />,
            type: 'collapse',
            icon: IconUserCheck,
            children: [
                {
                    id: 'customer-list',
                    title: <FormattedMessage id="customer-list" />,
                    type: 'item',
                    url: '/customer/customer-list',
                    breadcrumbs: true
                },
                {
                    id: 'customer-orders',
                    title: <FormattedMessage id="customer-orders" />,
                    type: 'collapse',
                    children: [
                        {
                            id: 'customer-orders',
                            title:'הזמנות ישן',
                            type: 'item',
                            url: '/orders',
                            breadcrumbs: true,
                        },
                        {
                            id: 'customer-orders-old',
                            title: 'הזמנות חדש',
                            type: 'item',
                            url: '/orders-new',
                            breadcrumbs: true,
                        },
                    ]
                },
                {
                    id: 'customer-documents',
                    title: <FormattedMessage id="customer-documents" />,
                    type: 'collapse',
                    children: [
                        {
                            id: 'customer-orders-all-documents',
                            title: <FormattedMessage id="customer-orders-all-documents" />,
                            type: 'item',
                            url: '/customer/documents/all-documents',
                            breadcrumbs: true
                        },
                        {
                            id: 'customer-orders-all-products-spread',
                            title: <FormattedMessage id="customer-orders-all-products-spread" />,
                            type: 'item',
                            url: '/customer/documents/all-products-spread',
                            breadcrumbs: true
                        },
                        {
                            id: 'customer-orders-reciept',
                            title: <FormattedMessage id="customer-orders-reciept" />,
                            type: 'item',
                            url: '/customer/documents/reciept',
                            breadcrumbs: true
                        },
                        {
                            id: 'customer-orders-open-orders',
                            title: <FormattedMessage id="customer-orders-open-orders" />,
                            type: 'item',
                            url: '/customer/documents/open-orders',
                            breadcrumbs: true
                        },
                        {
                            id: 'customer-orders-open-invoices',
                            title: <FormattedMessage id="customer-orders-open-invoices" />,
                            type: 'item',
                            url: '/customer/documents/open-invoices',
                            breadcrumbs: true
                        },
                    ]
                },
                // {
                //     id: 'customer-compare',
                //     title: 'השוואות וגידול',
                //     type: 'item',
                //     url: '/customer/all-compare',
                //     breadcrumbs: true
                // },
                {
                    id: 'customer-tax-notes',
                    title: 'הנפקת חשבוניות מרוכזת',
                    type: 'item',
                    url: '/customer/all-tax-note',
                    breadcrumbs: true
                },
                {
                    id: 'customer-debts',
                    title: <FormattedMessage id="customer-debts" />,
                    type: 'item',
                    url: '/customer/all-debt',
                    breadcrumbs: true
                },
                {
                    id: 'customer-users',
                    title: <FormattedMessage id="customer-users" />,
                    type: 'item',
                    url: '/customer/users',
                    breadcrumbs: true
                },
                {
                    id: 'customer-sent-messages',
                    title: <FormattedMessage id="customer-sent-messages" />,
                    type: 'item',
                    url: '/customer/sent-messages',
                    breadcrumbs: true
                },                {
                    id: 'customer-cp-data',
                    title: 'מוצרים מיוחדים',
                    type: 'item',
                    url: '/customer/cp-data',
                    breadcrumbs: true
                },
            ]
        },
        {
            id: 'reports',
            title: <FormattedMessage id="reports" />,
            type: 'collapse',
            icon: IconFile,
            children: [
                {
                    id: 'customer-amounts',
                    title: 'נתוני חלוקה',
                    type: 'item',
                    url: '/reports/all-amount',
                    breadcrumbs: true
                },
                {
                    id: 'customer-visitation',
                    title: 'דוח ביקורים',
                    type: 'item',
                    url: '/reports/all-visitation',
                    breadcrumbs: true
                },
                {
                    id: 'customer-compare',
                    title: <FormattedMessage id="customer-compare" />,
                    type: 'item',
                    url: '/reports/all-compare',
                    breadcrumbs: true
                },       {
                    id: 'customer-giul',
                    title:'גיול חובות',
                    type: 'item',
                    url: '/reports/all-giul',
                    breadcrumbs: true
                },
                {
                    id: 'reports-use',
                    title: "דוחות סיכום וממשקים",
                    type: 'item',
                    url: '/reports/all-reports',
                    breadcrumbs: true
                },
                {
                    id: "reports-route-plan",
                    title: <FormattedMessage id="customer-documents-route-plan" />,
                    type: 'item',
                    url: '/reports/route-plan',
                    breadcrumbs: true
                }
            ]
        },
        {
            id: 'suppliers',
            title: <FormattedMessage id="suppliers" />,
            type: 'collapse',
            icon: IconTruck,
            children: [
                {
                    id: 'supplier-list',
                    title: <FormattedMessage id="supplier-list" />,
                    type: 'item',
                    url: '/supplier/supplier-list',
                    breadcrumbs: true
                },
                {
                    id: 'supplier-products_docs',
                    title: 'פירוט מוצרים',
                    type: 'item',
                    url: '/supplier/all-products-spread',
                    breadcrumbs: true
                },
                {
                    id: 'supplier-reports',
                    title: 'נתוני אספקה',
                    type: 'item',
                    url: '/supplier/all-amount',
                    breadcrumbs: true
                },
                {
                    id: 'all-debt',
                    title: 'קניות',
                    type: 'item',
                    url: '/supplier/all-debt',
                    breadcrumbs: true
                },
                {
                    id: 'all-documents',
                    title: 'כל המסמכים',
                    type: 'item',
                    url: '/supplier/all-documents',
                    breadcrumbs: true
                }
            ]
        },
        {
            id: 'products',
            title: <FormattedMessage id="products" />,
            type: 'collapse',
            icon: IconBuildingStore,
            children: [
                {
                    id: 'client_products-list',
                    title: <FormattedMessage id="client_products-list" />,
                    type: 'item',
                    url: '/products/customer-products',
                    breadcrumbs: true
                },
                {
                    id: 'product-cost-list',
                    title: 'מחירי עלות',
                    type: 'item',
                    url: '/products/cost',
                    breadcrumbs: true
                },
                {
                    id: 'supplier_products-list',
                    title: <FormattedMessage id="supplier_products-list" />,
                    type: 'item',
                    url: '/products/supplier-products',
                    breadcrumbs: true
                },
                {
                    id: 'products-inventory',
                    title: <FormattedMessage id="products-inventory" />,
                    type: 'item',
                    url: '/products/inventory',
                    breadcrumbs: true
                },
                {
                    id: 'products-reports',
                    title: <FormattedMessage id="products-reports" />,
                    type: 'item',
                    url: '/products/products-reports',
                    breadcrumbs: true
                },
                {
                    id: 'products-taklit',
                    title: <FormattedMessage id="products-taklit" />,
                    type: 'item',
                    url: '/products/taklit',
                    breadcrumbs: true
                },
            ]
        },

    ]
};

export default application;
