import IItem from '../product_delivery';
import {Details, documentTypes, Payment} from './utils';

export type DocumentType =
    'receipt'
    | 'invoice'
    | 'tax_invoice'
    | 'order'
    | 'delivery_certificate'
    | 'refund'
    | 'supplier_note'
    | 'debt'
    | 'visit_note'
    | 'delivery_returns'
    | 'sup_invoice'
    | 'sup_receipt'
    | 'sup_tax_invoice'
    | 'sup_refund'
|'sup_debt'
    | 'empty'
    ;

export function docTypeToDocType(types) {
    return types.map(
        (type) => {
            switch (type) {
                case 'receipt':
                    return documentTypes.receipt.nkType;
                case 'invoice':
                    return documentTypes.invoice.nkType;
                case 'tax_invoice':
                    return documentTypes.tax_invoice.nkType;
                case 'order':
                    return documentTypes.order.nkType;
                case 'delivery_certificate':
                    return documentTypes.delivery_certificate.nkType;
                case 'refund':
                    return documentTypes.refund.nkType;
                case 'supplier_note':
                    return documentTypes.supplier_note.nkType;
                case 'debt':
                    return documentTypes.debt.nkType;
                case 'visit_note':
                    return documentTypes.visit_note.nkType;
                case 'delivery_returns':
                    return documentTypes.delivery_certificate.nkType;
                case 'sup_invoice'    :
                    return documentTypes.sup_invoice.nkType;
                case 'sup_receipt'    :
                    return documentTypes.sup_receipt.nkType;
                case 'sup_tax_invoice':
                    return documentTypes.sup_tax_invoice.nkType;
                case 'sup_refund'      :
                    return documentTypes.sup_refund.nkType;
                case 'sup_debt'      :
                    return documentTypes.sup_debt.nkType;
                case 'empty':
                    return 0;

            }
        }
    )

}
export function isSupplierDocument(type: DocumentType) {
    return type === 'sup_invoice' || type === 'sup_receipt' || type === 'sup_tax_invoice' || type === 'sup_refund' || type === 'sup_debt';
}

export type DocumentStatus = 'open' | 'approved' | 'pending_approval' | 'closed' | 'canceled';
export const documentStatuses = {
    open: 'פתוח',
    approved: 'אושר',
    pending_approval: 'ממתין לאישור',
    closed: 'סגור',
    canceled: 'בוטל'

};

export interface Document {
    id: number;
    entId: string;
    entName: string;
    entBName: string;
    // date: string; // yyyy-mm-dd
    date: Date;
    end: Date;
    issueDate: Date;
    type: DocumentType;


    status: DocumentStatus;
    agent: string;
    mainValue: number;
    taxValue?: number;
    preValue?: number;
    ref_id?: string
    nkObject: any;


}

export interface ExtendedDocument extends Document {

    comment: string;
    category?: string;
    sign?: boolean;
    photo?: boolean;
    pdf?: boolean
    // metadata
    color: string;
    textColor: string;
    baseColor: string;
    description: string;
    title: string;
    name: string;
    allDay: boolean;
    notvalid?: boolean;
    storeNext?: any;
    hashavshevet?: any;
    manager?: any;
    one?: any;
    driver?: string
    orderId?: number
    agent_id?: number
    notes2?: string
    notes3?: string
    notes4?: string
    notes5?: string
    taxConfirmation?: string

}

export interface ProductNote extends ExtendedDocument {
    bul?: number;
    orderStatus?: string;
    user_id?: number;
    products: IItem[];
    returnsOnly?: boolean

}

export interface PaymentNote extends ExtendedDocument {
    details?: Details;
    payments: Payment[];
    refundRest?: boolean
}


export const typeStr = (doc): string => documentTypes[doc.type].name;
