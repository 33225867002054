import axios from 'axios';

import {CUSTOMER_DOCUMENTS_API, DOCUMENTS_API} from 'api/routes';
import {QueryOptions, buildQueryOptions} from 'api/utils';
import JSDB, {ApiCall, NKTypeConvertor} from '../../fromKotlin/nk';
import IItem from 'types/product_delivery';
import {
    dateObjectToString,
    dateObjectToStringIsrael,
    dateTimeObjectToString,
    roundDecimals
} from '../../fromKotlin/nkutils';
import {
    DocumentStatus,
    ExtendedDocument,
    ProductNote,
    DocumentType,
    docTypeToDocType, isSupplierDocument
} from 'types/DocumentsTypes/Document';
import {
    createDocument,
    deserializeCarteset,
    deserializeDetails,
    deserializeDocument,
    deserializePayment,
    deserializePaymentMethod,
    documentTypes,
    Payment,
    serializePayment,
    toArrayOrEmpty,
    visitStates,
    visitStatesMap
} from 'types/DocumentsTypes/utils';
import {TaxNote} from 'types/DocumentsTypes/TaxNote';
import {PayNote} from '../../types/DocumentsTypes/PayNote';
import {CartesetDataHolder} from '../../types/CartesetDataHolder';
import {
    deserializeCustDataToClientDaily,
    deserializeDataStruct, deserializeDataStructOld,
    deserializeOrderDataToClientDaily,
    deserializeOrderDataToOrder,
    OrderData
} from '../../types/OrderData';
import {OrderActiveState} from '../../types/OrderActiveState';
import {ICustomer} from '../../cdmanagment/src/types/customerStore-types';
import {AllTaxNoteHolder} from '../../types/AllTaxNoteHolder';
import {groupBy, groupByUnStr} from './analytics.api';
import {OrderManagment, RoutePlan} from "../../types/plannedVactuall";
import {distinct, distinctByField} from "../../utils/sort";
import {debtDataHolder} from "../../types/debtDataHolder";

export interface CustomerDocumentsQueryOptions extends QueryOptions {
    status?: DocumentStatus[];
    type?: DocumentType;
}

export const getDocuments: (opts?: CustomerDocumentsQueryOptions) => Promise<ExtendedDocument[]> = async (opts) => {
    const url = `${DOCUMENTS_API}${buildQueryOptions(opts)}`;
    const res = await axios.get(url);
    return res.data.documents.map((d) => createDocument({...d}));
};

export const getCustomerDocuments: (cid: string, opts?: CustomerDocumentsQueryOptions) => Promise<ExtendedDocument[]> = async (cid, opts) => {
    const url = `${CUSTOMER_DOCUMENTS_API(cid)}${buildQueryOptions(opts)}`;
    const res = await axios.get(url);
    return res.data.documents.map((d) => createDocument({...d}));
};

export const createCustomerDocument: (cid: string, d: Document) => Promise<ExtendedDocument> = async (cid, d) => {
    const res = await axios.post(CUSTOMER_DOCUMENTS_API(cid), d);
    return res.data.document;
};


// YOTAM

export const docResultsDeserialize: (ret: any) => ExtendedDocument[] = (ret) => {
    if (ret.size > 0) {
        return ret.toArray().map((d) => deserializeDocument(d));
    }
    return [];
};

export const getBaseOrder: (day: number, client_id?: number[], full?: boolean) => Promise<ExtendedDocument[]> = async (day, client_id, full = undefined) => {
    const req = new ApiCall('GET_CLIENT_BASE_ORDER');
    if (client_id)
        req.putParam('client_ids', NKTypeConvertor().toKotlinList(client_id));
    req.putParam('day', day);
    if (full)
        req.putParam('full', full);
    const result = await req.execute();
    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    return docResultsDeserialize(result.first);

};

export const getSpecificDocument: (type: DocumentType, cid?: number, fromDate?: Date, toDate?: Date, open?: boolean, specific?: number, full?: boolean, onlyProducts?: boolean, cids?: number[], branched?: boolean, api?: boolean, check_date?: Date, inventory?: boolean) => Promise<ExtendedDocument[]> = async (type, cid = undefined, fromDate = undefined, toDate = undefined, open = undefined, specific = undefined, full = undefined, onlyProducts = undefined, cids = undefined, branched = undefined, api = undefined, check_date = undefined, inventory = undefined) => {
    let req;
    if (type === 'delivery_certificate') {
        req = new ApiCall('GET_CLIENT_NOTE');
        // req.putParam("api",true)
        if (specific)
            req.putParam('id', Number(specific));
    } else if (type === 'invoice' || type==='sup_invoice') {
        req = new ApiCall('GET_CLIENT_TAX_NOTE');
        if (specific)
            req.putParam('id', Number(specific));
        if (onlyProducts)
            req.putParam('withProduct', Number(1));
        if(type=='sup_invoice')
            req.putParam('sup', true);
        req.putParam('type', Number(0));
    } else if (type === 'tax_invoice' || type==='sup_tax_invoice') {
        req = new ApiCall('GET_CLIENT_TAX_NOTE');
        if (specific)
            req.putParam('id', Number(specific));
        if (onlyProducts)
            req.putParam('withProduct', Number(1));
        if(type=='sup_tax_invoice')
            req.putParam('sup', true);
        if (check_date)
            req.putParam('check_date', (dateObjectToStringIsrael(check_date)));
        req.putParam('type', Number(1));
    } else if (type === 'refund' || type==='sup_refund') {
        req = new ApiCall('GET_CLIENT_TAX_NOTE');
        if (specific)
            req.putParam('id', Number(specific));
        if (onlyProducts)
            req.putParam('withProduct', Number(1));
        if(type=='sup_refund')
            req.putParam('sup', true);
        req.putParam('type', Number(2));
    } else if (type === 'order') {
        req = new ApiCall('GET_CLIENT_ORDER');
        if (open != undefined && open) {
            req.putParam('inverse_status', NKTypeConvertor().toKotlinList([2, 0, 13, 12, 16]));
        }
        if (specific)
            req.putParam('order_id', Number(specific));
        if (!inventory)
            req.putParam('withoutInventory', true);
    } else if (type === 'receipt' || type==='sup_receipt') {
        req = new ApiCall('GET_CLIENT_PAY');
        if (specific)
            req.putParam('id', Number(specific));
        if(type=='sup_receipt')
            req.putParam('sup', true);
        if (check_date)
            req.putParam('check_date', (dateObjectToString(check_date)));
    } else if (type === 'supplier_note') {

        req = new ApiCall('GET_SUPPLIER_NOTE');
        if (specific)
            req.putParam('delivery_id', Number(specific));
        if (cid)
            req.putParam('supplier_id', Number(cid));
        if (cids)
            req.putParam('client_ids', NKTypeConvertor().toKotlinList(cids));
    } else if (type === 'visit_note') {
        req = new ApiCall('GET_CLIENT_VISIT_NOTE');
        if (specific)
            req.putParam('ids', NKTypeConvertor().toKotlinList([Number(specific)]));
        if (cids)
            req.putParam('ent_ids', NKTypeConvertor().toKotlinList(cids));
    }
    if (api)
        req.putParam('api', true);
    if (branched)
        req.putParam('branched', true);
    if (cid)
        req.putParam('client_id', Number(cid));
    if (cids)
        req.putParam('client_ids', NKTypeConvertor().toKotlinList(cids));
    if (fromDate)
        req.putParam('fromDate', dateObjectToString(fromDate));
    if (toDate)
        req.putParam('toDate', dateObjectToString(toDate));
    if (full)
        req.putParam('full', true);
    if (open !== undefined)
        req.putParam('open', open);
    console.log(req);
    const result = await req.execute();
    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });

    return docResultsDeserialize(result.first);

};
export const getAllSupplierDocuments: (fromDate: Date, toDate: Date, full?: boolean, cid?: string,tax_only?:boolean) => Promise<ExtendedDocument[]> = async (fromDate, toDate, full = undefined, cid = undefined,  tax_only = undefined) => {

    const req = new ApiCall('GET_ALL_SPECIFIC_SUPPLIER_DOCUMENTS');
    if (cid)
        req.putParam('sup_id', Number(cid));
    req.putParam('fromDate', dateObjectToString(fromDate));
    req.putParam('toDate', dateObjectToString(toDate));
    if (tax_only)
        req.putParam('tax_only', true);
    if (full)
        req.putParam('full', true);
    req.putParam("api",false)
    const result = await req.execute();
    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    return docResultsDeserialize(result.first);

};

export const buildClientCartesetDebt: (toDate: Date, cids?: number[]) => Promise<boolean> = async (toDate, cids) => {

    const req = new ApiCall('OPTIMIZED_CARTESET_VALUE');
    if (cids)
        req.putParam('ids', NKTypeConvertor().toKotlinList(cids));
    req.putParam('toDate', dateObjectToString(toDate));

    const result = await req.execute();
    if (result == null || result.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    return true

};

export const getAllCustomerDocuments: (fromDate: Date, toDate: Date, full?: boolean, cid?: string, apis?: boolean, tax_only?: boolean, onlyProducts?: boolean, driver?: number, visit?: boolean, client_ids?: number[], cost?: boolean, documents?: string[]) => Promise<ExtendedDocument[]> = async (fromDate, toDate, full = undefined, cid = undefined, apis = undefined, tax_only = undefined, onlyProducts = undefined, driver = undefined, visit, client_ids, cost, documents) => {

    const req = new ApiCall('GET_ALL_SPECIFIC_CLIENT_DOCUMENTS');
    if (cid)
        req.putParam('client_id', Number(cid));
    req.putParam('fromDate', dateObjectToString(fromDate));
    req.putParam('toDate', dateObjectToString(toDate));
    if (full)
        req.putParam('full', true);
    if (tax_only)
        req.putParam('tax_only', true);
    if (apis !== undefined)
        req.putParam('api', apis);
    if (cost)
        req.putParam('cost', true);
    if (onlyProducts)
        req.putParam('onlyProducts', true);
    if (driver != undefined)
        req.putParam('driver_id', driver);
    if (visit != undefined)
        req.putParam('visit', visit);
    if (client_ids != undefined)
        req.putParam('client_ids', NKTypeConvertor().toKotlinList(client_ids));
    if (documents) {
        req.putParam('force_documents', NKTypeConvertor().toKotlinList(docTypeToDocType(documents)));
    }

    const result = await req.execute();
    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    return docResultsDeserialize(result.first);

};

export function collect(items: IItem[], id: number, client: boolean = true, usep: boolean): any[] {
    const pds: any[] = [];
    let entity: any;
    if (client)
        entity = JSDB().getClient(id).first;
    else
        entity = JSDB().getSupplier(id).first;
    items.forEach((row, index) => {
        const x: any = JSDB().createProductDelivery(row.id);
        x.isClient = client;
        x.value = row.quantity;
        x.returns = row.quantityReturns;
        if (row.quantitySecond)
            x.wrapped_amount = row.quantitySecond
        if (row.conversion_ratio)
            x.conversion_ratio = row.conversion_ratio

        if (row.notes && row.notes != '') {
            x.notes = row.notes;
        }
        if (row.costPrice) {
            x.cost = row.costPrice;
        }
        if (row.notePlacement != undefined && row.notePlacement != null) {
            x.tempPosition = row.notePlacement;
        } else {
            x.tempPosition = index;
        }
        if (x.value !== 0 || x.returns !== 0 || x.wrapped_amount != 0) {
            x.price = row.totalPrice;
            x.discount = row.discount;
            if (usep) {
                if (x.getProduct().getNoTaxProduct() === 1 || entity.no_tax_client == 1) {
                    x.use_price = 1;
                } else if (entity.getIncludeTax() === 0) {
                    x.use_price = 2;
                } else if (entity.getIncludeTax() === 1) {
                    x.use_price = 3;
                }
            }
            pds.push(x);
        }
    });
    return pds;
}

export const newClientDebt: (cid: number, date: Date, positive: number, negative: number, notes: string,sup?:boolean) => Promise<ExtendedDocument> = async (cid, date, positive, negative, notes,sup) => {
    let req: ApiCall;
    req = new ApiCall('NEW_CLIENT_CARTESET');
    req.putParam('date', dateObjectToString(date));
    req.putParam('positive', positive);
    req.putParam('negative', negative);
    req.putParam('id', Number(cid));
    req.putParam('notes', notes);
    if(sup)
        req.putParam('sup',true);
    const result = await req.execute();

    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    return deserializeDocument(result.first);
    ;

};
export const getClientDebt: (cid: number, date: Date,sup?:boolean) => Promise<ExtendedDocument[]> = async (cid, date,sup) => {
    let req: ApiCall;
    req = new ApiCall('GET_CLIENT_CARTESET');
    req.putParam('fromDate', dateObjectToString(date));
    req.putParam('toDate', dateObjectToString(date));
    req.putParam('state', 0);
    req.putParam('id', Number(cid));
    if(sup)
        req.putParam('sup',true);
    const result = await req.execute();

    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    return (result.first);
    ;

};


export const createDVFromPds: (cid: number, d: any[], date: Date, comment?: string) => Promise<ExtendedDocument> = async (cid, d, date, comment) => {
    let req: ApiCall;
    req = new ApiCall('NEW_CLIENT_NOTE');
    req.putParam('date', dateObjectToString(date));
    req.putParam('delivery_value', NKTypeConvertor().toKotlinList(d));
    req.putParam('agent', JSDB().currentAgent);
    req.putParam('ent_id', Number(cid));
    if (comment)
        req.putParam('notes', comment);
    req.putParam('date_issued', JSDB().getDatesManipulator().dateNow());


    const result = await req.execute();

    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    return docResultsDeserialize(result.first);
    ;

};

export const createSupplierNote: (cid: string, d: ExtendedDocument) => Promise<ExtendedDocument> = async (cid, d) => {
    let req: ApiCall;
    req = new ApiCall('NEW_SUPPLIER_NOTE');
    const savePriceInNote = JSDB().getUser().first.savePriceInNote();
    const usePriceInNote = JSDB().getUser().first.needPriceInNoteShow();
    req.putParam('date', dateObjectToString(d.date));

    const products = d.products.sort((a, b) => (
        (a?.notePlacement != undefined || b?.notePlacement != undefined) ? ((a?.notePlacement ?? 0) - (b?.notePlacement ?? 0)) :
            (1))
    );
    req.putParam('delivery_value', NKTypeConvertor().toKotlinList(collect(products, Number(cid), false, savePriceInNote)));
    req.putParam('agent', JSDB().currentAgent);
    req.putParam('ent_id', Number(cid));
    req.putParam('with_price', usePriceInNote);
    if ((d as ProductNote).bul)
        req.putParam('connected_id', NKTypeConvertor().toKotlinLong((d as ProductNote).bul));

    req.putParam('notes', (d as ProductNote).comment);
    req.putParam('date_issued', JSDB().getDatesManipulator().dateNow());
    if (Number(d.id)) {
        req.putParam('delivery_id', Number(d.id));
    }
    const result = await req.execute();

    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });

    return docResultsDeserialize(result.first);

};


export const createDvsTaxNoteSplitted: (dvs: number[], date: Date, splitBranch?: boolean, external_details?: string, doRound?: boolean, discount_percent?: number) => Promise<string[]> = async (dvs, date, splitBranch, external_details, doRound, discount_percent) => {
    let req: ApiCall;

    req = new ApiCall('NEW_CLIENT_TAX_NOTE_BRANCH_SPLITTED');
    req.putParam('document_date', dateObjectToString(date));
    req.putParam('delivery_notes', NKTypeConvertor().toKotlinList(dvs));
    if (discount_percent != undefined)
        req.putParam('discount_percent', discount_percent);
    if (splitBranch)
        req.putParam('splitBranch', splitBranch);
    req.putParam('agent', JSDB().currentAgent);
    if (external_details)
        req.putParam('external_details', external_details);
    if (doRound)
        req.putParam('doRound', doRound);

    const result = await req.execute();

    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    if (result.first.size == 0)
        return []
    return result.first.toArray()

}

export const updateAmountsDocuments: (data: any[], savePriceInClient?: boolean) => Promise<ExtendedDocument[]> = async (data, savePriceInClient) => {
    let req: ApiCall;

    req = new ApiCall('UPDATE_MASS_DOCUMENT');
    req.putParam('data', NKTypeConvertor().toKotlinList(data.map((d) => {
        const rate = (d.quantity > 0 && d.quantitySecond > 0) ? 0 : d.conversion_ratio
        return JSDB().createChangeNoteHelper(d.doc_id, d.note.nkObject.getConnectedDocType(), d.p_id, d.quantity, d.quantitySecond, rate, d.quantityReturns, d.totalPrice, d.discount, d.driver)
    })));
    if (savePriceInClient)
        req.putParam('savePriceInClient', savePriceInClient);


    const result = await req.execute();

    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    if (result.first.size == 0)
        return []

    return result.first.toArray().map(d => (deserializeDocument(d)))

}
export const createRealCustomerDocuments: (cid: string, d: ExtendedDocument) => Promise<ExtendedDocument> = async (cid, d) => {
    let req: ApiCall;
    const savePriceInNote = JSDB().getUser().first.savePriceInNote();
    const usePriceInNote = JSDB().getUser().first.needPriceInNoteShow();

    if (d.type === 'delivery_certificate') {
        req = new ApiCall('NEW_CLIENT_NOTE');
        req.putParam('date', dateObjectToString(d.date));
        req.putParam('delivery_value', NKTypeConvertor().toKotlinList(collect((d as ProductNote).products, Number(cid), true, savePriceInNote)));
        req.putParam('agent', JSDB().currentAgent);
        req.putParam('ent_id', Number(cid));
        req.putParam('with_price', usePriceInNote);
        req.putParam('connected_id', NKTypeConvertor().toKotlinLong((d as ProductNote).bul));
        req.putParam('notes', (d as ProductNote).comment);
        req.putParam('date_issued', JSDB().getDatesManipulator().dateNow());
        if (d.fromOrder)
            req.putParam('fromOrder', d.fromOrder);
        if (Number(d.id)) {
            req.putParam('delivery_id', Number(d.id));
        }

        if (d.refId)
            req.putParam('order_id', Number(d.refId));


    } else if (d.type === 'visit_note') {
        req = new ApiCall('NEW_CLIENT_VISIT_NOTE');
        req.putParam('document_date', dateObjectToString(d.date));
        // if (d.product)
        //     req.putParam('pd', d.product);
        if (d.payment)
            req.putParam('payment', d.payment);


        if (d?.product && d?.product?.amount) {
            const x: any = JSDB().createProductDelivery(d.product.id);
            x.isClient = true;
            x.value = d.product.amount;
            x.returns = 0;
            x.price = d.product.price

            req.putParam('pd', x);
        }

        req.putParam('agent', JSDB().currentAgent);
        req.putParam('client_id', Number(cid));
        req.putParam('lon_lat', "");
        req.putParam('comment', d.comment);
        req.putParam('date_issued', JSDB().getDatesManipulator().dateNow());
        req.putParam('states', NKTypeConvertor().toKotlinList(d?.states ?? []));

    } else if (d.type === 'order') {
        req = new ApiCall('NEW_CLIENT_ORDER');
        req.putParam('date', dateObjectToString(d.date));
        req.putParam('delivery_value', NKTypeConvertor().toKotlinList(collect((d as ProductNote).products, Number(cid), true, savePriceInNote)));
        req.putParam('sup_delivery_value', NKTypeConvertor().toKotlinList([]));
        req.putParam('agent', JSDB().currentAgent);
        req.putParam('ent_id', Number(cid));
        req.putParam('with_price', usePriceInNote);
        req.putParam('connected_id', (d as ProductNote).bul);
        req.putParam('notes', (d as ProductNote).comment);
        req.putParam('date_issued', JSDB().getDatesManipulator().dateNow());

    } else if (d.type === 'invoice' || d.type === 'tax_invoice' || d.type === 'refund') {
        req = new ApiCall('NEW_CLIENT_TAX_NOTE');
        const dateIssued = (JSDB().getDatesManipulator().dateNow());
        const details = deserializeDetails((d as TaxNote).details!);
        const ct: any[] = [];
        let dv: any = null;
        if ((d as TaxNote).hand > 0) {

            ct.push(JSDB().createTaxNoteDataFromFreeValue((d as TaxNote).hand_text ?? 'סכום ידני', (d as TaxNote).hand, (d as TaxNote).hand_tax === 0, dateObjectToString(d.date)));
        }

        if ((d as TaxNote).handArray && (d as TaxNote).handArray!.length > 0) {
            (d as TaxNote).handArray!.forEach((k) => {
                if (roundDecimals(k.value * k.amount) != 0) {
                    const include = JSDB().getClient(Number(cid)).first.getIncludeTax() == 1
                    const tax = k.tax * k.amount
                    const value = k.value * k.amount + (include ? 0 : tax)
                    ct.push(JSDB().createTaxNoteDataFromFreeValue(k.description, roundDecimals(value), roundDecimals(k.tax) == 0, dateObjectToString(d.date), k.amount));
                }
            })

        }

        if ((d as TaxNote).products.length > 0) {
            const pds = NKTypeConvertor().toKotlinList(collect((d as TaxNote).products, Number(cid), true, true));
            const result = JSDB().productDeliveryToAmount(pds, Number(d.entId), dateObjectToString(d.date));

            const productsTax = roundDecimals(result.second, 4);
            const productsValue = roundDecimals(result.third, 4);
            const productS = JSDB().createTaxNoteDataFromProducts(productsValue, (pds));
            productS.taxPaid = Number(productsTax);
            ct.push(productS);
        }

        if ((d as TaxNote).deliveryCertificatesIds.length > 0) {
            const fromNotes = JSDB().createTaxNoteDataFromNotesNew(Number(cid), NKTypeConvertor().toKotlinList((d as TaxNote).deliveryCertificatesIds));
            ct.push(fromNotes.first);
            dv = fromNotes.second;

        }
        let payments: any[] = []
        if ((d as TaxNote).payments.length > 0) {
            payments = (d as PayNote).payments.map((p) => deserializePayment(p));

        }
        if (Math.abs((d as TaxNote).round) !== 0) {
            ct.push(JSDB().createTaxNoteDataFromRound(Number((d as TaxNote).round), Number((d as TaxNote).round_tax)));
        }
        let t = 0;
        if (d.type == 'invoice') {
            t = 0;
        } else if (d.type == 'tax_invoice') {
            t = 1;
        } else {
            t = 2;
        }

        req
            .putParam('client_id', Number(cid))
            .putParam('date', dateIssued)
            .putParam('document_date', dateObjectToString(d.date))
            .putParam('value', roundDecimals(d.preValue!, 4))
            .putParam('total_value', roundDecimals(d.mainValue, 4))
            .putParam('details', details)
            .putParam('type', t)
            .putParam('cover_dates', '')
            .putParam('paymentsData', NKTypeConvertor().toKotlinList(payments))
            .putParam('dataValues', NKTypeConvertor().toKotlinList(ct))
            .putParam('discount_percent', NKTypeConvertor().toKotlinFloat((d as TaxNote).discount))
            .putParam('with_price', usePriceInNote)
            .putParam('delivery_notes', dv)
            .putParam('external_details', (d as TaxNote).comment)
            .putParam('agent', d.agent);
        if (d.refId)
            req.putParam('order_id', Number(d.refId));
        if (Number(d.id)) {
            req.putParam('tax_note_id', Number(d.id));
        }
    } else if (d.type === 'sup_tax_invoice' || d.type === 'sup_invoice' || d.type === 'sup_refund') {
        req = new ApiCall('NEW_CLIENT_TAX_NOTE');
        const dateIssued = (JSDB().getDatesManipulator().dateNow());
        const details = deserializeDetails((d as TaxNote).details!);
        const ct: any[] = [];
        let dv: any = null;
        if ((d as TaxNote).hand > 0) {

            ct.push(JSDB().createTaxNoteDataFromFreeValue((d as TaxNote).hand_text ?? 'סכום ידני', (d as TaxNote).hand, (d as TaxNote).hand_tax === 0, dateObjectToString(d.date)));
        }

        if ((d as TaxNote).handArray && (d as TaxNote).handArray!.length > 0) {
            (d as TaxNote).handArray!.forEach((k) => {
                if (roundDecimals(k.value * k.amount) != 0) {
                    const include = JSDB().getSupplier(Number(cid)).first.getIncludeTax() == 1
                    const tax = k.tax * k.amount
                    const value = k.value * k.amount + (include ? 0 : tax)
                    ct.push(JSDB().createTaxNoteDataFromFreeValue(k.description, roundDecimals(value), roundDecimals(k.tax) == 0, dateObjectToString(d.date), k.amount));
                }
            })

        }

        if ((d as TaxNote).products.length > 0) {
            const pds = NKTypeConvertor().toKotlinList(collect((d as TaxNote).products, Number(cid), false, true));
            const result = JSDB().productDeliveryToAmount(pds, Number(d.entId), dateObjectToString(d.date),false);

            const productsTax = roundDecimals(result.second, 4);
            const productsValue = roundDecimals(result.third, 4);
            const productS = JSDB().createTaxNoteDataFromProducts(productsValue, (pds));
            productS.taxPaid = Number(productsTax);
            ct.push(productS);
        }

        if ((d as TaxNote).deliveryCertificatesIds.length > 0) {
            const fromNotes = JSDB().createTaxNoteDataFromNotesNew(Number(cid), NKTypeConvertor().toKotlinList((d as TaxNote).deliveryCertificatesIds),true);
            ct.push(fromNotes.first);
            dv = fromNotes.second;

        }
        let payments: any[] = []
        if ((d as TaxNote).payments.length > 0) {
            payments = (d as PayNote).payments.map((p) => deserializePayment(p));

        }
        if (Math.abs((d as TaxNote).round) !== 0) {
            ct.push(JSDB().createTaxNoteDataFromRound(Number((d as TaxNote).round), Number((d as TaxNote).round_tax)));
        }
        let t = 0;
        if (d.type == 'sup_invoice') {
            t = 0;
        } else if (d.type == 'sup_tax_invoice') {
            t = 1;
        } else {
            t = 2;
        }

        req
            .putParam('client_id', Number(cid))
            .putParam('date', dateIssued)
            .putParam('document_date', dateObjectToString(d.date))
            .putParam('value', roundDecimals(d.preValue!, 4))
            .putParam('total_value', roundDecimals(d.mainValue, 4))
            .putParam('details', details)
            .putParam('type', t)
            .putParam('cover_dates', '')
            .putParam('paymentsData', NKTypeConvertor().toKotlinList(payments))
            .putParam('dataValues', NKTypeConvertor().toKotlinList(ct))
            .putParam('discount_percent', NKTypeConvertor().toKotlinFloat((d as TaxNote).discount))
            .putParam('with_price', usePriceInNote)
            .putParam('delivery_notes', dv)
            .putParam('external_details', (d as TaxNote).comment)
            .putParam('agent', d.agent)
        .putParam('sup', true);

        if (d.refId)
            req.putParam('order_id', Number(d.refId));
        if (Number(d.id)) {
            req.putParam('tax_note_id', Number(d.id));
        }

    }  else if (d.type === 'receipt' || d.type === 'sup_receipt') {
        req = new ApiCall('NEW_CLIENT_PAY');
        const dateIssued = (JSDB().getDatesManipulator().dateNow());
        const details = deserializeDetails((d as TaxNote).details!);
        const payments: any[] = (d as PayNote).payments.map((p) => deserializePayment(p));
        const tax_ids: string = (d as PayNote).receiptsIds.sort((s) => -s).join(',');


        const refundRest = (d as PayNote).refundRest;

        req
            .putParam('client_id', Number(cid))
            .putParam('date', dateIssued)

            .putParam('document_date', dateObjectToString(d.date))
            .putParam('value', roundDecimals(d.mainValue!, 4))
            .putParam('details', details)
            .putParam('paymentsData', NKTypeConvertor().toKotlinList(payments))
            .putParam('tax_note_id', (tax_ids))
            .putParam('external_details', (d as TaxNote).comment)
            .putParam('agent', JSDB().currentAgent);
        if (refundRest)
            req.putParam('refundRest', refundRest);
        if(d.type === 'sup_receipt')
            req.putParam('sup', true);

    } else {
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    }
    if (d.notes2) {
        req.putParam('notes2', d.notes2);
    }
    if (d.notes3) {
        req.putParam('notes3', d.notes3);
    }
    if (d.notes4) {
        req.putParam('notes4', d.notes4);
    }
    if (d.notes5) {
        req.putParam('notes5', d.notes5);
    }

    const result = await req.execute();

    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });

    if (d.type === 'delivery_certificate' || d.type === 'tax_invoice' || d.type === 'invoice' || d.type === 'refund' || d.type === 'sup_invoice' || d.type === 'sup_tax_invoice'|| d.type === 'sup_refund' ) {
        return docResultsDeserialize(result.first); //for njow
    } else {
        return deserializeDocument(result.first)!;
    }


};

export const updateRealCustomerDocument: (d: ExtendedDocument) => Promise<ExtendedDocument> = async (d) => {
    let req: ApiCall;

    const savePriceInNote = d?.savePriceInSpecificNote ?? JSDB().getUser().first.savePriceInNote()
    const savePriceGeneral = d?.savePriceInGeneral ?? JSDB().getUser().first.needPriceInNoteShow()
    if (d.type === 'delivery_certificate') {
        req = new ApiCall('UPDATE_CLIENT_NOTE');
        // if ((d as ProductNote).bul)
        //     req.putParam('connected_id', (d as ProductNote).bul);
        req.putParam('notes', (d as ProductNote).comment);
        req.putParam('notes2', (d as ProductNote).notes2);
        req.putParam('notes3', (d as ProductNote).notes3);
        req.putParam('notes4', (d as ProductNote).notes4);
        req.putParam('notes5', (d as ProductNote).notes5);
        req.putParam('id', Number(d.id));

        if (!isNaN(Number((d as ProductNote).bul))) {
            req.putParam('connected_id', NKTypeConvertor().toKotlinLong(Number((d as ProductNote).bul)));
        }
        req.putParam('delivery_value', NKTypeConvertor().toKotlinList(collect((d as ProductNote).products, Number(d.entId), true, savePriceInNote || JSDB().getUser().first.note_price == 1)));
        if (JSDB().getCompany() == "carmelberry") // remove
            req.putParam('updatePrice', false);
        else
            req.putParam('updatePrice', savePriceGeneral);
        if (JSDB().getCompany() == "galrazit") // remove
            req.putParam("agentEdit", JSDB().currentAgent)

    } else if (d.type === 'order') {
        req = new ApiCall('UPDATE_CLIENT_ORDER');
        // req.putParam('date', dateObjectToString(d.date));
        req.putParam('order_id', Number(d.id));
        // if((d as ProductNote).bul)
        // req.putParam('connected_id', (d as ProductNote).bul);
        req.putParam('notes', (d as ProductNote).comment);
        req.putParam('notes2', (d as ProductNote).notes2);
        req.putParam('notes3', (d as ProductNote).notes3);
        req.putParam('notes4', (d as ProductNote).notes4);
        req.putParam('notes5', (d as ProductNote).notes5);
        req.putParam('delivery_value', NKTypeConvertor().toKotlinList(collect((d as ProductNote).products, Number(d.entId), true, savePriceInNote || JSDB().getUser().first.note_price == 1)));

        req.putParam('updatePrice', savePriceGeneral);

    } else if (d.type === 'visit_note') {
        req = new ApiCall('UPDATE_CLIENT_VISIT_NOTE');
        // req.putParam('date', dateObjectToString(d.date));
        req.putParam('id', Number(d.id));
        // if((d as ProductNote).bul)
        // req.putParam('connected_id', (d as ProductNote).bul);
        req.putParam('comment', (d).comment);
        req.putParam('date', dateObjectToString((d).date));

        if (d.payment != undefined)
            req.putParam('payment', d.payment);
        req.putParam('states', NKTypeConvertor().toKotlinList(d.states.map((s) => visitStatesMap[s] ?? s)));
        if (d?.product?.amount != undefined) {
            const x: any = JSDB().createProductDelivery(d.product.id);
            x.isClient = true;
            x.value = d.product.amount;
            x.returns = 0;
            x.price = d.product.price

            req.putParam('pd', x);
            if (d.nkObject.order_id && d.nkObject.order_id != -1)
                req.putParam('order_id', d.nkObject.order_id)
        }


    } else if (d.type == 'supplier_note') {
        req = new ApiCall('UPDATE_SUPPLIER_NOTE');
        req.putParam('notes', (d as ProductNote).comment);
        req.putParam('notes2', (d as ProductNote).notes2);
        req.putParam('notes3', (d as ProductNote).notes3);
        req.putParam('notes4', (d as ProductNote).notes4);
        if (!isNaN(Number((d as ProductNote).bul))) {
            req.putParam('connected_id', NKTypeConvertor().toKotlinLong(Number((d as ProductNote).bul)));
        }
        req.putParam('notes5', (d as ProductNote).notes5);
        req.putParam('id', Number(d.id));
        req.putParam('delivery_value', NKTypeConvertor().toKotlinList(collect((d as ProductNote).products, Number(d.entId), false, JSDB().getUser().first.note_price == 1)));


    } else if (d.type === 'invoice' || d.type === 'tax_invoice' || d.type === 'refund') {
        req = new ApiCall('UPDATE_ALL_CLIENT_TAX_NOTE');

        let t = 0;
        if (d.type == 'invoice') {
            t = 0;
        } else if (d.type == 'tax_invoice') {
            t = 1;
        } else {
            t = 2;
        }
        req
            .putParam('types', NKTypeConvertor().toKotlinList([t]))
            .putParam('ids', NKTypeConvertor().toKotlinList([Number(d.id)]))

            .putParam('external_details', NKTypeConvertor().toKotlinList([(d as TaxNote).comment]))
        if (d.type == 'tax_invoice' && (d as PayNote).payments) {
            const payments = [NKTypeConvertor().toKotlinList((d as PayNote).payments.map((p) => deserializePayment(p)))]
            req.putParam('paymentsData', NKTypeConvertor().toKotlinList(payments))
        }

        if ((d as ProductNote).notes2)
            req.putParam('notes2', NKTypeConvertor().toKotlinList([(d as ProductNote).notes2]))
        if ((d as ProductNote).notes3)
            req.putParam('notes3', NKTypeConvertor().toKotlinList([(d as ProductNote).notes3]))

        if ((d as ProductNote).notes4)
            req.putParam('notes4', NKTypeConvertor().toKotlinList([(d as ProductNote).notes4]))

        if ((d as ProductNote).notes5)
            req.putParam('notes5', NKTypeConvertor().toKotlinList([(d as ProductNote).notes5]))
        if (d.taxConfirmation != undefined)
            req.putParam('taxConfirmation', NKTypeConvertor().toKotlinList([d.taxConfirmation.toString()]))
    } else if (d.type === 'receipt') {
        req = new ApiCall('UPDATE_CLIENT_PAY')
            .putParam('id', Number(d.id))
            .putParam('external_details', (d as TaxNote).comment)

    } else {
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    }
    const result = await req.execute();

    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    const requestedDoc = d
    if (d.type === 'tax_invoice' || d.type === 'invoice' || d.type === 'refund') {

        const docs = docResultsDeserialize(result.first)
        const d = docs.find((dd) => dd.id == Number(requestedDoc.id) && requestedDoc.type == dd.type) ?? docs[0]
        return d; //for njow
    } else {
        return deserializeDocument(result.first)!;
    }


};

export const getDocumentPdf: (d: ExtendedDocument, origin: boolean) => Promise<any> = async (d, origin) => {
    const req: ApiCall = new ApiCall('GET_DOCUMENT_PDF');
    req.putParam('doc', d.nkObject);
    req.putParam('origin', origin);
    if (JSDB().getCurrentAgent()?.print == 3)
        req.putParam('force_price', false);

    const result = await req.execute();

    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    // bytearray
    return result.first;

};

export const getDocumentSign: (d: ExtendedDocument) => Promise<any> = async (d) => {
    const req: ApiCall = new ApiCall('GET_SIGN_IMG');
    req.putParam('doc_type', documentTypes[d.type].nkType);
    req.putParam('note_id', d.id);
    const result = await req.execute();
    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    // bytearray
    return result.first.first;

};
export const getDocumentPhoto: (d: ExtendedDocument) => Promise<any> = async (d) => {
    const req: ApiCall = new ApiCall('GET_SIGN_PHOTO');
    req.putParam('note_id', d.id);
    req.putParam('doc_type', documentTypes[d.type].nkType);

    const result = await req.execute();

    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    // bytearray
    return result.first.first;

};
export const uploadDocumentPhoto: (d: ExtendedDocument, byteArr) => Promise<any> = async (d, byteArr) => {
    const req: ApiCall = new ApiCall('UPLOAD_SIGN_PHOTO');
    req.putParam('note_id', d.id);
    req.putParam('doc_type', documentTypes[d.type].nkType);
    req.putParam('image', byteArr);
    req.putParam('date', dateTimeObjectToString(new Date()));
    const result = await req.execute();

    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    // bytearray
    return result.first;

};
export const getCartesetPDF: (fromDate: Date, toDate: Date, cid?: number) => Promise<any> = async (fromDate, toDate, cid) => {
    const req: ApiCall = new ApiCall('GET_CLIENT_CARTESET_PDF');
    if (cid)
        req.putParam('id', Number(cid));
    req.putParam('dates', `${dateObjectToString(fromDate)}..${dateObjectToString(toDate)}`.toString());
    const result = await req.execute();

    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    // bytearray
    return result.first;

};
export const getOrderDocumentPdf: (d?: ExtendedDocument[], dint?: number[]) => Promise<any> = async (d, dint) => {
    const req: ApiCall = new ApiCall('CALCULATE_CLIENT_ORDER_PDF');
    if (d)
        req.putParam('ids', NKTypeConvertor().toKotlinList(d.map((p) => p.id)));
    if (dint)
        req.putParam('ids', NKTypeConvertor().toKotlinList(dint));
    const result = await req.execute();

    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    // bytearray
    return result.first;

};
export const getCofcLink: (id: number, date: Date) => Promise<any> = async (id, date) => {
    const req: ApiCall = new ApiCall('REGISTER_COFC');
    req.putParam('id', id);
    req.putParam('date', '');
    req.putParam('open_date', dateObjectToString(date));
    req.putParam('short', true);

    const result = await req.execute();

    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    // string
    return result.first;

};

export const updateGeo: (ids: number[], force?: boolean) => Promise<number[]> = async (ids, force) => {
    let req: ApiCall = new ApiCall('UPDATE_GEO_NAME');
    req.putParam('ids', NKTypeConvertor().toKotlinList(ids));
    if (force)
        req.putParam('force', force);


    const result = await req.execute();

    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    // string

    const res: number[] = []
    if (result.first.size > 0)
        result.first.toArray().forEach((c) => {
            res.push(ids[c]);

        })
    return res

};
export const optimizeRouteClients: (ids: number[], date?: Date, geoTransfrom?: boolean, initLoc?: string, endLoc?: string) => Promise<number[]> = async (ids, date, geoTransfrom, initLoc, endLoc) => {
    let req: ApiCall = new ApiCall('OPTIMIZE_ROUTE');
    if (date) {
        req = new ApiCall('OPTIMIZE_ROUTE_DRIVERS');
        req.putParam('date', dateObjectToString(date));
        req.putParam('save', true);
    }
    req.putParam('ids', NKTypeConvertor().toKotlinList(ids));
    if (geoTransfrom)
        req.putParam('geoTransfrom', geoTransfrom);
    if (initLoc)
        req.putParam('start_point', initLoc);
    if (endLoc)
        req.putParam('end_point', endLoc);

    const result = await req.execute();

    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    // string

    const res: number[] = []
    result.first.toArray().forEach((c) => {
        res.push(ids[c]);

    })
    return res

};
export const optimizeDriversToCollectors: (ids: number[], drivers: number[], collectors: number[], date: Date, maxBoxSize: number, inverseToCollectors: boolean) => Promise<number[]> = async (ids, drivers, collectors, date, maxBoxSize, inverseToCollectors) => {
    let req: ApiCall = new ApiCall('OPTIMIZE_COLLECTORS_SPLIT');
    req.putParam('date', dateObjectToString(date));
    req.putParam('save', true);
    req.putParam('ids', NKTypeConvertor().toKotlinList(ids));
    req.putParam('driver_id', NKTypeConvertor().toKotlinList(drivers));
    req.putParam('collector_id', NKTypeConvertor().toKotlinList(collectors));
    req.putParam('maxBoxSize', maxBoxSize);
    req.putParam('inverseToCollectors', inverseToCollectors);


    const result = await req.execute();

    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    // string

    const res: number[] = []
    result.first.toArray().forEach((c) => {
        res.push(ids[c]);

    })
    return res

};
export const getNameOfDoc: (d?: ExtendedDocument[]) => string = (d) => {
    if (!d || d.length == 0) {
        return "מסמכים"
    } else {
        const name = new Set(d.map((i) => i.entName))
        const t = new Set(d.map((i) => i.type))
        let nameDoc = ""
        let tDoc = ""
        if (t.size == 1) {
            const k = t.keys().next().value
            tDoc = documentTypes[k].name + "_" + (d.length == 1 ? String(d[0].id) : "לקוחות")
        } else {
            tDoc = "מסמכים"
        }
        if (name.size == 1) {
            nameDoc = name.keys().next().value
        }
        return nameDoc + "_" + tDoc


    }
}
export const getMassDocumentPdf: (d?: ExtendedDocument[], dint?: number[], types?: number[], origin?: boolean, originForce?: number, dates?: string) => Promise<any> = async (d, dint, types, origin, originForce, dates) => {
    const req: ApiCall = new ApiCall('CALCULATE_CLIENT_MASS_DOC_PDF');
    if (d) {
        req.putParam('ids', NKTypeConvertor().toKotlinList(d.map((p) => p.id)));
        req.putParam('docs', NKTypeConvertor().toKotlinList(d.map((p) => p.nkObject.getConnectedDocType())));
    }

    if (dint)
        req.putParam('ids', NKTypeConvertor().toKotlinList(dint));
    if (types)
        req.putParam('docs', NKTypeConvertor().toKotlinList(types));
    if (origin)
        req.putParam('origin', true);
    if (originForce)
        req.putParam('originForce', originForce);
    if (dates)
        req.putParam('dates', dates);

    const result = await req.execute();

    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });

    // bytearray
    return result.first;

};

export const sendPdfEmail: (byteArr: any, email: string, title: string, doc_id?: number, doc_type?: number) => Promise<boolean> = async (byteArr, email, title, doc_id, doc_type) => {
    const req: ApiCall = new ApiCall('SEND_EMAIL');

    req.putParam('f', byteArr);
    req.putParam('title', title);
    req.putParam('email', email);

    if (doc_id)
        req.putParam('doc_id', doc_id);
    if (doc_type)
        req.putParam('doc_type', doc_type);
    const result = await req.execute();

    if (result == null || result.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });

    // bytearray
    return true;

};

export const sendReqForApi: (doc_id: number, doc_type: number, api_method?: number) => Promise<boolean> = async (doc_id, doc_type, api_method) => {
    const req: ApiCall = new ApiCall('SEND_API_REQ');
    req.putParam('doc_id', doc_id);
    req.putParam('doc_type', doc_type);
    if (api_method)
        req.putParam('api_method', api_method);
    const result = await req.execute();
    if (result == null || result.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });

    // bytearray
    return true;

};
export const sendApiAll: (doc_id: number[], doc_type: number[]) => Promise<number[]> = async (doc_id, doc_type) => {
    const req: ApiCall = new ApiCall('SEND_API_REQ');
    req.putParam('doc_id', NKTypeConvertor().toKotlinList(doc_id));
    req.putParam('doc_type', NKTypeConvertor().toKotlinList(doc_type));

    const result = await req.execute();
    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });

    // bytearray
    if (result.first.size > 0)
        return result.first.toArray()

    return [];

};
export const buildNotesSum: (d?: ExtendedDocument[], dint?: number[], tax_note_id?: number[], order?: boolean) => Promise<any> = async (d, dint, tax_note_id, order) => {
    const req: ApiCall = new ApiCall('CALCULATE_client_delivery_easy');
    if (d) {
        req.putParam('ids', NKTypeConvertor().toKotlinList(d.map((p) => p.id)));
    }
    if (dint)
        req.putParam('ids', NKTypeConvertor().toKotlinList(dint));
    if (tax_note_id) {
        req.putParam('tax_id_merge', NKTypeConvertor().toKotlinList(tax_note_id));
    }
    if (order) {
        req.putParam('order', true);
    }
    const result = await req.execute();

    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    // bytearray
    return result.first;

};

export const buildNotesSumByDates: (id, fromDate: Date, toDate: Date, sup?: boolean,ids?:any) => Promise<any> = async (id, fromDate, toDate, sup,ids) => {
    let req: ApiCall = new ApiCall('CALCULATE_client_delivery');
    if (sup) {
        req = new ApiCall('CALCULATE_supplier_delivery');
    }
    if(ids){
        req.putParam('client_id', id);
        req.putParam('client_ids', NKTypeConvertor().toKotlinList(ids));
    } else {
        req.putParam('client_id', id);
    }

    req.putParam('date_range', `${dateObjectToString(fromDate)}..${dateObjectToString(toDate)}`);


    const result = await req.execute();

    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    // bytearray
    return result.first;

};

export const buildSupNotesSum: (d?: ExtendedDocument[], dint?: number[], ent_id?: number, dates?: string) => Promise<any> = async (d, dint, ent_id, dates) => {
    const req: ApiCall = new ApiCall('CALCULATE_supplier_delivery');
    if (d) {
        req.putParam('ids', NKTypeConvertor().toKotlinList(d.map((p) => p.id)));
    }
    if (dint)
        req.putParam('ids', NKTypeConvertor().toKotlinList(dint));
    if (ent_id && dates) {
        req.putParam('client_id', ent_id);
        req.putParam('date_range', dates);
    }

    const result = await req.execute();

    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    // bytearray

    return result.first;

};

export const buildQuerySum: (ids: number[], from: Date, to: Date, title, withNote?: boolean, withOrders?: boolean, specific_ids?: number[], seperate?: number, byIds?: number) => Promise<any> = async (ids, from, to, title, withNote, withOrders, specific_ids, seperate, byIds) => {
    const req: ApiCall = new ApiCall('QUERY_SUM_PDF');

    req.putParam('ids', NKTypeConvertor().toKotlinList(ids));
    req.putParam('fromDate', dateObjectToString(from));
    req.putParam('toDate', dateObjectToString(to));
    req.putParam('title', (title));
    req.putParam('issuer', JSDB().currentAgent);
    if (withOrders)
        req.putParam('withOrders', true);
    if (withNote)
        req.putParam('withNotes', true);
    if (specific_ids)
        req.putParam('specific_products', NKTypeConvertor().toKotlinList(specific_ids));
    if (seperate)
        req.putParam('seperate', seperate);
    if (byIds)
        req.putParam('byIds', byIds);
    const result = await req.execute();

    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    // bytearray
    return result.first;

};
export const dailyBuildQuerySum: (ids: number[], date: Date, groupType: number, withNote?: number, withOrders?: number, specific_ids?: number[], title?: string, seperate?: number, byIds?: number) => Promise<any> = async (ids, date, groupType, withNote, withOrders, specific_ids, title, seperate, byIds) => {
    const req: ApiCall = new ApiCall('DRIVER_QUERY_SUM_PDF');

    req.putParam('ids', NKTypeConvertor().toKotlinList(ids));
    req.putParam('date', dateObjectToString(date));
    req.putParam('groupType', (groupType));
    if (specific_ids)
        req.putParam('specific_products', NKTypeConvertor().toKotlinList(specific_ids));
    if (withOrders)
        req.putParam('withOrders', withOrders);
    if (withNote)
        req.putParam('withNotes', withNote);
    if (title)
        req.putParam('title', title);
    if (seperate)
        req.putParam('seperate', seperate);
    if (byIds)
        req.putParam('byIds', byIds);

    const result = await req.execute();

    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    // bytearray
    return result.first;

};


export const update_note_amounts: (d: ExtendedDocument) => Promise<ExtendedDocument | null> = async (d) => {
    let req: ApiCall = new ApiCall('UPDATE_CLIENT_NOTE');
    if (d.type == 'order') {
        req = new ApiCall('UPDATE_CLIENT_ORDER');
        req.putParam('order_id', Number(d.id));
    } else if (d.type == 'supplier_note') {
        req = new ApiCall('UPDATE_SUPPLIER_NOTE');
        req.putParam('id', Number(d.id));
    } else {
        if ((d as ProductNote).bul)
            req.putParam('connected_id', NKTypeConvertor().toKotlinLong((d as ProductNote).bul));
        req.putParam('id', Number(d.id));
    }
    req.putParam('delivery_value', NKTypeConvertor().toKotlinList(collect((d as ProductNote).products, Number(d.entId), true, JSDB().getUser().first.note_price == 1)));

    req.putParam('updatePrice', true);
    const result = await req.execute();
    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    // bytearray

    return deserializeDocument(result.first);

};


export const getAllPaysDetails: (fromDate?: Date, toDate?: Date, ids?: number[], paid?: boolean) => Promise<Payment[]> = async (fromDate, toDate, ids, paid) => {
    const req: ApiCall = new ApiCall('GET_CLIENT_PAY_DETAILS');
    if (ids)
        req.putParam('ids', NKTypeConvertor().toKotlinList(ids));
    if (fromDate)
        req.putParam('fromDate', fromDate);
    if (toDate)
        req.putParam('toDate', toDate);
    if (paid)
        req.putParam('paid', paid);

    const result = await req.execute();

    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    // bytearray
    if (result.first.size == 0)
        return [];

    return await result.first.toArray().map((p, index) => serializePayment(p, index));
    ;

};

export const updatePayment: (id: number, type: number, method: number, value: number, check_number?: string, details?: string, clear?: boolean, paid_date?: string, bank_id?: string) => Promise<PayNote> = async (id, type, method, value, check_number, details, clear, paid_date, bank_id) => {
    const req: ApiCall = new ApiCall('CLOSE_PAYMENT');
    req.putParam('id', id);
    req.putParam('method', method);
    req.putParam('type', type);
    req.putParam('value', value);
    if (check_number)
        req.putParam('check_number', (check_number));
    if (details)
        req.putParam('details', details);
    if (clear)
        req.putParam('clear', clear);
    if (paid_date)
        req.putParam('paid_date', (paid_date));
    if (bank_id)
        req.putParam('bank_id', (bank_id));
    const result = await req.execute();

    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    // bytearray
    return deserializeDocument(result.first);
    ;

};

export const cancelPaymentLine: (id: number, date: Date, method?: number, value?: number, check_number?: string, bank_id?: string) => Promise<PayNote> = async (id, date, method, value, check_number, bank_id) => {
    const req: ApiCall = new ApiCall('CANCEL_CLIENT_PAY');
    req.putParam('id', id);
    req.putParam('date', dateObjectToString(date));
    req.putParam('agent', JSDB().currentAgent);
    if (method)
        req.putParam('method', method);
    if (value)
        req.putParam('value', value);
    if (check_number)
        req.putParam('check_number', (check_number));
    if (bank_id)
        req.putParam('bank_id', (bank_id));
    console.log(req)
    const result = await req.execute();

    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    // bytearray
    return deserializeDocument(result.first);
    ;

};

export const getClientChecks: (id: number,sup?:boolean) => Promise<Payment | undefined> = async (id,sup) => {

    const req: ApiCall = new ApiCall('GET_CLIENT_CHECKS');

    req.putParam('client_id', id);
    req.putParam('last', 1);
    if(sup)
        req.putParam('sup', true);

    const result = await req.execute();

    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });

    if (result.first.size > 0) {
        return (result.first.toArray().map((p, index) => serializePayment(p, index))).find((p) => p.check != null);
    } else {
        return [];
    }

    ;

};
export const updateClientPay: (id: number, payNote?: PayNote, tax_note_id?: number, cancel_id?: number, active?: number) => Promise<PayNote> = async (id, payNote, tax_note_id, cancel_id, active) => {
    //FOR NOW DONT USE TAX_NOTE_ID SINCE THE MINUS APPLY FOR BOTH TAX AND REFUND
    const req: ApiCall = new ApiCall('UPDATE_CLIENT_PAY');
    req.putParam('id', id);
    if (payNote)
        req.putParam('paymentsData', NKTypeConvertor().toKotlinList(payNote.payments.map((p) => deserializePayment(p))));
    if (tax_note_id)
        req.putParam('tax_note_id', tax_note_id);
    if (active)
        req.putParam('active', active);
    const result = await req.execute();

    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    // bytearray
    return await deserializeDocument(result.first);
    ;

};
export const close_all_docs: (docs: ExtendedDocument[]) => Promise<ExtendedDocument[]> = async (docs) => {

    const dvs = docs.filter((d) => d.type == 'delivery_certificate' || d.type == 'supplier_note')
    const docs_new = []
    if (dvs.length > 0) {
        const x = await close_all_dv(dvs)
        docs_new.push(...x)
    }

    const tns = (docs.filter((d) => d.type == 'invoice' || d.type == 'refund'))

    if (tns.length > 0) {
        const x = await close_all_tn(tns)
        docs_new.push(...x)
    }
    return docs_new;
    ;

};
export const close_all_dv: (d: ExtendedDocument[]) => Promise<ExtendedDocument[]> = async (d) => {
    let req: ApiCall = new ApiCall('UPDATE_ALL_CLIENT_NOTE');
    if (d[0].type == 'delivery_certificate') {

        const docs = d.filter((doc) => doc.status == 'open' && doc.type == 'delivery_certificate');
        req.putParam('delivery_ids', NKTypeConvertor().toKotlinList(docs.map((p) => p.id)));
        req.putParam('paids', NKTypeConvertor().toKotlinList(docs.map((p) => 1)));

    } else {
        const docs = d.filter((doc) => doc.type == 'supplier_note');
        req = new ApiCall('CLOSE_SUPPLIER_NOTES');
        req.putParam('ids', NKTypeConvertor().toKotlinList(docs.map((p) => p.id)));
    }

    const result = await req.execute();

    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    // bytearray

    return await docResultsDeserialize(result.first);
    ;

};
export const close_all_tn: (d: ExtendedDocument[],sup?:boolean) => Promise<ExtendedDocument[]> = async (d,sup) => {
    let req: ApiCall = new ApiCall('UPDATE_ALL_CLIENT_TAX_NOTE');
    const docs = d.filter((doc) => doc.status != 'closed' && ((!sup && (doc.type == 'invoice' || doc.type == 'refund')) ||
    (sup && (doc.type == 'sup_invoice' || doc.type == 'sup_refund'))));
    if (docs.length == 0)
        return d
    req.putParam('ids', NKTypeConvertor().toKotlinList(docs.map((p) => p.id)));
    req.putParam('types', NKTypeConvertor().toKotlinList(docs.map((p) => p.nkObject?.type ?? 0)));
    req.putParam('active_state', NKTypeConvertor().toKotlinList(docs.map((p) => 2)));

    const result = await req.execute();

    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    // bytearray

    return await docResultsDeserialize(result.first);
    ;

};
export const all_dvs_printed: (d: ExtendedDocument[]) => Promise<ExtendedDocument[]> = async (d) => {
    const req: ApiCall = new ApiCall('UPDATE_ALL_CLIENT_NOTE');
    req.putParam('delivery_ids', NKTypeConvertor().toKotlinList(d.map((p) => p.id)));
    req.putParam('pdf', NKTypeConvertor().toKotlinList(d.map((p) => 1)));

    const result = await req.execute();

    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    // bytearray

    return await docResultsDeserialize(result.first);
    ;

};
export const refreshDocsCall: (ids: number[], types: number[], offline?: boolean, full?: boolean) => Promise<ExtendedDocument[]> = async (ids, types, offline, full) => {
    const req: ApiCall = new ApiCall('REFRESH_DOCS');


    req.putParam('documents', NKTypeConvertor().toKotlinList(ids));
    req.putParam('types', NKTypeConvertor().toKotlinList(types));

    if (offline)
        req.putParam('offline', true);
    if (full)
        req.putParam('full', full);
    const result = await req.execute();

    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });

    return docResultsDeserialize(result.first)!;
};
export const cancelCustomerDocuments: (d: ExtendedDocument, date?: Date) => Promise<ExtendedDocument> = async (d, date) => {
    const req: ApiCall = new ApiCall('ACTIVE_DOCUMENTS');
    const action = d.status === 'open';
    req.putParam('date', dateObjectToString(date ?? d.date));
    req.putParam('doc', d.nkObject);
    req.putParam('active', action);
    req.putParam('agent', JSDB().currentAgent);
    const result = await req.execute();

    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });

    return deserializeDocument(result.first)!;
};
export const changeAllOrderNoteDate: (ids, date) => Promise<ExtendedDocument[]> = async (ids, date) => {
    const req: ApiCall = new ApiCall('UPDATE_ALL_CLIENT_ORDER');

    req.putParam('order_ids', NKTypeConvertor().toKotlinList(ids));
    req.putParam('date', dateObjectToString(date));
    const result = await req.execute();

    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });

    return docResultsDeserialize(result.first);
};
export const cancelOrderNote: (ids) => Promise<ExtendedDocument[]> = async (ids) => {
    const req: ApiCall = new ApiCall('UPDATE_ALL_CLIENT_ORDER');

    req.putParam('order_ids', NKTypeConvertor().toKotlinList(ids));
    const status = ids.map((p) => 'מבוטלת');
    req.putParam('status', NKTypeConvertor().toKotlinList(status));
    const result = await req.execute();

    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    return docResultsDeserialize(result.first);
};

export const prepareCustomerCarteset: (fromDate: Date, toDate: Date, cid: number) => Promise<CartesetDataHolder[] | null> = async (fromDate, toDate, cid) => {
    const req: ApiCall = new ApiCall('CALCULATE_CLIENT_DEBT_TILL_NOW');


    req.putParam('fromDate', dateObjectToString(fromDate));
    req.putParam('toDate', dateObjectToString(toDate));
    if (cid)
        req.putParam('id', cid);

    const result = await req.execute();


    if (result == null || result.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    return deserializeCarteset(JSDB().getClient(Number(cid)).first);

};


export const getClientsDaily: (fromDate: Date, toDate: Date, cid?: number, day?: number, specific_driver?: number[], specific_collector?: number[], ids?: number[]) => Promise<any[] | null> = async (fromDate, toDate, cid, day, specific_driver, specific_collector, ids) => {
    const req: ApiCall = new ApiCall('GET_CLIENT_DAILY');

    req.putParam('date', dateObjectToString(fromDate));
    if (cid)
        req.putParam('id', cid);
    if (ids)
        req.putParam('ids', NKTypeConvertor().toKotlinList(ids));
    if (day)
        req.putParam('day', day);
    if (specific_driver && specific_driver.length > 0)
        req.putParam('driver_ids', NKTypeConvertor().toKotlinList(specific_driver));
    if (specific_collector && specific_collector.length > 0)
        req.putParam('collector_ids', NKTypeConvertor().toKotlinList(specific_collector));
    const result = await req.execute();

    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    if (result.first.size > 0) {
        return result.first.toArray();
    }
    return [];
};
export const newAllDaily: (date: Date, orderData: ICustomer[], activeState: OrderActiveState) => Promise<boolean> = async (date, orderData, activeState) => {
    const req: ApiCall = new ApiCall('NEW_CLIENT_ALL_DAILY');
    const d = dateObjectToString(date);

    if (activeState.date)
        req.putParam('date', d);
    if (activeState.day != -1)
        req.putParam('day', activeState.day);
    const data = orderData.map((p, i) => deserializeCustDataToClientDaily(p, p.driver_position ?? 0, p.collector_position ?? 0, d));

    req.putParam('data', NKTypeConvertor().toKotlinList(data));
    const result = await req.execute();


    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });

    return true;

};
export const cancelAllDvs: (dvs: number[]) => Promise<any[] | ExtendedDocument[] | null> = async (dvs) => {

    const req: ApiCall = new ApiCall('UPDATE_ALL_CLIENT_NOTE');
    req.putParam('delivery_ids', NKTypeConvertor().toKotlinList(dvs));
    req.putParam('actives', NKTypeConvertor().toKotlinList(dvs.map((p) => 0)));

    const result = await req.execute();


    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });


    return docResultsDeserialize(result.first);

};
export const newAllDailyNew: (date: Date, orderData: OrderData[], activeState: OrderActiveState) => Promise<boolean> = async (date, orderData, activeState) => {
    const d = dateObjectToString(date);
    const req: ApiCall = new ApiCall('NEW_CLIENT_ALL_DAILY');
    if (activeState.date)
        req.putParam('date', d);
    if (activeState.day != -1)
        req.putParam('day', activeState.day);

    const data = orderData.map((p) => deserializeOrderDataToClientDaily(p, d, activeState.day));
    req.putParam('data', NKTypeConvertor().toKotlinList(data));
    const result = await req.execute();


    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });


    return true;

};

export const newBase: (d: any) => Promise<boolean> = async (d) => {

    const req: ApiCall = new ApiCall('CHANGE_BASE_AMOUNTS');
    // data c_id,p_id, amount, day? , algo ?


    const data = d.map((p) => JSDB().createBaseData(p.c_id, p.p_id, p.amount, p.day));
    req.putParam('data', NKTypeConvertor().toKotlinList(data));
    const result = await req.execute();

    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });

    return true;

};
export const updateDriver: (id, driver, all_base: boolean, date?: string) => Promise<boolean> = async (id, driver, all_base, date) => {
    const req: ApiCall = new ApiCall('UPDATE_DRIVER');
    req.putParam('id', id);
    req.putParam('driver', driver);
    req.putParam('all_base', all_base);
    if (date)
        req.putParam('fromDate', date);
    const result = await req.execute();
    if (result == null || (result.name !== 'SUCCESS' && result.name !== 'ERROR_EMPTY_NEW'))
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    return true;
};
export const clearCarteset: (id, date,sup) => Promise<boolean> = async (id, date,sup) => {
    const req: ApiCall = new ApiCall('CLEAR_CARTESET');
    req.putParam('id', id);
    req.putParam('date', dateObjectToString(date));
    if(sup)
        req.putParam('sup', sup);

    const result = await req.execute();
    if (result == null || (result.name !== 'SUCCESS'))
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    return true;
};
export const storeNextLoad: (data, date) => Promise<string> = async (data, date) => {
    const req: ApiCall = new ApiCall('STORE_NEXT_LOAD');
    req.putParam('data', NKTypeConvertor().toKotlinList(data.map((d) => NKTypeConvertor().toKotlinList((d.map((p) => String(p)))))));
    req.putParam('date', dateObjectToString(date));
    console.log(req)
    const result = await req.execute();
    if (result == null || (result.second.name !== 'SUCCESS'))
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    console.log(result)
    return (result.first);
};

export const productsFromNotes: (doc: TaxNote) => IItem[] = async (doc) => {
    const ids = doc.deliveryCertificatesIds.map((d) => Number(d))
    if (ids.length == 0)
        return []

    const req: ApiCall = new ApiCall('TAX_NOTE_EXPLAIN');
    req.putParam('ids', NKTypeConvertor().toKotlinList(ids));
    if(isSupplierDocument(doc.type))
        req.putParam('sup', true)

    const result = await req.execute();

    if (result == null || (result.second.name !== 'SUCCESS'))
        return new Promise((resolve, reject) => {
            reject(Error);
        });

    const ret = result.first
    if (ret.size == 0)
        return []
    const sup= isSupplierDocument(doc.type)
    const client =  sup ? JSDB().getSupplier(Number(doc.entId)).first :JSDB().getClient(Number(doc.entId)).first
    return ret.toArray().map((d) => {
        const product =sup ? JSDB() .getSupplierProduct(d.id).first:JSDB().getClientProduct(d.id).first
        return {
            discount: d.discount,
            quantityReturns: d.returns,
            totalPrice: roundDecimals((client.include_tax == 1) ? d.price / (1 - d.discount / 100) : d.priceBefore / (1 - d.discount / 100), 3),
            id: d.id,
            external_id: product.external_id,
            name: product.getName(),
            image: '',
            barcode: product.barcode,
            position: product.getPosition(),
            quantity: roundDecimals(d.amount, 3),
            quantitySecond: 0,
            no_tax_product: product.getNoTaxProduct() === 1,
            unit: '',
            in_unit: product.getUnitAmmount(),
            category: product.getCategory(),
            category2: product.getCategory2(),
            available_units: [],
            conversion_ratio: 0,
            price_min: roundDecimals((client.include_tax == 1) ? d.priceMin / (1 - d.discount / 100) : d.priceBeforeMin / (1 - d.discount / 100), 3),
            price_max: roundDecimals((client.include_tax == 1) ? d.priceMax / (1 - d.discount / 100) : d.priceBeforeMax / (1 - d.discount / 100), 3),
            total: roundDecimals((client.include_tax == 1) ? d.total : d.totalBefore, 3),
        }
    })
}

export const dvFromOrder: (date: Date, orders: ExtendedDocument[], refresh?: boolean) => Promise<any[] | ExtendedDocument[] | null> = async (date, orders, refresh) => {
    const req2: ApiCall = new ApiCall('NEW_ALL_CLIENT_NOTE');
    const dateIssued = (JSDB().getDatesManipulator().dateNow());

    const pds = NKTypeConvertor().toKotlinList(orders.map((p) => (p.nkObject.delivery_info)));
    const ids = NKTypeConvertor().toKotlinList(orders.map((p) => (Number(p.entId))));
    const order_ids = NKTypeConvertor().toKotlinList(orders.map((p) => (p.id)));
    req2.putParam('notes', NKTypeConvertor().toKotlinList(orders.map((p) => p.comment ?? '')));
    req2.putParam('notes2', NKTypeConvertor().toKotlinList(orders.map((p) => p.notes2 ?? '')));
    req2.putParam('notes3', NKTypeConvertor().toKotlinList(orders.map((p) => p.notes3 ?? '')));
    req2.putParam('notes4', NKTypeConvertor().toKotlinList(orders.map((p) => p.notes4 ?? '')));
    req2.putParam('notes5', NKTypeConvertor().toKotlinList(orders.map((p) => p.notes5 ?? '')));
    // req2.putParam('orderAgents', NKTypeConvertor().toKotlinList(orders.map((p) => p.on.orderDriver ?? '')));
    req2.putParam('date', dateObjectToString(date));
    req2.putParam('agent', JSDB().currentAgent);
    req2.putParam('ids', ids);
    req2.putParam('date_issued', dateIssued);
    req2.putParam('pds', pds);
    req2.putParam('order_ids', order_ids);
    req2.putParam('withTaxNotes', true);

    const result2 = await req2.execute();
    if (result2 == null || result2.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
                reject(Error);
            }
        );
    let notes = docResultsDeserialize(result2.first)
    if (refresh) {
        const curdocs = await refreshDocsCall(orders.map((p) => p.id), orders.map((p) => p.nkObject.getConnectedDocType()), false, true)
        curdocs?.forEach((p) => {
            notes.push(p)
        })
    }

    return notes;


}
export const newAllOrderNote: (date: Date, orderData: OrderData[], withOrder: boolean, activeState: OrderActiveState, dv?: any, old?: boolean, historyState?: boolean) => Promise<any[] | ExtendedDocument[] | null> = async (date, orderData, withOrder, activeState, dv, old, historyState) => {
    if (orderData.length == 0)
        return [];

    const d = dateObjectToString(date);
    const ret: ExtendedDocument[] = [];

    if (dv) {
        let index = 0;
        const new_dvs = orderData.map((on) => {
            return {
                on: on,
                pds: (dv[index] == null) ? deserializeOrderDataToOrder(on) : old ? deserializeDataStructOld(dv[index]) : deserializeDataStruct(dv[index])
            };
        }).filter((o) => o.pds.size > 0 && !o.on.readonly && (o.on.orderId && o.on.orderId != '') && JSDB().checkValidForDv(o.pds));
        if (new_dvs.length > 0) {
            const req2: ApiCall = new ApiCall('NEW_ALL_CLIENT_NOTE');
            const dateIssued = (JSDB().getDatesManipulator().dateNow());

            const pds = NKTypeConvertor().toKotlinList(new_dvs.map((p) => (p.pds)));
            const ids = NKTypeConvertor().toKotlinList(new_dvs.map((p) => (Number(p.on.id))));
            const order_ids = NKTypeConvertor().toKotlinList(new_dvs.map((p) => (p.on.orderId ? Number(p.on.orderId) : null)));
            req2.putParam('notes', NKTypeConvertor().toKotlinList(new_dvs.map((p) => p.on.notes ?? '')));
            req2.putParam('notes2', NKTypeConvertor().toKotlinList(new_dvs.map((p) => p.on.notes2 ?? '')));
            req2.putParam('notes3', NKTypeConvertor().toKotlinList(new_dvs.map((p) => p.on.notes3 ?? '')));
            req2.putParam('notes4', NKTypeConvertor().toKotlinList(new_dvs.map((p) => p.on.notes4 ?? '')));
            req2.putParam('notes5', NKTypeConvertor().toKotlinList(new_dvs.map((p) => p.on.notes5 ?? '')));
            req2.putParam('orderAgents', NKTypeConvertor().toKotlinList(new_dvs.map((p) => p.on.orderDriver ?? '')));
            req2.putParam('date', d);
            req2.putParam('agent', JSDB().currentAgent);
            req2.putParam('ids', ids);
            req2.putParam('date_issued', dateIssued);
            req2.putParam('pds', pds);
            req2.putParam('order_ids', order_ids);
            req2.putParam('withTaxNotes', true);
            const round = parseInt(localStorage.getItem('roundTax') ?? '0') == 1
            req2.putParam('round', round);
            const result2 = await req2.execute();
            if (result2 == null || result2.second.name !== 'SUCCESS')
                new Promise((resolve, reject) => {
                    reject(Error);
                });
            const notes = await docResultsDeserialize(result2.first);
            ret.push(...notes);
        }

    }
    if (withOrder) {
        if (!dv) {
            const orderDataFiltered = orderData;
            if (orderDataFiltered.length > 0) {
                const req2: ApiCall = new ApiCall('NEW_ALL_CLIENT_ORDER');
                req2.putParam('date', d);
                req2.putParam('agent', JSDB().currentAgent);
                req2.putParam('ids', NKTypeConvertor().toKotlinList(orderDataFiltered.map((p) => Number(p.id))));
                const order_ids = NKTypeConvertor().toKotlinList(orderDataFiltered.map((p) => (p.orderId && p.orderId != '' ? Number(p.orderId) : null)));
                req2.putParam('order_ids', order_ids);
                if (activeState.day !== -1) {
                    req2.putParam('day', activeState.day);
                }

                const data2 = orderDataFiltered.map((p) => deserializeOrderDataToOrder(p));
                const categories = orderDataFiltered.map((p) => p.category ?? '');
                const status = orderDataFiltered.map((p) => p.status ?? '');

                req2.putParam('notes', NKTypeConvertor().toKotlinList(orderDataFiltered.map((p) => p.notes ?? '')));
                req2.putParam('notes2', NKTypeConvertor().toKotlinList(orderDataFiltered.map((p) => p.notes2 ?? '')));
                req2.putParam('notes3', NKTypeConvertor().toKotlinList(orderDataFiltered.map((p) => p.notes3 ?? '')));
                req2.putParam('notes4', NKTypeConvertor().toKotlinList(orderDataFiltered.map((p) => p.notes4 ?? '')));
                req2.putParam('notes5', NKTypeConvertor().toKotlinList(orderDataFiltered.map((p) => p.notes5 ?? '')));
                req2.putParam('orderAgents', NKTypeConvertor().toKotlinList(orderDataFiltered.map((p) => p.orderDriver ?? '')));
                req2.putParam('pds', NKTypeConvertor().toKotlinList(data2));
                req2.putParam('categories', NKTypeConvertor().toKotlinList(categories));
                req2.putParam('status', NKTypeConvertor().toKotlinList(status));

                req2.putParam('ref_ids', NKTypeConvertor().toKotlinList(orderDataFiltered.map((p) => p.ref_id ?? '')));

                const cc = JSDB().getCollectors().first
                if (cc.size > 0) {
                    const cols = cc.toArray()
                    const collector = orderDataFiltered.map((p) => {
                        const s = []
                        cols.forEach((c) => {

                            if (p[`c_${c.id}`])
                                s.push(JSDB().createNewCollector(c.id, p[`c_${c.id}`]))
                        })
                        if (s.length > 0)
                            return NKTypeConvertor().toKotlinList(s)
                        return undefined

                    })
                    req2.putParam('collection', NKTypeConvertor().toKotlinList(
                        collector
                    ));
                }


                if (historyState)
                    req2.putParam('withHistory', historyState);
                const result2 = await req2.execute();

                if (result2 == null || result2.second.name !== 'SUCCESS')
                    return new Promise((resolve, reject) => {
                        reject(Error);
                    });
                const orders = await docResultsDeserialize(result2.first);
                ret.push(...orders);
            }

        } else {
            const order_ids = orderData.filter((od) => od.orderId).map((od) => Number(od.orderId));
            if (order_ids.length > 0) {
                const req2: ApiCall = new ApiCall('GET_CLIENT_ORDER');
                req2.putParam('order_ids', NKTypeConvertor().toKotlinList(order_ids));
                const result2 = await req2.execute();

                if (result2 == null || result2.second.name !== 'SUCCESS')
                    return new Promise((resolve, reject) => {
                        reject(Error);
                    });
                const orders = await docResultsDeserialize(result2.first);
                ret.push(...orders);
            }
        }
    }

    return ret;
};

export const allTaxQDeserialize: (ret: any, date: string) => AllTaxNoteHolder[] = (ret, date) => {
    if (ret.length > 0) {
        return ret.map((t) => {
            const c = JSDB().getClient(t.client_id).first;
            const notes = docResultsDeserialize(t.notes);
            const taxNotes = docResultsDeserialize(t.taxNotes);
            return {
                id: c.id,
                nkObject: t,
                type: 'client',
                name: c.getName(),
                bname: c.getBusinessName(),

                driver: c.getDriver()?.user_name ?? '',
                city: c.getCity(date),
                agent: c.getAgent(date),

                amount_dv: t.totalNotesNumber(),
                open_amount_dv: t.openNotesNumber(),
                canceled_amount_dv: t.canceledNotesNumber(),

                open_value_dv: roundDecimals(t.openSum, 2),
                open_tax: roundDecimals(t.openTax, 2),

                total_value_dv: roundDecimals(t.totalNotes, 2),
                total_tax_dv: roundDecimals(t.totalTax, 2),

                amount_tn: taxNotes.length,
                value_tn: roundDecimals(t.totalSum, 2),
                value_tax_tn: roundDecimals(t.totalTaxTn, 2),
                sub: notes,
                subClient: t.getClients().toArray()

            };
        });
    }
    return [];

};
export const getAllTaxNoteQuery: (fromDate: Date, toDate: Date, cid?: number[], citys?: string[], agents?: string[], creators?: string[], allOpen?: boolean) => Promise<AllTaxNoteHolder[]> = async (fromDate, toDate, cid, citys, agents, creators, allOpen) => {


    const req = new ApiCall('PREPARE_MASS_TAX_NOTE');
    req.putParam('date', dateObjectToString(toDate));
    req.putParam('fromDate', dateObjectToString(fromDate));
    if (cid) {
        req.putParam('client_ids', NKTypeConvertor().toKotlinList(cid));
    }
    if (allOpen)
        req.putParam('allOpen', true);
    // req.putParam('byMoney', true);

    const result = await req.execute();
    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });

    let arr;
    if (result.first.size > 0) {
        arr = result.first.toArray();
    } else {
        return [];
    }

    const x = allTaxQDeserialize(arr, dateObjectToString(toDate));
    return x;

};
export const hashSign: (id: number, type: number) => Promise<ExtendedDocument> = async (id, type) => {


    const req = new ApiCall('HASH_ISRAEL_SIGN');
    req.putParam('id', id);
    req.putParam('type', type);


    // req.putParam('byMoney', true);

    const result = await req.execute('LOGIN_FAILED');

    if (result.second.name == 'LOGIN_FAILED')
        return null;
    return deserializeDocument(result.first)!;

};
export const hashIsraelToken: (token) => Promise<boolean> = async (token) => {

    const req = new ApiCall('HASH_ISRAEL_TOKEN');
    req.putParam('token', token);


    // req.putParam('byMoney', true);

    const result = await req.execute();
    if (result == null || result.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });

    return true;

};
export const splitDocsBy: (date: Date, docs: ExtendedDocument[], users, orderType, dailyG) => any = (date, docs, users, orderType, dailyG) => {
    const g = {}
    switch (orderType) {
        case "pOrder":
        case "driver":
            const u = groupBy(users.filter((u) => u.isDriver()), (item) => item.id);
            docs.forEach((d) => {
                const driverId = dailyG[Number(d.entId)]?.[0]?.driver_id ?? undefined
                const driver = u[driverId]?.[0]?.user_name

                if (!g[driver])
                    g[driver] = []
                g[driver].push(d)


            })
            break
        case "collector":
            const u2 = groupBy(users.filter((u) => u.isCollector()), (item) => item.id);
            docs.forEach((d) => {
                const driverId = dailyG[d.entId]?.[0]?.collector_id ?? undefined
                const driver = u2[driverId]?.[0]?.user_name

                if (!g[driver])
                    g[driver] = []
                g[driver].push(d)


            })
            break

        case "agent":

            docs.forEach((d) => {
                const agent = JSDB().getClient(Number(d.entId)).first.getAgent()
                if (!g[agent])
                    g[agent] = []
                g[agent].push(d)

            })
            break
        default:
            break

    }
    return g
}

export const sortPlanned: (date: Date, planned: ExtendedDocument[], user, orderType, dailyG) => ExtendedDocument[] = (date, planned, user, orderType, dailyG) => {

    switch (orderType) {
        case "driver":
            return planned.sort((a, b) => {
                const a_position = dailyG[Number(a.entId)]?.[0]?.position ?? 1000000
                const b_position = dailyG[Number(b.entId)]?.[0]?.position ?? 1000000
                return a_position - b_position
            })

        case "collector":
            return planned.sort((a, b) => {
                const a_position = dailyG[Number(a.entId)]?.[0]?.position_col ?? 1000000
                const b_position = dailyG[Number(b.entId)]?.[0]?.position_col ?? 1000000
                return a_position - b_position
            })

        case "agent":
            return planned.sort((a, b) => {
                const a_position = JSDB().getClient(Number(a.entId))?.first?.position ?? 1000000
                const b_position = JSDB().getClient(Number(b.entId))?.first?.position ?? 1000000
                return a_position - b_position
            })

        default:
            return planned;

    }
}
export const plannedVActual: (date: Date, planned: ExtendedDocument[], actual: ExtendedDocument[], user, orderType) => Promise<RoutePlan> = async (date, planned, actual, user, orderType) => {

    const d = user
    const name = d?.user_name ?? `ללא ${orderType}`
    const route: RoutePlan = {
        driver: name,
        id: d?.id ?? -1,
        route: [],
        value: 0,
    };
    // Loop over each order for the driver
    const productsMap = groupBy(actual, (item) => `${item.entId},${dateObjectToString(item.date)}`);
    const orderMap = distinctByField(planned, 'entId')

    let i = 0
    Object.keys(productsMap).forEach((entId, index) => {
        const p = productsMap[entId];
        const first = p[0];
        const amount = p.reduce((sum, x) => sum + x.totalAmount, 0)
        let o
        if (planned.length > index)
            o = orderMap[index]
        i += 1
        const client = JSDB().getClient(Number(first.entId)).first;
        const location = client?.location ?? '';
        const city = client?.city ?? '';
        route.route.push({

            beenPosition: index,
            cname: first.entName,
            cid: Number(first.entId),
            city: city,
            location: location,
            date: dateObjectToString(first.issueDate),
            time: dateTimeObjectToString(first.issueDate).split(" ")[1],
            value: amount,
            docs: p,
            plannedDoc: o, driverId: d?.driverId, driverName: name,
            plannedPosition: o ? index : undefined,
            planName: o ? o.entName : '',
            planId: o ? o.entId : -1

        });
    })
    for (; i < orderMap.length; i++) {
        const o = orderMap[i];
        route.route.push({
            cname: "",
            cid: Number(o.entId),
            city: "",
            date: "",
            time: "00:00",
            beenPosition: -1,
            docs: [],
            value: 0,
            driverId: d?.driverId, driverName: name,
            plannedDoc: o,
            plannedPosition: i,
            planName: o.entName,
            planId: o.entId
        });
    }
    route.value = route.route.reduce((sum, x) => sum + x.value, 0)
    return route

}

export const docsToPlannedVsActual: (date: Date, toDate: Date, docs: ExtendedDocument[], actualType: DocumentType[], plannedType: string, orderType, splitByAgent) => Promise<RoutePlan[]> = async (date, toDate, docs, actualType, plannedType, orderType, splitByAgent) => {

    const daily = await getClientsDaily(toDate, toDate, undefined, undefined, undefined, undefined, docs.map((d) => Number((d.entId))))
    const users = JSDB().getAgents().first.toArray()
    const dailyG = groupBy(daily, (item) => item.id);
    const actualDocs = docs?.filter((d) => actualType.includes(d.type)).sort((a, b) => {
        const date1 = a.issueDate
        const date2 = b.issueDate
        if (date1 < date2) {
            return -1;
        } else if (date1 > date2) {
            return 1;
        } else {
            return 0;
        }
    }) ?? []
    const plannedDocs = docs?.filter((d) => d.type == plannedType).sort((a, b) => {
        const date1 = a.issueDate
        const date2 = b.issueDate
        if (date1 < date2) {
            return -1;
        } else if (date1 > date2) {
            return 1;
        } else {
            return 0;
        }
    }) ?? []

    let g
    if (splitByAgent) {
        g = groupByUnStr(actualDocs, (item) => item.agent);
    } else {
        g = splitDocsBy(date, actualDocs, users, orderType, dailyG)
    }


    let p
    if (plannedDocs && plannedDocs.length > 0) {
        p = splitDocsBy(date, plannedDocs, users, orderType, dailyG)
    }
    const routes = []
    for (const agent of Object.keys(g)) {
        const curDocs = g[agent];
        const actual = curDocs ?? []
        let planned: ExtendedDocument[] = p?.[agent] ?? []
        const curUser = users.find((d) => {
            return d.user_name == agent.replace(/"/g, '');
        })

        if (plannedType == 'dOrder') {
            planned = sortPlanned(date, [...actual], curUser, orderType, dailyG)
        } else if (orderType == 'pOrder') {

            const plannedMap = new Map(planned.map(item => [item.entId, item]));
            // Create a set of used entIds
            const usedEntIds = new Set<number | string>();
            const activeMap = distinctByField(actual, 'entId')
            // Iterate over actual and get matching item from planned or a blank item
            const orderedPlanned = activeMap.map(doc => {
                if (plannedMap.has(doc.entId)) {
                    usedEntIds.add(doc.entId);
                    return plannedMap.get(doc.entId)!;
                } else {
                    return {
                        id: 0,
                        entId: String(Math.random()),
                        entName: '',
                        entBName: ''
                    };
                }
            });

            // Append remaining items from planned that were not used
            for (let item of planned) {
                if (!usedEntIds.has(item.entId)) {
                    orderedPlanned.push(item);
                }
            }

            planned = orderedPlanned


        } else {
            planned = sortPlanned(date, planned, curUser, orderType, dailyG)
        }


        const route = await plannedVActual(date, planned, actual, curUser, orderType)

        routes.push(route);
    }


    return routes
};
