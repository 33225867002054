import { Box, Grid } from '@mui/material';
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';

import { useDispatch, useSelector } from 'react-redux';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import config from '../../config';
import { enUS, he } from 'date-fns/locale';
import { RootState } from '../../store';
import { getSpecificCustomer, selectCustomerById } from '../../store/customers/customersSlice';
import { useCreateCustomerDebt } from '../../hooks/customer/documents.hooks';
import { getClientDebt } from '../../api/customer/documents.api';
import {memo} from "react";
import {selectSupplierById} from "../../store/suppliers/suppliersSlice";

function DialogAddManualDate({ id, open, setOpen,pool,sup }: { id: number, open, setOpen,pool,sup }) {
    const dispatch = useDispatch();
    const customer = sup ? useSelector((state: RootState) => selectSupplierById(state, id)) : useSelector((state: RootState) => selectCustomerById(state, id));
    React.useEffect(() => {
        if (!customer)
            dispatch(getSpecificCustomer(Number(id)));
    }, [dispatch]);
    if (!customer)
        return;
    const [date, setDate] = React.useState(new Date());
    const [notes, setNotes] = React.useState<string>('');
    const [positive, setPositive] = React.useState<string>('');
    const [negative, setNegative] = React.useState<string>('');
    React.useEffect(() => {
        getClientDebt(customer!.id, date,sup).then(r => {
            if (r.size > 0) {
                const data = r.toArray()[0]
                setPositive(data.positive);
                setNegative(data.negative);
            } else {
                setPositive('');
                setNegative('');
            }
        }).catch(() => console.log('no debt'));
    }, [date,sup]);

    const updateCustomerDept = useCreateCustomerDebt(pool,sup);
    const handleOk = async () => {
        if (positive != '' || negative != '') {
            updateCustomerDept.mutate({
                cid: Number(customer.id),
                date: date,
                positive: Number(positive) ?? 0,
                negative: Number(negative) ?? 0,
                notes: notes
            });
        }
        handleClose();
    };

    const handleClose = () => {
        setOpen(false);
    };
    const onApprove =
        () => {
            handleOk();
        };
    return (
        <div>
            {open ? <Dialog id='doc_search_dialog' maxWidth={'sm'} fullWidth={true}
                open={open} onClose={handleClose}>
                <DialogTitle> {'הוסף חוב ידני לכרטסת'}
                    <LocalizationProvider dateAdapter={AdapterDateFns}
                        locale={config.i18n === 'he' ? he : enUS}>
                        <DatePicker
                            renderInput={(props) => <TextField {...props} variant={'standard'} type={'date'} />}
                            label={'תאריך חוב ידני'}
                            value={date ?? new Date()}
                            onChange={(newValue) => {
                                if (newValue && !isNaN(newValue.getDate())) setDate(newValue);
                            }}
                        />
                    </LocalizationProvider>
                </DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={5} sm={5}>
                            <TextField
                                label={'חובה'}
                                type={'number'}
                                value={positive}
                                sx={{ minWidth: 10 }}
                                onChange={(v) => {
                                    setPositive(v.target.value);
                                }}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                variant='standard'
                            />
                        </Grid>
                        <Grid item xs={2} sm={2}>
                            <Box />
                        </Grid>
                        <Grid item xs={5} sm={5}>
                            <TextField
                                label={'זכות'}
                                type={'number'}
                                style={{ direction: 'ltr', textAlign: 'end' }}
                                value={negative}
                                sx={{ minWidth: 10 }}
                                onChange={(v) => {
                                    setNegative(v.target.value);
                                }}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                variant='standard'
                            />
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <TextField
                                label={'היערות'}
                                type={'text'}
                                value={notes}
                                sx={{ minWidth: 10 }}
                                onChange={(v) => {
                                    setNotes(v.target.value);
                                }}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                variant='standard'
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={(e) => {
                        onApprove();
                    }} style={{ backgroundColor: 'green', color: 'white' }}>המשך
                    </Button>
                    <Button onClick={handleClose} style={{ backgroundColor: 'red', color: 'white' }}>ביטול</Button>
                </DialogActions>
            </Dialog> : undefined}
        </div>
    );
}
export default memo(DialogAddManualDate);
