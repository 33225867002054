import React, {memo, useEffect} from 'react';
import {
    Autocomplete,
    Box,
    Checkbox,
    Divider,
    IconButton,
    InputAdornment,
    ListItemIcon,
    Menu,
    MenuItem,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import {DatePicker, LocalizationProvider} from '@mui/lab';
import {FormattedMessage} from 'react-intl';
import {enUS, he} from 'date-fns/locale';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import config from 'config';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import EventIcon from '@mui/icons-material/Event';
import SearchIcon from '@mui/icons-material/Search';
import {useSelector} from 'react-redux';
import {selectDrivers} from '../../../../store/agents/agentsSlice';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Agent from '../../../../types/agent';
import {getClientsDaily} from "../../../../api/customer/documents.api";

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;
const BaseOrDataSelector = ({ callPrevOrder, ids, date, disabled, boxid = undefined, externalAnchor }: {
    callPrevOrder: (date: Date, ids: number[], base: number, drivers: number[], undefined: undefined, b: boolean, b1: boolean, severalOrders: boolean, runBaseFirst: boolean) => void,
    ids: number[],
    date: Date,
    disabled: boolean,
    boxid?: string,
    externalAnchor?: HTMLElement
}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(externalAnchor ?? null);
    const [value, setValue] = React.useState<Agent[]>([]);
    const [runOverOrders, setRunOverOrders] = React.useState<boolean>(false);
    const [severalOrders, setSeveralOrders] = React.useState<boolean>(false);
    const [runBaseFirst, setRunBaseFirst] = React.useState<boolean>(false);
    const [withDrivers, setWithDrivers] = React.useState<boolean>(parseInt(localStorage.getItem('orderWithDrivers') ?? '0') == 1);
    const drivers = useSelector(selectDrivers);
    const [open, setOpen] = React.useState(Boolean(anchorEl));
    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setValue([]);
        setRunOverOrders(false);
        setSeveralOrders(false);
        setOpen(false);
    };
    useEffect(() => {
        setAnchorEl(externalAnchor ?? null);
        setOpen(Boolean(anchorEl ?? externalAnchor));
    }, [externalAnchor]);


    const onClick = React.useCallback(
        (day: number) => {
            callPrevOrder(date, ids, day, value?.map((a) => a.id), undefined, runOverOrders, withDrivers, severalOrders,runBaseFirst);
            handleClose();
        },
        [date,ids,value,runOverOrders,withDrivers,severalOrders,runBaseFirst]
    );

    return (
        <Box>
            <Tooltip title='בחר מתוך הזמנה קיימת'>
                <span>
                    <IconButton
                        disabled={disabled}
                        onClick={handleOpen}
                        id="get-file-from-existing-order-menu-orders"
                        size='small'
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup='true'
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <EventIcon fontSize='small' />
                    </IconButton>
                </span>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                id={boxid}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0
                        }
                    }
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {(!ids) ? <MenuItem>
                    <Autocomplete
                        style={{ marginBottom: 12, marginTop: 10 }}
                        value={value}
                        onChange={(e: any, v: Agent[]) => {
                            setValue(v);
                        }}
                        multiple
                        freeSolo
                        onKeyDown={(e) => {
                            e.stopPropagation();
                        }
                        }
                        disableCloseOnSelect
                        options={drivers}
                        // TODO: use groupBy to support distinct values on other properties
                        // groupBy={(option) => option.firstLetter}
                        getOptionLabel={(o) => o.name}
                        size='small'
                        fullWidth
                        autoHighlight
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.name}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder={'בחר נהגים..'}

                                InputProps={{
                                    ...params.InputProps,
                                    onKeyDown: (e) => {
                                        if (e.keyCode != 37 && e.keyCode != 38 && e.keyCode != 39 && e.keyCode != 40 && e.keyCode != 13)
                                            e.stopPropagation();
                                    },

                                    endAdornment: (
                                        <InputAdornment position='start'>
                                            <SearchIcon fontSize='small' />
                                        </InputAdornment>
                                    )
                                }}
                                fullWidth
                            />
                        )}
                    />
                </MenuItem> : undefined}
                <MenuItem>
                    <Checkbox checked={runOverOrders} onChange={(e) => {
                        setRunOverOrders(e.target.checked);
                    }} name='לדרוס הזמנות נוכחיות' />
                    <Typography variant='h5' style={{ marginRight: 10 }} onClick={(e) => {
                        setRunOverOrders(!runOverOrders);
                    }}> לדרוס הזמנות נוכחיות </Typography>
                </MenuItem>
                <MenuItem>
                    <Checkbox checked={severalOrders} onChange={(e) => {
                        setSeveralOrders(e.target.checked);
                    }} name='כמה הזמנות מאותו לקוח' />
                    <Typography variant='h5' style={{ marginRight: 10 }} onClick={(e) => {
                        setSeveralOrders(!severalOrders);
                    }}> כמה הזמנות מאותו לקוח </Typography>
                </MenuItem>
                <MenuItem>
                    <Checkbox checked={withDrivers} onChange={(e) => {
                        localStorage.setItem('orderWithDrivers', e.target.checked ? '1' : '0');
                        setWithDrivers(e.target.checked);
                    }} name='עם סידור נהגים' />
                    <Typography variant='h5' style={{ marginRight: 10 }} onClick={(e) => {
                        localStorage.setItem('orderWithDrivers', !withDrivers ? '1' : '0');
                        setWithDrivers(!withDrivers);
                    }}
                    > עם סידור נהגים </Typography>
                </MenuItem>
                <MenuItem>
                    <Checkbox checked={runBaseFirst} onChange={(e) => {
                        setRunBaseFirst(e.target.checked);
                    }} name='עם סידור נהגים' />
                    <Typography variant='h5' style={{ marginRight: 10 }} onClick={(e) => {

                        setRunBaseFirst(!runBaseFirst);
                    }}
                    > לנסות בסיס קודם </Typography>
                </MenuItem>
                <MenuItem>
                    <div>


                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={config.i18n === 'he' ? he : enUS}>
                        <DatePicker
                            components={{
                                OpenPickerIcon: () => (
                                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                        <Tooltip title={<FormattedMessage id='Fetch From Previous Order' />}>
                                            <CalendarTodayIcon fontSize='small' />
                                        </Tooltip>
                                        <Typography variant='h5' style={{ marginRight: 10 }}> מתוך הזמנה
                                            קיימת </Typography>

                                    </Box>
                                )
                            }}
                            renderInput={({ inputRef, inputProps, InputProps }) => <Box
                                ref={inputRef}>{InputProps?.endAdornment}</Box>}
                            label={<FormattedMessage id='Date' />}
                            allowSameDateSelection
                            value={date ?? new Date()}
                            onChange={(newValue) => {
                                if (newValue) callPrevOrder(newValue, ids, -1, value?.map((a) => a.id), undefined, runOverOrders, withDrivers, severalOrders, runBaseFirst);
                            }}
                        />
                    </LocalizationProvider>
                    </div>
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => onClick(1)}>
                    <ListItemIcon>
                        <EventIcon fontSize='small' />
                    </ListItemIcon>
                    הזמנת בסיס יום א
                </MenuItem>
                <MenuItem onClick={() => onClick(2)}>
                    <ListItemIcon>
                        <EventIcon fontSize='small' />
                    </ListItemIcon>
                    הזמנת בסיס יום ב
                </MenuItem>
                <MenuItem onClick={() => onClick(3)}>
                    <ListItemIcon>
                        <EventIcon fontSize='small' />
                    </ListItemIcon>
                    הזמנת בסיס יום ג
                </MenuItem>
                <MenuItem onClick={() => onClick(4)}>
                    <ListItemIcon>
                        <EventIcon fontSize='small' />
                    </ListItemIcon>
                    הזמנת בסיס יום ד
                </MenuItem>
                <MenuItem onClick={() => onClick(5)}>
                    <ListItemIcon>
                        <EventIcon fontSize='small' />
                    </ListItemIcon>
                    הזמנת בסיס יום ה
                </MenuItem>
                <MenuItem onClick={() => onClick(6)}>
                    <ListItemIcon>
                        <EventIcon fontSize='small' />
                    </ListItemIcon>
                    הזמנת בסיס יום ו
                </MenuItem>
            </Menu>
        </Box>
    );
};


export default memo(BaseOrDataSelector);
