import {Cancel, Email, HighlightOffOutlined, Phone, UploadFile, WhatsApp} from "@mui/icons-material";
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Chip,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Input,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Popover,
    Radio,
    TextField,
    Typography
} from "@mui/material"
import JSDB from "fromKotlin/nk";
import {useEffect, useState} from "react"
import Swal from "sweetalert2";
import {DeviceFloppy, Pencil, User} from "tabler-icons-react";
import {useDispatch, useSelector} from "react-redux";
import {fillCustomers, selectCustomersidname, selectCustomersObjs} from "../../store/customers/customersSlice";
import * as React from "react";
import {changeAllCustomers} from "../../api/customer";
import {callbackNetworkResult, getFile, sendMsgAll, whatsappRefresh} from "../../api/customer/files.api";
import {SNACKBAR_OPEN} from "../../store/actions";
import LoadingButton from "@mui/lab/LoadingButton";
import Draggable from "react-draggable";
import Papper from '@mui/material/Paper';
import {GenerativeLoaderChip, GenerativeLoaderChipSum} from "../GenerativeLoader";
import {buildNotesSum, buildSupNotesSum} from "../../api/customer/documents.api";
import FunctionsOutlinedIcon from "@mui/icons-material/FunctionsOutlined";
import {dateObjectToString, dateObjectToStringIsrael} from "../../fromKotlin/nkutils";

const PapperComponent = (props: any) => {
    return (
        <Draggable
            handle="#draggable-gross-message-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Papper {...props} />
        </Draggable>
    );
}

const errorPopup = (errors: any[]) => {
    Swal.fire({
        title: `אירעה שגיאה בעת השליחה`,
        text: `
    ${errors.map((error) => {
            if (error === "no-phone") return "לא נמצא מספר טלפון";
            if (error === "no-email") return "לא נמצא כתובת אימייל";
            if (error === "no-message") return "לא נמצא תוכן הודעה";
        }).join("\n")}
    `,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'אישור',
        cancelButtonText: 'בטל'
    }).then((result) => {
        if (result?.isConfirmed) {
            // CONFIRM ALERTS
        }
    })
}

interface ISendMessageDialogProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    toSelected?: string[];
    externalVar?: string[];
    withFiles: boolean;
    files?: {
        ids: number[],
        types: number[]
    };
    defaultPlatform?: "whatsapp" | "email";
    msg?: string;
    subject?: string;
    action_date?: Date;
    onSend?: () => void;
}

const SendMessageDialog = ({
                               open,
                               setOpen,
                               toSelected,
                               files,
                               withFiles,
                               defaultPlatform,
                               subject,
                               msg,
                               onSend,
                               action_date,
                               externalVar
                           }: ISendMessageDialogProps) => {
    const [platform, setPlatform] = useState<"whatsapp" | "email">(defaultPlatform || "whatsapp");
    const toOptions: any[] = useSelector(selectCustomersidname);
    const [uploadFiles, setUploadFiles] = useState<File[]>([]);
    const url = new URL(window.location.href);
    const pathName = url.pathname;
    const [savedMessages, setSavedMessages] = useState<{
        id: number;
        name: string;
        message: string;
    }[]>(localStorage.getItem(`saved-messages-${pathName}`) ? JSON.parse(localStorage.getItem(`saved-messages-${pathName}`) as string) : []);
    const [saveNewMessage, setSaveNewMessage] = useState(false);
    const dispatch = useDispatch()
    const [form, setForm] = useState({
        to: [] as {
            name: string;
            id: string;
            phone?: string;
            email?: string;
        }[],
        subject: "",
        message: "",
        files: [] as string[] | File[],
    })
    const [openUserDetails, setOpenUserDetails] = useState(false);
    const [singleUser, setSingleUser] = useState({
        id: "",
        name: "",
        phone: "",
        email: "",
    });

    const [noPlatform, setNoPlatform] = useState(false);
    const [editPhone, setEditPhone] = useState(false);
    const [editEmail, setEditEmail] = useState(false);
    const [loading, setLoading] = useState(false);
    const isTaxFile = files?.types?.some((type) => type === 0);
    const isNoteFile = files?.types?.some((type) => type === 4);
    const [sendSum, setSendSum] = useState(false);
    const [withPrice, setWithPrice] = useState(undefined);
    useEffect(() => {
        if (platform === "email") {
            setNoPlatform(form.to.some((user) => !user.email || user.email === ""));
            return;
        }
        if (platform === "whatsapp") {
            setNoPlatform(form.to.some((user) => !user.phone || user.phone === ""));
            return;
        }
        setNoPlatform(false);
    }, [platform, editEmail, editPhone, form.to])

    const sendCallback = React.useCallback(async () => {
        try {
            setLoading(true)
            const filesOn = files?.ids?.length
            const curForm = form.to.filter((user) => (platform == "email" ? user.email !== "" : user.phone !== "") && (!filesOn || user.file != undefined && user.type != undefined));

            const ids = filesOn ? curForm.map((user) => user.file) : curForm.map((user) => Number(user.id));
            let doc_types = filesOn ? curForm.map((user) => user.type) : undefined;
            const emails = curForm.map((user) => user.email ?? '');
            const phones = curForm.map((user) => user.phone ?? '');
            const msg = form.message;
            const subject = form.subject;
            // ADD CALLBACK TO TO SEND SUM IF NEEDED
            if (ids?.length) {
                let  specialArg = undefined
                if(withPrice && sendSum){
                    specialArg = '3'
                } else if (withPrice){
                    specialArg = '1'
                } else if (sendSum){
                    specialArg = '2'
                }
                const x = await sendMsgAll(ids, msg, subject, doc_types, platform == "email" ? emails : undefined, platform == "whatsapp" ? phones : undefined, action_date, specialArg, externalVar);
            } else {
                setLoading(false)
                return
            }


            // dispatch(fillCustomers());

            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'נשלח בהצלחה!',
                variant: 'alert',
                alertSeverity: 'success'
            });
            setOpen(false)
            if (onSend)
                onSend()
            return true
        } catch (e) {
            console.log(e)
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'נכשל בשליחה',
                variant: 'alert',
                alertSeverity: 'error'
            });

        }
        setLoading(false)
        return false

    }, [form, files, sendSum, platform, externalVar])
    const whatsappCallBack = () => {
        const to = form.to[0]
        const id = to.id
        const client = JSDB().getClient(Number(id)).first

        let text = form.message

        text = text.replace("#name", client.getName())
        text = text.replace("#id", client.id)
        text = text.replace("#external_id", client.getExternalId())
        text = text.replace("#date", dateObjectToStringIsrael(new Date()))
        text = text.replace("#contact", client.getPhoneContact())
        text = text.replace("#day", JSDB().getDatesManipulator().getDayOfWeekStr(dateObjectToString(new Date()), true))

        const url = `${text.replaceAll('\\\\n', '%0a')}`;
        // console.log(encodeURIComponent("🙏🏽"))
        const phone = to.phone ?? client.getPhoneComplex(dateObjectToString(new Date()), 0);
        const x = window.open(`https://api.whatsapp.com/send?text=${url}&phone=${JSDB().getValidPhone(phone)}`, '_blank');
        if (x) {
            x.focus();
        }
    };
    useEffect(() => {

        if (open) {
            setLoading(false)
            setPlatform(defaultPlatform || "email")

            const to = toSelected?.map((id, index) => {
                const client = JSDB().getClient(Number(id)).first
                console.log(files?.ids?.[index])
                return {
                    name: client.name,
                    id: Number(id),
                    phone: client.getPhoneComplex(dateObjectToString(new Date()), files?.ids?.[index] != undefined ? 1 : 0),
                    email: client.getEmail(),
                    file: files?.ids?.[index],
                    type: files?.types?.[index]
                }
            }) ?? []

            const f = {
                to: to,
                subject: subject ?? "",
                message: msg ?? "",
                files: [],
            }
            if (toSelected) setForm(
                f
            );
        }
    }, [open])
    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            fullWidth
            PaperComponent={PapperComponent}
            aria-labelledby="draggable-gross-message-dialog-title"
        >
            <DialogTitle
                id="draggable-gross-message-dialog-title"
                style={{cursor: 'move'}}
            >
                שלח הודעה
            </DialogTitle>
            <DialogContent>
                <Grid container sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    width: '100%',
                }}>

                    <Grid item xs={12}>
                        <div style={{textAlign: 'left'}}>
                            <GenerativeLoaderChip labelText={'רענן חיבור לווטסאפ'} func={(setLoader) => {
                                callbackNetworkResult(whatsappRefresh, () => {
                                }, setLoader)
                            }} icon={<WhatsApp sx={{width: '24px', height: '24px', mr: 0, ml: 0, p: 0}}/>}/>
                        </div>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl
                            fullWidth
                        >
                            <FormLabel>
                                <Typography>
                                    בחר פלטפורמה
                                </Typography>
                            </FormLabel>
                            <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                <FormControlLabel value={"whatsapp"}
                                                  control={<Radio checked={platform === "whatsapp"}/>} label="וואטצאפ"
                                                  onChange={() => setPlatform("whatsapp")}/>
                                <FormControlLabel value={"email"} control={<Radio checked={platform === "email"}/>}
                                                  label="אימייל" onChange={() => setPlatform("email")}/>
                            </Box>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <FormLabel>
                                <Typography>
                                    בחר נמען
                                </Typography>
                            </FormLabel>
                            <Autocomplete
                                sx={{
                                    maxHeight: 200,
                                    overflowY: 'scroll',

                                }}
                                multiple
                                options={toOptions}
                                getOptionLabel={(option) => option?.name}
                                limitTags={files?.ids?.length ?? undefined}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder="בחר נמען"
                                    />
                                )}
                                isOptionEqualToValue={(option, value) => Number(option?.id) === Number(value?.id)}
                                value={form?.to ?? []}
                                onChange={(e, value) => {
                                    e?.stopPropagation();
                                    e?.preventDefault();
                                    if (e?.keyCode === 8) {
                                        const user = form.to.find((user) => user.id === singleUser?.id);
                                        if (!user) return;
                                        if (document.activeElement?.id === "send-message-dialog-user-details-phone-input") {
                                            const focusedInput = document.activeElement as HTMLInputElement;
                                            if (focusedInput) {
                                                focusedInput.value = focusedInput.value.slice(0, -1);
                                                setSingleUser({
                                                    ...singleUser,
                                                    phone: focusedInput.value,
                                                })
                                                return;
                                            }
                                            return;
                                        }
                                        if (document.activeElement?.id === "send-message-dialog-user-details-email-input") {
                                            const focusedInput = document.activeElement as HTMLInputElement;
                                            if (focusedInput) {
                                                focusedInput.value = focusedInput.value.slice(0, -1);
                                                setSingleUser({
                                                    ...singleUser,
                                                    email: focusedInput.value,
                                                })
                                                return;
                                            }
                                            return;
                                        }
                                    }
                                    setForm({
                                        ...form,
                                        to: value || [],
                                    })
                                }}
                                renderTags={(value, getTagProps) => {
                                    return value.map((option, index) => {
                                            const customer = form?.to?.[index];


                                            const missing = ((platform === "whatsapp" && (!customer?.phone || customer?.phone === "")) || (platform === "email" && (!customer?.email || customer?.email === "")));
                                            return (
                                                <>
                                                    <Chip
                                                        variant="outlined"
                                                        label={(
                                                            <Box sx={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                gap: 1,
                                                                alignItems: 'center'
                                                            }}>
                                                                <Typography>
                                                                    {customer?.name}
                                                                </Typography>
                                                                <Phone
                                                                    style={{
                                                                        color: !customer?.phone || customer?.phone === "" ? "red" : "inherit",
                                                                        fontSize: 18,
                                                                    }}
                                                                />
                                                                <Email
                                                                    style={{
                                                                        color: !customer?.email || customer?.email === "" ? "red" : "inherit",
                                                                        fontSize: 18,
                                                                    }}
                                                                />
                                                            </Box>
                                                        )}
                                                        id={`send-message-dialog-user-details-${index}`}
                                                        {...getTagProps({index})}
                                                        onDelete={getTagProps({index}).onDelete}

                                                        onClick={() => {
                                                            setSingleUser({
                                                                name: customer?.name || "",
                                                                phone: customer?.phone || "",
                                                                email: customer?.email || "",
                                                                id: customer?.id || "",
                                                            });
                                                            setOpenUserDetails(true)
                                                        }}
                                                        style={{
                                                            backgroundColor: missing ? "#ff4e007d" : "#ede7f6",
                                                            fontWeight: missing ? "bold" : "normal",
                                                        }}
                                                    />
                                                    <Popover
                                                        open={openUserDetails && (option?.id) === singleUser?.id}
                                                        anchorEl={() => document.getElementById(`send-message-dialog-user-details-${index}`) as HTMLElement}
                                                        onClose={() => {
                                                            setOpenUserDetails(false)
                                                            setEditEmail(false);
                                                            setEditPhone(false);
                                                        }}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'center',
                                                        }}

                                                    >
                                                        <Box sx={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            gap: 2,
                                                            padding: 2
                                                        }}>
                                                            <Typography sx={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                gap: 1,
                                                                alignItems: 'center',
                                                            }}>
                                                                <User/> {singleUser?.name}
                                                            </Typography>
                                                            {editPhone ? (
                                                                <Box sx={{
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                    gap: 1,
                                                                    alignItems: 'center',
                                                                }}>
                                                                    <TextField
                                                                        label="טלפון"
                                                                        value={singleUser?.phone}
                                                                        onChange={(e) => {
                                                                            // callback to update the user phone
                                                                            const user = form.to.find((user) => user.id === singleUser?.id);
                                                                            if (user) {
                                                                                setForm(
                                                                                    {
                                                                                        ...form,
                                                                                        to: form.to.map((u) => {
                                                                                            if (u.id === user.id) {
                                                                                                u.phone = e.target.value;
                                                                                            }
                                                                                            return u;
                                                                                        })
                                                                                    }
                                                                                )
                                                                            }
                                                                            setSingleUser({
                                                                                ...singleUser,
                                                                                phone: e.target.value,
                                                                            })
                                                                        }}
                                                                        id="send-message-dialog-user-details-phone-input"
                                                                        onClick={() => {
                                                                            setEditPhone(true);
                                                                            setTimeout(() => {
                                                                                document.getElementById("send-message-dialog-user-details-phone-input")?.focus();
                                                                            }, 100);
                                                                        }}
                                                                    />
                                                                    <DeviceFloppy
                                                                        style={{
                                                                            cursor: 'pointer',
                                                                        }}
                                                                        onClick={() => {
                                                                            // callback to save the phone
                                                                            setEditPhone(false);
                                                                        }}
                                                                    />
                                                                </Box>
                                                            ) : (
                                                                <Typography sx={{
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                    gap: 1,
                                                                    alignItems: 'center',
                                                                    fontWeight: !singleUser?.phone || singleUser?.phone === "" ? "bold" : "normal",
                                                                }}>
                                                                    <Phone
                                                                        style={{
                                                                            color: !singleUser?.phone || singleUser?.phone === "" ? "red" : "inherit",
                                                                        }}
                                                                    />{singleUser?.phone || "לא קיים"}
                                                                    <Pencil style={{
                                                                        cursor: 'pointer',
                                                                        zIndex: 1000,
                                                                    }}
                                                                            onClick={() => {
                                                                                setEditPhone(true);
                                                                                setTimeout(() => {
                                                                                    document.getElementById("send-message-dialog-user-details-phone-input")?.focus();
                                                                                }, 100);
                                                                            }}
                                                                    />
                                                                </Typography>
                                                            )
                                                            }
                                                            {editEmail ? (
                                                                <Box sx={{
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                    gap: 1,
                                                                    alignItems: 'center',
                                                                }}>
                                                                    <TextField
                                                                        label="אימייל"
                                                                        value={singleUser?.email}
                                                                        onChange={(e) => {
                                                                            // callback to update the user email
                                                                            const user = form.to.find((user) => user.id === singleUser?.id);
                                                                            if (user) {
                                                                                setForm(
                                                                                    {
                                                                                        ...form,
                                                                                        to: form.to.map((u) => {
                                                                                            if (u.id === user.id) {
                                                                                                u.email = e.target.value;
                                                                                            }
                                                                                            return u;
                                                                                        })
                                                                                    }
                                                                                )
                                                                            }
                                                                            setSingleUser({
                                                                                ...singleUser,
                                                                                email: e.target.value,
                                                                            })
                                                                        }}
                                                                        id="send-message-dialog-user-details-email-input"
                                                                        onClick={() => {
                                                                            setEditEmail(true);
                                                                            setTimeout(() => {
                                                                                document.getElementById("send-message-dialog-user-details-email-input")?.focus();
                                                                            }, 100);
                                                                        }}
                                                                    />
                                                                    <DeviceFloppy
                                                                        style={{
                                                                            cursor: 'pointer',
                                                                        }}
                                                                        onClick={() => {
                                                                            // callback to save the email
                                                                            setEditEmail(false);
                                                                        }}
                                                                    />
                                                                </Box>
                                                            ) : (
                                                                <Typography sx={{
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                    gap: 1,
                                                                    alignItems: 'center',
                                                                    fontWeight: !singleUser?.email || singleUser?.email === "" ? "bold" : "normal",
                                                                }}>
                                                                    <Email
                                                                        style={{
                                                                            color: !singleUser?.email || singleUser?.email === "" ? "red" : "inherit",
                                                                        }}
                                                                    />{singleUser?.email || "לא קיים"}
                                                                    <Pencil style={{
                                                                        cursor: 'pointer',
                                                                        zIndex: 1000,
                                                                    }}
                                                                            onClick={() => {
                                                                                setEditEmail(true);
                                                                                setTimeout(() => {
                                                                                    document.getElementById("send-message-dialog-user-details-email-input")?.focus();
                                                                                }, 100);
                                                                            }}
                                                                    />
                                                                </Typography>
                                                            )}
                                                        </Box>
                                                    </Popover>
                                                </>
                                            )
                                        }
                                    )
                                }}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <FormLabel>
                                <Typography>
                                    נושא
                                </Typography>
                            </FormLabel>
                            <TextField
                                variant="outlined"
                                placeholder="נושא"
                                value={form?.subject || ""}
                                onChange={(e) => {
                                    setForm({
                                        ...form,
                                        subject: e.target.value,
                                    })
                                }}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 1,
                    }}>
                        {
                            savedMessages?.length ? savedMessages?.map((message) => (
                                <Chip
                                    variant="outlined"
                                    label={message.name}
                                    onClick={() => {
                                        setForm({
                                            ...form,
                                            message: message.message,
                                        })
                                    }}
                                    icon={<Cancel style={{color: "red"}} onClick={() => {
                                        setSavedMessages(savedMessages.filter((m) => m.id !== message.id))
                                        localStorage.setItem(`saved-messages-${pathName}`, JSON.stringify(savedMessages.filter((m) => m.id !== message.id)))
                                    }}/>}
                                />
                            )) : null
                        }
                        {
                            saveNewMessage ? (
                                <div style={{
                                    display: "flex"
                                }}>
                                    <Input type="text" placeholder="שם" id="save-new-message-chip"/>
                                    <DeviceFloppy onClick={() => {
                                        const name = document.getElementById("save-new-message-chip")?.value;
                                        if (name) {
                                            setSavedMessages([...savedMessages, {
                                                id: savedMessages.length,
                                                name: name,
                                                message: form.message,
                                            }])
                                            localStorage.setItem(`saved-messages-${pathName}`, JSON.stringify([...savedMessages, {
                                                id: savedMessages.length,
                                                name: name,
                                                message: form.message,
                                            }]))
                                        }
                                        setSaveNewMessage(false);
                                    }}
                                                  style={{
                                                      cursor: 'pointer',
                                                      color: "blue"
                                                  }}
                                    />
                                    <Cancel
                                        style={{
                                            cursor: 'pointer',
                                            color: "red"
                                        }}
                                        onClick={() => {
                                            setSaveNewMessage(false)
                                        }}
                                    />
                                </div>
                            ) : (
                                <Chip
                                    variant="outlined"
                                    label="שמור הודעה"
                                    onClick={() => {
                                        setSaveNewMessage(true)
                                    }}
                                />
                            )
                        }
                        {form?.to?.length == 1 ? (<Chip
                            variant="filled"
                            label="ווטסאפ דסק"
                            color={"success"}
                            onClick={() => {
                                whatsappCallBack()
                            }}
                        />) : undefined}

                    </Grid>

                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <FormLabel>
                                <Typography>
                                    הודעה
                                </Typography>
                            </FormLabel>
                            <TextField
                                id="send-message-dialog-message-input"
                                multiline
                                rows={4}

                                variant="outlined"
                                placeholder="הודעה"
                                onChange={(e) => {
                                    setForm({
                                        ...form,
                                        message: e.target.value,
                                    })
                                }}
                                value={form?.message || ""}
                            />
                        </FormControl>
                    </Grid>

                    {isTaxFile ?
                        <Grid item xs={12}>
                            <FormControlLabel
                                value={sendSum}
                                control={<Checkbox color="primary" checked={sendSum} onChange={() => {
                                    setSendSum(!sendSum);
                                }}/>}
                                label="שלח סיכום"
                                labelPlacement="start"
                            />
                        </Grid>
                        : null
                    } {isNoteFile ?
                    <>  <Grid item xs={4}>
                        <FormControlLabel
                            value={withPrice}
                            control={<Checkbox color="primary" checked={withPrice} onChange={() => {
                                setWithPrice(!withPrice);
                            }}/>}
                            label="עם מחירים"
                            labelPlacement="start"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormControlLabel
                            value={sendSum}
                            control={<Checkbox color="primary" checked={sendSum} onChange={() => {
                                setSendSum(!sendSum);
                            }}/>}
                            label="סהכ בלבד"
                            labelPlacement="start"
                        />
                    </Grid>
                    </>
                    : null
                }

                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <FormLabel>
                                <Typography>
                                    תבניות
                                </Typography>
                            </FormLabel>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: 1,
                                flexWrap: 'wrap',
                            }}>
                                <Chip
                                    variant="outlined"
                                    label="#מזהה"
                                    onClick={() => {
                                        setForm({
                                            ...form,
                                            message: form.message + " #id ",
                                        })
                                        document.getElementById("send-message-dialog-message-input")?.focus();
                                    }}
                                />
                                <Chip
                                    variant="outlined"
                                    label="#שם"
                                    onClick={() => {
                                        setForm({
                                            ...form,
                                            message: form.message + " #name ",
                                        })
                                        document.getElementById("send-message-dialog-message-input")?.focus();
                                    }}
                                />
                                <Chip
                                    variant="outlined"
                                    label="#איש קשר"
                                    onClick={() => {
                                        setForm({
                                            ...form,
                                            message: form.message + " #contact ",
                                        })
                                        document.getElementById("send-message-dialog-message-input")?.focus();
                                    }}
                                />
                                <Chip
                                    variant="outlined"
                                    label="#תאריך"
                                    onClick={() => {
                                        setForm({
                                            ...form,
                                            message: form.message + " #date ",
                                        })
                                        document.getElementById("send-message-dialog-message-input")?.focus();
                                    }}
                                />

                                <Chip
                                    variant="outlined"
                                    label="#לינק"
                                    onClick={() => {
                                        setForm({
                                            ...form,
                                            message: form.message + " #link ",
                                        })
                                        document.getElementById("send-message-dialog-message-input")?.focus();

                                    }}
                                />
                                <Chip
                                    variant="outlined"
                                    label="#מזהה חיצוני"
                                    onClick={() => {
                                        setForm({
                                            ...form,
                                            message: form.message + " #external_id ",
                                        })
                                        document.getElementById("send-message-dialog-message-input")?.focus();
                                    }}
                                />
                                <Chip
                                    variant="outlined"
                                    label="#יום"
                                    onClick={() => {
                                        setForm({
                                            ...form,
                                            message: form.message + " #day ",
                                        })
                                        document.getElementById("send-message-dialog-message-input")?.focus();

                                    }}
                                />
                            </Box>
                        </FormControl>
                    </Grid>

                    {withFiles ? (
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <FormLabel>
                                    <Typography>
                                        קבצים
                                    </Typography>
                                </FormLabel>
                                {
                                    files?.ids?.length ? (
                                        <Autocomplete
                                            multiple
                                            options={files?.ids.map((option) => option) || []}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    placeholder="בחר קבצים"
                                                />
                                            )}
                                            onChange={(e, value) => {
                                                const ids = value?.map((name) => files?.ids.find((t) => t === name)) || []
                                                setForm({
                                                    ...form,
                                                    files: [...form.files, ...ids] as string[] | File[],
                                                })
                                            }}
                                        />
                                    ) : (
                                        <>
                                            <input type="file" id="send-message-dialog-uplkad-file" hidden multiple
                                                   onChange={(e) => {
                                                       const files = e.target.files
                                                       if (files && files?.length > 1) {
                                                           const validFiles: File[] = []
                                                           for (let i = 0; i < files.length; i++) {
                                                               const file = files[i]
                                                               if ((fileTypes && fileTypes?.includes(file.type)) || !fileTypes) {
                                                                   validFiles.push(file)
                                                               }
                                                           }
                                                       } else {
                                                           const file = files?.[0]
                                                           if (file && (fileTypes && fileTypes?.includes(file.type) || !fileTypes)) {
                                                               setUploadFiles([...uploadFiles, file])
                                                           }
                                                       }
                                                   }}
                                            />
                                            <UploadFile style={{
                                                cursor: 'pointer',
                                            }}
                                                        onClick={() => document.getElementById("send-message-dialog-uplkad-file")?.click()}/>
                                        </>
                                    )
                                }
                            </FormControl>
                            {
                                uploadFiles?.length ? (
                                    <Box sx={{display: 'flex', flexDirection: 'row', gap: 2, marginTop: "5px"}}>
                                        {
                                            uploadFiles?.map((file) => (
                                                <Typography sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                }}>
                                                    <HighlightOffOutlined style={{
                                                        cursor: 'pointer',
                                                        color: 'red',
                                                        marginLeft: "5px"
                                                    }}
                                                                          onClick={() => setUploadFiles(uploadFiles.filter((f) => f !== file))}/> {file.name}
                                                </Typography>
                                            ))
                                        }
                                    </Box>
                                ) : null
                            }
                        </Grid>
                    ) : null
                    }

                    <Grid item xs={12} sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 2,
                    }}>

                        <LoadingButton
                            variant="contained"
                            fullWidth
                            loading={loading}
                            color={noPlatform ? "error" : "primary"}
                            disabled={(form.to?.length === 0 && !form.to?.length) || form.subject.length == 0 || form.message.length == 0 || loading}
                            onClick={() => {
                                sendCallback()
                            }}>
                            {
                                "שלח רק למי שאפשר"
                            }
                        </LoadingButton>
                        <LoadingButton
                            variant="contained"
                            fullWidth
                            loading={loading}
                            color={noPlatform ? "error" : "primary"}
                            disabled={noPlatform || (form.to?.length === 0 && !form.to?.length) || form.subject.length == 0 || form.message.length == 0 || loading}
                            onClick={() => {
                                sendCallback()
                            }}>
                            {
                                noPlatform ? `לאחד הלקוחות אין פרטי ${platform === "email" ? "אימייל" : "פלאפון"}` : "שלח"
                            }
                        </LoadingButton>

                        <Button
                            variant="contained"
                            fullWidth
                            color="error"
                            onClick={() => {
                                setOpen(false)
                                setUploadFiles([])
                            }}>
                            בטל
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default SendMessageDialog
