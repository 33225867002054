import {ControlPoint} from '@mui/icons-material';
import {Button, ButtonGroup, Typography} from '@mui/material';
import {IconTrash} from '@tabler/icons';
import {Column, ColumnState} from 'ag-grid-community';
import dayjs from 'dayjs';
import {ArrowDownCircle, ArrowUpCircle, Trash} from 'tabler-icons-react';
import {HeadCell} from 'types';
import {sortDateIsraelStr} from '../../fromKotlin/nkutils';
import {arraysEqual} from '../../utils/sort';
import {ITableControls} from './interfaces';
import {RadioNumberFilter} from './CustomRadioFilter';
import {RadioFloatingFilter} from './CustomRadioFilterProps';
import {extractBeforeNumber} from './ag-grid-functions';

/**
 * Intialization of Ag-Table Utilities
 */

const createColumn = (column: HeadCell, tableControls, i, cellRenderer, menuiItems) => {
    return {
        //---- basic
        field: column?.id,
        headerName: column?.label,
        id: column?.id,
        suppressAutoSort: true,

        hide: column?.hide || column?.hideForce,
        hideForce: column?.hideForce,
        pinned: column?.pinned,

        rowGroup: tableControls?.defaultGroupBy === column?.id || tableControls?.defaultGroupBy === column?.label,
        filter: 'agMultiColumnFilter',
        filterParams: {
            buttons: ['reset'],
            defaultToNothingSelected: true,
            excelMode: undefined,
            filters:
                column?.type === 'number' || column?.numeric
                    ? [
                        {
                            filter: 'agNumberColumnFilter'
                        },
                        {
                            filter: RadioNumberFilter,
                            floatingFilterComponent: RadioFloatingFilter
                        },
                        {
                            filter: 'agSetColumnFilter',

                            filterParams: {
                                buttons: ['reset'],
                                defaultToNothingSelected: true
                            }
                        }
                    ]
                    : column?.type === 'date' || column?.type === 'date_range' || column?.type == 'dateStr'
                        ? [
                            {
                                filter: 'agDateColumnFilter',
                                filterParams: {
                                    buttons: ['reset'],
                                    defaultToNothingSelected: true,
                                    inRangeInclusive: true,
                                    comparator: (filterValue, cellValue) => {
                                        if (!cellValue) return -1;
                                        let dateParts = cellValue.split('-');

                                        if (dateParts.length < 3) {
                                            dateParts = cellValue.split('/');
                                        }
                                        if (dateParts.length < 3) {
                                            return -1;
                                        }

                                        const year = Number(dateParts[2]);
                                        const month = Number(dateParts[1]) - 1;
                                        const day = Number(dateParts[0]);
                                        const cellDate = new Date(year, month, day);

                                        if (cellDate < filterValue) {
                                            return -1;
                                        } else if (cellDate > filterValue) {
                                            return 1;
                                        } else {
                                            return 0;
                                        }
                                    }
                                }
                            },
                            {
                                filter: 'agSetColumnFilter',
                                filterParams: {
                                    buttons: ['reset'],
                                    defaultToNothingSelected: true
                                }
                            }
                        ]
                        : [
                            {
                                filter: 'agSetColumnFilter',
                                filterParams: {
                                    buttons: ['reset'],
                                    defaultToNothingSelected: true
                                }
                            }
                        ]
        },
        enableValue: column?.enableValue,
        rowSpan: column?.rowSpan,
        colSpan: column?.colSpan,

        // remove icon from menu
        suppressMenu: true,

        //---- features
        // group by
        enableRowGroup: tableControls?.groupBy?.enable && column?.enableRowGroup,
        // allow edit to cells
        editable: (params) => {
            return (
                !tableControls.disableEdit &&
                (tableControls.strictEdit ? false : typeof column?.editable === 'function' ? column?.editable(params) : column?.editable)
            );
        },
        // header selection type

        //---- width
        minWidth: column?.minWidth ? column?.minWidth : 80,
        maxWidth: column?.maxWidth ? column?.maxWidth : undefined,
        autoHeight: !tableControls?.fixH,
        cellClass: column?.cellClass,
        cellStyle: (params: any) => {
            if (!params.data) return;
            let style: {
                [key: string]: string | number | undefined;
            } = {
                direction: column?.type === 'number' ? 'ltr' : 'rtl',
                textAlign: 'right',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                whiteSpace: 'nowrap'
            };

            if (tableControls?.rowStyle) {
                style = {
                    ...style,
                    ...tableControls?.rowStyle(params)
                };
            }
            if (column?.cellStyle) {
                style = {
                    ...style,
                    ...column?.cellStyle(params)
                };
            }
            if (
                !tableControls?.disableEditableCellStyle &&
                params.column.colDef.editable &&
                (params.column.colDef.editable == true || params.column.colDef.editable(params))
            ) {
                style = {
                    ...style,
                    // 'background-color': '#ffffff',
                    'background-image':
                        "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23666' width='18px' height='18px' opacity='0.15'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04a.996.996 0 0 0 0-1.41l-2.34-2.34a.996.996 0 0 0-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z'/%3E%3C/svg%3E\")",
                    'background-repeat': 'no-repeat',
                    'background-position': 'left 4px center'
                };
            }
            if (params?.node?.data?.hasBeenEdited) {
                return {
                    ...style,
                    backgroundColor: 'rgba(255, 2, 2, 0.250)',
                    fontWeight: 'bold'
                };
            } else {
                return style;
            }
        },
        lockVisible: !!column?.disableCheckbox,
        checkboxSelection: column.id == 'checkbox' ? true : undefined,
        headerCheckboxSelection: column.id == 'checkbox' ? true : undefined,
        headerCheckboxSelectionFilteredOnly: column.id == 'checkbox' ? true : undefined,
        width: column.id == 'checkbox' ? 50 : undefined,
        lockPosition: column.id == 'checkbox',

        columnsMenuParams: {
            columnLayout: [
                {
                    label: 'הצג עמודות',
                    columns: tableControls?.columns?.map((col: HeadCell) => col?.id)
                }
            ]
        },

        chartDataType: column?.type === 'number' ? 'series' : 'category',

        //agSelectCellEditor  "agTextCellEditor"  "agRichSelectCellEditor"
        cellEditor:
            column?.cellEditor ?? (menuiItems?.[column?.id] || menuiItems?.[column?.label] ? 'agRichSelectCellEditor' : 'agTextCellEditor'),
        cellEditorPopup: column.cellEditorPopup ?? (menuiItems?.[column?.id] || menuiItems?.[column?.label]),
        cellEditorPopupPosition: 'under',
        cellEditorParams: {
            values: menuiItems?.[column?.id]?.map((item: { label: string; value: string | number }) => item.value),
            selectorValues: menuiItems?.[column?.id],
            cellRenderer: (params: any) => {
                return (
                    menuiItems?.[column?.id].find((item: { label: string; value: string | number }) => item.value === params?.value)
                        ?.label ||
                    params?.value ||
                    ''
                );
            }
        },

        aggFunc: column?.aggFunc ? column?.aggFunc : column?.type === 'number' ? 'sum' : null,

        //---- actions
        valueGetter:
            column?.extractFunc || column?.valueGetter
                ? (params: any) => {
                    if (column?.valueGetter && params?.data) {
                        return column?.valueGetter(params?.data);
                    }
                    if (column?.extractFunc && params?.data) {
                        return column?.extractFunc(params?.data);
                    }
                }
                : null,
        extractFunc: column?.extractFunc,
        valueSetter: column?.valueSetter
            ? (params: any) => {
                if (column?.valueSetter) {
                    const newData = column?.valueSetter(params?.data, params?.newValue);
                    params?.api?.applyTransaction({update: [newData]});
                    return true;
                }
            }
            : null,
        comparator: column?.comparator ? column?.comparator : null,
        filterValueGetter: (params: any) => {
            if (params?.node?.group) {
                return params?.value;
            }
            if (column?.extractFunc) {
                return column?.extractFunc(params?.data);
            }
            if (column?.searchFunc) {
                return column?.searchFunc(params?.data);
            }

            return params?.data?.[column?.id];
        },

        valueFormatter: (params: any) => {
            if (column?.extractFunc && !params?.node?.rowPinned && params?.data) {
                return column?.extractFunc(params?.data);
            }

            if (!params?.value) return '';

            if (column?.dataFormat) {
                if (params?.node?.rowPinned && column?.pinnedRowProps) return params?.value || '';
                switch (column?.dataFormat) {
                    case 'currency':
                        if (params?.node?.group) {
                            return '₪ ' + params?.value?.toLocaleString().replace(/\d(?=(\d{3})+\.)/g, '$&,');
                        }
                        return '₪ ' + params?.value?.toLocaleString();
                    case 'percent':
                        if (params?.node?.group) {
                            return params?.value?.toLocaleString().replace(/\d(?=(\d{3})+\.)/g, '$&,') + ' %';
                        }
                        return params?.value + ' %';
                    case 'dateWithTime':
                        if (!params?.node?.rowPinned) {
                            return dayjs(params?.value).format('DD/MM/YYYY HH:mm');
                        }
                        return params?.value;
                    case 'boolean':
                        return params?.value ? 'V' : 'X';
                }
                const fs = typeof column?.dataFormat === 'object' ? column?.dataFormat : false;

                if (fs) {
                    if (params?.node?.rowPinned && column?.pinnedRowProps) return;
                    if (params?.node?.group) {
                        return params?.value?.toLocaleString().replace(/\d(?=(\d{3})+\.)/g, '$&,') + ' ' + fs.str;
                    }
                    return params?.value + ' ' + fs.str;
                }
                return params?.value;
            }
        },
        comparator: column?.comparator
            ? column?.comparator
            : column?.type == 'dateStr'
                ? (valueA, valueB, nodeA, nodeB, isDescending) => {
                    const va = sortDateIsraelStr(valueA);
                    const vb = sortDateIsraelStr(valueB);
                    return va - vb;
                }
                : column?.type == 'numOrStr'
                    ? (valueA, valueB, nodeA, nodeB, isDescending) => {
                        if (valueA == null || valueA == undefined) return 1;
                        if (valueB == null || valueB == undefined) return -1;

                        const va = Number(valueA);
                        const vb = Number(valueB);
                        if (isNaN(va) || isNaN(vb)) {
                            return valueA.localeCompare(valueB) * (isDescending ? -1 : 1);
                        }
                        return va - vb;
                    }
                    : undefined,
        valueParser: (params: any) => {
            if (params?.oldValue === true || params?.oldValue === false) {
                return params?.newValue === 'true' ? true : params?.newValue === 'false' ? false : params?.newValue;
            }
            if (column?.type === 'number' || column?.numeric) {
                if (!isNaN(params?.newValue)) {
                    return Number(params?.newValue);
                }
                return '';
            }

            return params?.newValue;
        },

        valueLimit: column?.valueLimit ? column?.valueLimit : null,

        cellRenderer:
            typeof column?.cellRenderer === 'string'
                ? column?.cellRenderer
                : tableControls?.cellRenderer ?? cellRenderer
                    ? (params) => (tableControls?.cellRenderer ?? cellRenderer)(params, column, i)
                    : null
    };
};

const createColumnWithSubColumns = (columnsForDef, tableControls) => {
    tableControls?.headerChildrens?.forEach(
        (
            parent: {
                parent: string;
                childrens: {
                    label: string;
                    closed?: boolean;
                }[];
            },
            i: number
        ) => {
            parent.childrens?.forEach((child: { label: string; closed?: boolean }) => {
                const columnFromDef = columnsForDef.find((col: any) => col?.headerName === child.label || col?.id === child.label);
                if (columnFromDef) {
                    const indexOfColumn = columnsForDef.indexOf(columnFromDef);
                    columnsForDef.splice(indexOfColumn, 1);
                    const isParentExist = columnsForDef.find((col: any) => col?.headerName === parent.parent);
                    if (isParentExist) {
                        if (child.closed !== undefined) {
                            isParentExist.children
                                ? isParentExist.children.push({
                                    ...columnFromDef,
                                    columnGroupShow: child.closed ? 'closed' : 'open'
                                })
                                : (isParentExist.children = [
                                    {
                                        ...columnFromDef,
                                        columnGroupShow: child.closed ? 'closed' : 'open'
                                    }
                                ]);
                        } else {
                            isParentExist.children
                                ? isParentExist.children.push(columnFromDef)
                                : (isParentExist.children = [columnFromDef]);
                        }
                    } else {
                        if (child.closed !== undefined) {
                            const newColumn = {
                                headerName: parent.parent,
                                children: [
                                    {
                                        ...columnFromDef,
                                        columnGroupShow: child.closed ? 'closed' : 'open'
                                    }
                                ]
                            };
                            columnsForDef.splice(indexOfColumn, 0, newColumn);
                        } else {
                            const newColumn = {
                                headerName: parent.parent,
                                children: [columnFromDef]
                            };
                            columnsForDef.splice(indexOfColumn, 0, newColumn);
                        }
                    }
                }
            });
        }
    );
};

export const setColumns = (columns, tableControls: ITableControls, menuiItems, nested?: boolean, cellRenderer?: any, mobile?: boolean) => {
    let columnsForDef: any = [];

    columns &&
    columns.forEach((column: HeadCell, i: number) => {
        if (
            nested &&
            (tableControls?.nestedColumns?.exclude?.includes(column?.id) ||
                tableControls?.nestedColumns?.exclude?.includes(column?.label))
        ) {
            return;
        }

        const agColumn = createColumn(column, tableControls, i, cellRenderer, menuiItems);
        columnsForDef.push(agColumn);
    });

    if (tableControls?.headerChildrens?.length) createColumnWithSubColumns(columnsForDef, tableControls);

    // if (tableControls?.selection?.enable && !mobile) {
    //     let isPinned = false;
    //     columnsForDef.forEach((col: any) => {
    //         if (col?.pinned) isPinned = true;
    //     });
    //     const checkboxColumn = {
    //         headerName: "",
    //         field: "checkbox",
    //         id: "checkbox",
    //         checkboxSelection: true,
    //         headerCheckboxSelection: true,
    //         headerCheckboxSelectionFilteredOnly: true,
    //         width: 50,
    //         lockPosition: true,
    //         pinned: isPinned ? "right" : false,
    //     }
    //     columnsForDef.unshift(checkboxColumn);
    // }

    return columnsForDef;
}

//-- to start with closed sidebar
export const sideBarDef = (
    tableRef,
    tableControls,
    customeUserState,
    setCustomeUserState,
    customeUserButtons,
    setCustomeUserButtons,
    setOpenCreateStateDialog,
    pinnedRowPropsForSideBar,
    pinnedControl
) => {
    const defaultPanels: any = ['columns', 'filters'];
    // tableControls?.sideBarActions ? {

    const pinnedRowActions = {
        id: 'pinnedRow',
        labelDefault: 'שורות נעוצות',
        labelKey: 'pinnedRow',
        iconKey: 'pin',
        toolPanel: () => {
            return (
                <div
                    style={{
                        width: 'var(--ag-side-bar-panel-width)',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: '5px'
                    }}
                >
                    <div
                        style={{
                            width: '98%',
                            padding: '10px 5px',
                            border: 'solid green 3px',
                            borderRadius: '5px'
                        }}
                    >
                        <Typography
                            variant="h5"
                            sx={{
                                width: '100%',
                                textAlign: 'center',
                                height: '48px',
                                fontSize: '1rem',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                paddingTop: '5px'
                            }}
                        >
                            שורות סיכום עליונות
                            <ArrowUpCircle style={{marginRight: '10px', color: 'green'}}/>
                        </Typography>
                        {pinnedRowPropsForSideBar.pinnedTopRowDataConfiguration?.map((control: IPinnedRowControls, i) => {
                            return (
                                <div
                                    key={i}
                                    style={{
                                        width: '100%',
                                        height: '48px',
                                        marginTop: '5px',
                                        display: 'flex',
                                        border: 'solid green 2px',
                                        borderRadius: '5px'
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            width: '100%',
                                            fontSize: '1rem',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            color: 'black'
                                        }}
                                    >
                                        {control?.label}
                                    </Typography>
                                    <Trash
                                        style={{
                                            height: '100%',
                                            color: 'red',
                                            cursor: 'pointer',
                                            marginLeft: '20px'
                                        }}
                                        onClick={() => {
                                            pinnedRowPropsForSideBar.removeRow(i, true);
                                        }}
                                    />
                                </div>
                            );
                        })}
                    </div>
                    <div
                        style={{
                            width: '98%',
                            padding: '10px 5px',
                            border: 'solid blue 3px',
                            borderRadius: '5px',
                            marginTop: '10px'
                        }}
                    >
                        <Typography
                            variant="h5"
                            sx={{
                                width: '100%',
                                textAlign: 'center',
                                height: '48px',
                                fontSize: '1rem',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                paddingTop: '5px'
                            }}
                        >
                            שורות סיכום תחתונות
                            <ArrowDownCircle style={{marginRight: '10px', color: 'blue'}}/>
                        </Typography>
                        {pinnedRowPropsForSideBar.pinnedBottomRowDataConfiguration?.map((control, i) => {
                            return (
                                <div
                                    key={i}
                                    style={{
                                        width: '100%',
                                        height: '48px',
                                        marginTop: '5px',
                                        display: 'flex',
                                        border: 'solid blue 2px',
                                        borderRadius: '5px'
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            width: '100%',
                                            fontSize: '1rem',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            color: 'black'
                                        }}
                                    >
                                        {control?.label}
                                    </Typography>
                                    <Trash
                                        style={{
                                            height: '100%',
                                            color: 'red',
                                            cursor: 'pointer',
                                            marginLeft: '20px'
                                        }}
                                        onClick={() => {
                                            pinnedRowPropsForSideBar.removeRow(i);
                                        }}
                                    />
                                </div>
                            );
                        })}
                    </div>
                    <div
                        style={{
                            width: '98%',
                            padding: '10px 5px',
                            border: 'solid grey 2px',
                            borderRadius: '5px',
                            marginTop: '10px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}
                    >
                        <Typography
                            variant="h5"
                            sx={{
                                width: '100%',
                                textAlign: 'center',
                                height: '48px',
                                fontSize: '1rem',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                paddingTop: '5px'
                            }}
                        >
                            הוסף שורות
                            <ControlPoint style={{marginRight: '10px'}}/>
                        </Typography>
                        {pinnedControl?.pinnedRowControlsNew?.map((control: IPinnedRowControls, i) => {
                            return (
                                <div
                                    key={i}
                                    style={{
                                        padding: '5px',
                                        width: '100%',
                                        marginTop: '5px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        border: 'solid grey 2px',
                                        borderRadius: '5px'
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            width: '100%',
                                            marginTop: '5px',
                                            textAlign: 'center',
                                            fontSize: '1rem'
                                        }}
                                    >
                                        {control?.label}
                                    </Typography>
                                    <div
                                        style={{
                                            width: '100%',
                                            display: 'flex'
                                        }}
                                    >
                                        <Button
                                            variant="outlined"
                                            sx={{
                                                width: '50%',
                                                height: '48px',
                                                borderRadius: '0px',
                                                marginTop: '5px',
                                                borderTopLeftRadius: '5px',
                                                borderBottomLeftRadius: '5px',
                                                color: 'green',
                                                border: 'solid green 1px'
                                            }}
                                            onClick={() => {
                                                pinnedRowPropsForSideBar.pushNewRow(control, true);
                                            }}
                                        >
                                            הוסף למעלה
                                            <ArrowUpCircle style={{marginLeft: '5px'}}/>
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            sx={{
                                                width: '50%',
                                                height: '48px',
                                                borderRadius: '0px',
                                                marginTop: '5px',
                                                borderTopRightRadius: '5px',
                                                borderBottomRightRadius: '5px',
                                                color: 'blue',
                                                border: 'solid blue 1px'
                                            }}
                                            onClick={() => {
                                                pinnedRowPropsForSideBar.pushNewRow(control);
                                            }}
                                        >
                                            הוסף למטה
                                            <ArrowDownCircle style={{marginLeft: '5px'}}/>
                                        </Button>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            );
        }
    };
    const sideBarActions = {
        id: 'customColumns',
        labelDefault: 'Custome User Columns',
        labelKey: 'customeUserColumns',
        iconKey: 'group',
        toolPanel: () => {
            return (
                <div
                    style={{
                        height: '100%',
                        width: 'var(--ag-side-bar-panel-width)',
                        display: 'flex',
                        flexDirection: 'column',
                        border: 'solid black 2px'
                    }}
                >
                    <Typography
                        variant="h5"
                        sx={{
                            width: '100%',
                            textAlign: 'center',
                            height: '48px',
                            fontSize: '0.8rem',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        מצבי משתמש שמורים
                    </Typography>
                    <Button
                        variant="outlined"
                        sx={{
                            width: '100%',
                            height: '48px'
                        }}
                        onClick={() => setOpenCreateStateDialog(true)}
                    >
                        שמור מצב נוכחי
                    </Button>
                    {customeUserButtons.map(
                        ({
                             buttonLabel,
                             state,
                             gstate,
                             id,
                             filters
                         }: { buttonLabel: string; state: any; gstate: any; id; filters }) => {
                            return (
                                <ButtonGroup variant="contained">
                                    <Button
                                        variant="outlined"
                                        sx={{
                                            width: '100%',
                                            height: '48px',
                                            backgroundColor: customeUserState === id ? '#a5d6ff' : 'inherit',
                                            color: customeUserState === id ? '#0745af' : '',
                                            fontWeight: customeUserState === id ? 'bold' : 'inherit'
                                        }}
                                        onClick={() => {
                                            applyColumnState(state, tableRef);
                                            if (gstate?.length) {
                                                tableRef?.current?.columnApi?.resetColumnGroupState();
                                                tableRef?.current?.columnApi?.setColumnGroupState(gstate);
                                            }
                                            tableRef?.current?.api?.setFilterModel({});
                                            tableRef?.current?.api?.setFilterModel(filters);
                                            setTimeout(() => {
                                                tableRef?.current?.api?.onFilterChanged();
                                            }, 100);
                                        }}
                                    >
                                        {buttonLabel}
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        onClick={() => {
                                            const newButtonsList = customeUserButtons.filter((button) => button.id !== id);
                                            localStorage.setItem(
                                                tableControls?.tableName + '-customeButtons',
                                                JSON.stringify(newButtonsList)
                                            );
                                            setCustomeUserButtons(newButtonsList);
                                        }}
                                        sx={{
                                            color: 'red'
                                        }}
                                    >
                                        <IconTrash size={30} strokeWidth={2} color={'red'}/>
                                    </Button>
                                </ButtonGroup>
                            );
                        }
                    )}
                </div>
            );
        }
    };

    if (pinnedControl?.control) defaultPanels.push(pinnedRowActions);
    defaultPanels.push(sideBarActions);

    return {
        toolPanels: defaultPanels
    };
};

export const statusBar = {
    statusPanels: [
        {
            statusPanel: 'agTotalAndFilteredRowCountComponent',
            align: 'left'
        },
        {
            statusPanel: 'agTotalRowCountComponent',
            align: 'center'
        },
        {
            statusPanel: 'agFilteredRowCountComponent',
            align: 'right'
        },
        {
            statusPanel: 'agSelectedRowCountComponent',
            align: 'right'
        },
        {
            statusPanel: 'agAggregationComponent',
            align: 'right'
        }
    ]
};

export const rightClickItems = (
    tableRef,
    statusBarData,
    tableControls,
    setGrossValueChangeModal,
    setGrossValue,
    extentedContextMenu,
    rightClickActions,
    menuiItems
) => {
    const focusedCell = tableRef?.current?.api?.getFocusedCell();
    const node = tableRef?.current?.api?.getDisplayedRowAtIndex(focusedCell?.rowIndex);
    if (node?.group && node?.expanded && node?.childrenAfterGroup?.length) {
        const columns = tableRef?.current?.columnApi?.getAllDisplayedColumns();
        tableRef?.current?.api?.addCellRange({
            rowStartIndex: node?.childrenAfterGroup[0]?.rowIndex,
            rowEndIndex: node?.childrenAfterGroup[node?.childrenAfterGroup?.length - 1]?.rowIndex,
            columnStart: columns[0],
            columnEnd: columns[columns?.length - 1]
        });
    }
    const blockGrossChange = () => {
        if (focusedCell?.column?.colDef?.editable === false) return true;
        if (!focusedCell?.column?.colDef?.editable) return true;
        const isRange = tableRef?.current?.api?.getCellRanges()?.[0];
        const rangeLength =
            isRange?.endRow?.rowIndex > isRange?.startRow?.rowIndex
                ? isRange?.endRow?.rowIndex - isRange?.startRow?.rowIndex
                : isRange?.startRow?.rowIndex - isRange?.endRow?.rowIndex;
        if (rangeLength >= 1) return false;
        if (statusBarData?.selectedRows <= 1 || !tableRef?.current?.api?.getFocusedCell()?.column?.colDef?.editable) {
            return true;
        }
    };
    if (focusedCell?.rowPinned === 'top') {
        const numOfRows = tableRef?.current?.api?.getDisplayedRowCount();
        tableRef?.current?.api?.addCellRange({
            rowStartIndex: 0,
            rowEndIndex: numOfRows - 1,
            columns: [focusedCell?.column]
        });
    }
    let defaultItems = [
        'copy',
        'copyWithHeaders',
        'paste',
        {
            name: 'שינוי ערך גורף',
            disabled: blockGrossChange(),
            action: () => {
                const selectedNode = tableRef?.current?.api?.getFocusedCell();
                const isSelectable = selectedNode?.column?.colDef?.cellEditorParams?.values?.length;
                if (isSelectable) {
                    setGrossValue(menuiItems?.[selectedNode?.column?.colDef?.id].map((item: any) => item.label));
                }
                setGrossValueChangeModal(true);
            }
        },
        {
            name: 'סמן עמודה',
            action: () => {
                tableRef.current.api.clearRangeSelection();
                tableRef.current.api.addCellRange({
                    rowStartIndex: 0,
                    rowEndIndex: tableRef.current.api.getDisplayedRowCount() - 1,
                    columns: [tableRef.current.api.getFocusedCell().column]
                });
            }
        },
        {
            name: 'סמן שורות',
            action: () => {
                const range = tableRef.current.api.getCellRanges()[0];
                const startRow = range.startRow.rowIndex;
                const endRow = range.endRow.rowIndex;
                for (let i = startRow; i <= endRow; i++) {
                    tableRef.current.api.getDisplayedRowAtIndex(i).setSelected(true);
                }
            }
        },
        'separator',
        {
            name: 'ייצא',
            subMenu: [
                {
                    name: 'ייצא לקובץ csv',
                    action: () => {
                        const selectedRows = tableRef?.current?.api?.getSelectedRows();
                        if (selectedRows?.length) {
                            tableRef?.current?.api?.exportDataAsCsv({onlySelected: true});
                        } else {
                            tableRef?.current?.api?.exportDataAsCsv();
                        }
                    }
                },
                {
                    name: 'ייצא לקובץ excel',
                    action: () => {
                        const selectedRows = tableRef?.current?.api?.getSelectedRows();
                        if (selectedRows?.length) {
                            tableRef?.current?.api?.exportDataAsExcel({onlySelected: true});
                        } else {
                            tableRef?.current?.api?.exportDataAsExcel();
                        }
                    }
                }
            ]
        },
        'separator',
        'chartRange'
    ];
    const clickedColumn = tableRef?.current?.api?.getFocusedCell();
    if (clickedColumn?.rowPinned) {
        defaultItems = [...defaultItems];
    }
    if (rightClickActions?.[clickedColumn?.column?.colDef?.id]) {
        defaultItems.splice(6, 0, {
            name: rightClickActions?.[clickedColumn?.column?.colDef?.id]?.name,
            action: () => rightClickActions?.[clickedColumn?.column?.colDef?.id]?.action(tableRef?.current),
            disabled: rightClickActions?.[clickedColumn?.column?.colDef?.id]?.disabled
                ? rightClickActions?.[clickedColumn?.column?.colDef?.id]?.disabled(tableRef.current)
                : false
        });
    }
    if (tableControls?.extentedContextMenu) {
        if (tableControls?.replaceContextMenu) {
            return [...tableControls?.extentedContextMenu(tableRef?.current)];
        }
        const extendedItems = tableControls?.extentedContextMenu(tableRef?.current);
        defaultItems.unshift(...extendedItems);
    }
    if (extentedContextMenu) {
        if (tableControls?.replaceContextMenu) {
            return [...extentedContextMenu(tableRef?.current)];
        }
        defaultItems.unshift(...extentedContextMenu(tableRef?.current));
    }
    return defaultItems;
};

export const fixColumnsWidth = (tableRef) => {
    const columns = tableRef.current?.columnApi?.getColumns();
    const columnsWidthArr = columns?.map((col: any) => {
        const x = {
            ...col
        };
        return x?.actualWidth;
    });
    const columnsWidth = columnsWidthArr?.reduce((a: number, b: number) => {
        return a + b;
    });
    const tableWidth = document.querySelector('#ag-table-main-div')?.clientWidth;
    if (tableWidth && columnsWidth > tableWidth) {
        tableRef.current?.columnApi?.autoSizeAllColumns();
    }
};

function mapColumnState(columnState1: ColumnState[], columnState2: Column[]): ColumnState[] {
    return columnState1.map((state) => {
        const baseColId = extractBeforeNumber(state.colId);
        const newCol: any = columnState2?.find((cs: any) => extractBeforeNumber(cs?.colId) == baseColId);

        if (newCol) {
            return {...state, aggFunc: undefined, colId: newCol.colId, hide: newCol.colDef.hideForce ?? state.hide};
        } else {
            return state;
        }
    });
}

export function mapFilterState(filterModel: any, columnState2: ColumnState[]): any {
    const newFM = {};
    Object.keys(filterModel).map((state) => {
        const baseColId = extractBeforeNumber(state);
        const newCol = columnState2?.find((cs) => extractBeforeNumber(cs?.colId) == baseColId);
        if (newCol) newFM[newCol.colId] = filterModel[state];
    });
    return newFM;
}

export function applyColumnState(columnState1: ColumnState[], tableRef) {
    const cs2 = tableRef?.current?.columnApi?.getColumns();
    const newColumnState = mapColumnState(columnState1, cs2);
    setTimeout(() => {
        tableRef?.current?.columnApi?.applyColumnState({
            state: newColumnState,
            applyOrder: true
        });
    },0)

}

export const autoSizeAllColumns = (api) => {
        api?.columnApi?.autoSizeAllColumns();
    const tableWidth = document.getElementById('actual-ag-table')?.offsetWidth;
    const columnsWidth = api?.columnApi?.getAllDisplayedColumns()?.reduce((acc: number, curr: any) => {
        return acc + curr?.actualWidth;
    }, 0);
    if (tableWidth && tableWidth > columnsWidth) {
        api?.api?.sizeColumnsToFit();
    }
};
export const getSavedColumnState = (tableRef, tableControls: ITableControls, gridReady: boolean) => {
    const itemFromLocalStorage = localStorage.getItem(tableControls?.tableName + '-columnsState');
    const itemFromLocalStorageGrp = localStorage.getItem(tableControls?.tableName + '-columnsStategrp');
    const columnsState =
        itemFromLocalStorage && itemFromLocalStorage !== 'undefined' && itemFromLocalStorage !== 'null'
            ? JSON.parse(itemFromLocalStorage)
            : [];
    const columnsStateGrp =
        itemFromLocalStorageGrp && itemFromLocalStorageGrp !== 'undefined' && itemFromLocalStorageGrp !== 'null'
            ? JSON.parse(itemFromLocalStorageGrp)
            : [];
    if (columnsState?.length) {
        applyColumnState(columnsState, tableRef);
        if (columnsStateGrp?.length) {
                tableRef?.current?.columnApi?.resetColumnGroupState();
                tableRef?.current?.columnApi?.setColumnGroupState(columnsStateGrp);
        }
    } else {
        autoSizeAllColumns(tableRef.current);
    }
};

export const costumFunction = () => {
    return {
        רוב: (params) => {
            const values = params?.values.filter((v: any) => v !== 'undefined' && v !== null && v !== undefined);

            const counts = values?.reduce((acc: any, curr: any) => {
                acc[curr] = (acc[curr] || 0) + 1;
                return acc;
            }, {});
            counts[''] = 0;
            const max = Object.keys(counts).length ? Object.keys(counts)?.reduce((a, b) => (counts[a] > counts[b] ? a : b)) : 0;
            return max ?? '';
        },
        'ערך חוזר': (params) => {
            const values = params?.values.filter((v: any) => v !== 'undefined' && v !== null && v !== undefined);
            if (values?.length != params?.values?.length) return '';
            return values?.every((v: any) => v === values[0]) ? values[0] : '';
        }
    };
};

export const numberPinnedRowRenderer = (params: any) => {
    if (params?.node?.group) {
        return (
            <div style={{textAlign: 'right', fontWeight: 'bold'}}>
                {Number(params?.value)
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
            </div>
        );
    }
    return (
        <div style={{textAlign: 'right'}}>
            {Number(params?.value)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
        </div>
    );
};

const signSwitch = (params, sign?: string) => {
    switch (sign) {
        case 'currency':
            return (
                '₪ ' +
                Number(params?.value)
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, '$&,')
            );
        case 'percentage':
            return (
                Number(params?.value)
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, '$&,') + ' %'
            );
        default:
            return Number(params?.value)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }
};

export const numberCellRenderer = (params: any, sign?: 'currency' | 'percentage') => {
    if (!params?.value && params?.value !== 0) return '';
    if (typeof params?.value === 'string') return params?.value;

    if (params?.node?.group) {
        return rowGroupRenderer(signSwitch(params, sign));
    }
    return <div style={{textAlign: 'right'}}>{signSwitch(params, sign)}</div>;
};

export const rowGroupRenderer = (value) => {
    if (typeof value === 'number') {
        return (
            <div
                style={{
                    fontWeight: 'bold',
                    color: 'blue'
                }}
            >
                {Number(value)
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
            </div>
        );
    }
    return (
        <div
            style={{
                fontWeight: 'bold',
                color: 'blue'
            }}
        >
            {value}
        </div>
    );
};

export const customGroup = (params: any, categorys: string[], remove?: boolean) => {
    const isAllGrouped: any = [];
    params?.columnApi?.getRowGroupColumns()?.forEach((col) => {
        if (categorys?.includes(col?.colDef.id)) {
            isAllGrouped.push(col?.colDef.id);
        }
    });

    const real_names = categorys.map((c) => params?.columnApi?.getColumns()?.find((a) => a?.colDef?.id == c)?.colId);
    if (remove || isAllGrouped.length > 0 || arraysEqual(isAllGrouped, categorys)) {
        params?.columnApi?.removeRowGroupColumns(real_names);
        return true;
    } else {
        params?.columnApi?.addRowGroupColumns(real_names);
        return false;
    }
}

// export const hideColumns = (params: any, categorys: string[]) => {
//   const columns = params?.columnApi?.getAllColumns();
//   const isAllHiden = columns?.every((col) => !categorys?.includes(col?.colDef.id));
//   if (isAllHiden) {
//     columns?.forEach((col) => {
//       params?.columnApi?.setColumnVisible(col?.colId, true);
//     });
//   } else {
//     params?.columnApi?.setColumnVisible(categorys, false);
//   }
// }

export const customGroupLabelForChip = (params: any, categorys: string[], groupText?, unGroupText?) => {
    const isAllGrouped: any = [];

    params?.columnApi?.getRowGroupColumns()?.forEach((col) => {
        if (categorys?.includes(col?.colDef.id)) {
            isAllGrouped.push(col?.colDef.id);
        }
    });

    if (!arraysEqual(isAllGrouped, categorys)) {
        return groupText ? groupText : 'קבץ לפי קבוצה';
    } else {
        return unGroupText ? unGroupText : 'בטל קבוצה';
    }
};

export const customGroupActiveForChip = (params: any, categorys: string[]) => {
    const isAllGrouped: any = [];
    params?.columnApi?.getRowGroupColumns()?.forEach((col) => {
        if (categorys?.includes(col?.colDef.id)) {
            isAllGrouped.push(col?.colDef.id);
        }
    });

    if (arraysEqual(isAllGrouped, categorys)) {
        return true;
    } else {
        return false;
    }
};

// move...?
export function removeDuplicatesById(data) {
    const uniqueDataMap = new Map();

    data.forEach((item) => {
        if (!uniqueDataMap.has(item.id)) {
            uniqueDataMap.set(item.id, item);
        }
    });

    return Array.from(uniqueDataMap.values());
}
